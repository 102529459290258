import React, { useState } from 'react'
import { utcToZonedTime, formatInTimeZone } from 'date-fns-tz'
import { differenceInMinutes, parseISO } from 'date-fns'
import copy from 'copy-to-clipboard'
import { DocumentDuplicateIcon } from '@heroicons/react/solid'
import toast from 'react-hot-toast'

import LoadSelectStatus from './LoadSelectStatus'
import LoadSelectDriver from './LoadSelectDriver'
import LoadSelectDispatcher from './LoadSelectDispatcher'

import { capitalizeString } from '../../../services/stateConversion'
import { getCurrentUser } from '../../../services/authService'

import './load.css'
import LoadCollapse from './LoadCollapse/LoadCollapseMain'

function numberWithCommas(number, item) {
	const result = number?.toFixed(item !== 'miles' ? 2 : 0)

	return result?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function truncateString(str, num) {
	if (!str) return str

	if (str?.length <= num) {
		return str
	}
	return str?.slice(0, num) + '...'
}

function formatDuration(hours, minutes) {
	const days = Math.floor(hours / 24)
	const remainingHours = hours % 24
	if (days > 0) {
		return `${days}d ${remainingHours}h`
	} else if (hours > 0) {
		return `${hours}h ${minutes}m`
	} else {
		return `${minutes}m`
	}
}

function calculateTripDuration(pickupDate, dropoffDate, status) {
	if (status === 'cancelled' || status === 'rejected') {
		return '0h'
	}
	const pickup = parseISO(pickupDate)
	const dropoff = parseISO(dropoffDate)
	const durationInMinutes = differenceInMinutes(dropoff, pickup)
	const hours = Math.floor(durationInMinutes / 60)
	const minutes = durationInMinutes % 60
	return formatDuration(hours, minutes)
}

function LoadCard({ load, onRemoveLoad, onEditLoad }) {
	const [isCollapsed, setIsCollapsed] = useState(false)
	const [showCopyIcon, setShowCopyIcon] = useState(false)

	const handleCollapseToggle = () => {
		setIsCollapsed(!isCollapsed)
	}

	const handleSelectClick = event => {
		event.stopPropagation()
	}

	const handleCopyClick = (e, text) => {
		e.stopPropagation()

		copy(text)
		toast.success('Load ID copied to clipboard')
	}

	const drivers = load?.drivers

	const {
		loadId,
		broker,
		status,
		originCity,
		originState,
		pickupDate,
		deliveryCity,
		deliveryState,
		dropoffDate,
		price,
		distance,
		equipment,
		stops,
		_id,
	} = load || {}

	const format = 'EEE, MMM dd, HH:mm zzz'

	const origin = `${capitalizeString(originCity)}, ${originState}`

	const originTZ = stops[0]?.timezone
	const originUTC = utcToZonedTime(pickupDate, originTZ)
	const pickDate = formatInTimeZone(originUTC, originTZ, format)

	const destination = `${capitalizeString(deliveryCity)}, ${deliveryState}`

	const destinationTZ = stops[stops?.length - 1]?.timezone
	const dropUTC = utcToZonedTime(dropoffDate, destinationTZ)
	const dropDate = formatInTimeZone(dropUTC, destinationTZ, format)

	const ppm = distance > 0 ? price / distance : 0

	const brokerName = truncateString(capitalizeString(broker?.name), 8)

	const priceTotal = numberWithCommas(price)
	const stopCount = load?.stops?.length

	const user = getCurrentUser()

	const dispatcher = load?.dispatcher?.name || ''

	const tripDuration = calculateTripDuration(
		pickupDate,
		dropoffDate,
		status?.toLowerCase()
	)

	return (
		<>
			<div
				onClick={handleCollapseToggle}
				className="border p-3 my-1 w-full px-4 rounded shadow-sm bg-white"
			>
				<div className="grid grid-cols-12 gap-1">
					<div
						className="col-span-1 flex flex-col items-start"
						onMouseEnter={() => setShowCopyIcon(true)}
						onMouseLeave={() => setShowCopyIcon(false)}
					>
						<div className=" flex items-center cursor-pointer load-card-value text-blue-700 text-xs text-left">
							{showCopyIcon && (
								<span onClick={e => handleCopyClick(e, loadId)}>
									<DocumentDuplicateIcon className="h-4 w-4 mr-1" />
								</span>
							)}

							{loadId}
						</div>
						<div className="flex items-center text-gray-500 text-xs text-left">
							<p className="load-card-value mt-1 text-sm">
								{brokerName ? brokerName : 'No Broker set'}
							</p>
						</div>
					</div>

					<div
						onClick={handleSelectClick}
						className="mt-1 col-span-1 flex flex-col items-start"
					>
						<LoadSelectStatus value={status} loadId={_id} />
					</div>

					<div className="ml-6 col-span-2 flex flex-col items-start">
						<p className="load-card-value text-gray-700 text-sm text-center">
							<span className="badge badge-accent badge-sm mr-1 text-white">
								1
							</span>

							{origin}
						</p>
						<div className="flex items-center text-gray-500 text-center">
							<p className="load-card-prop mt-1 text-xs">{pickDate}</p>
						</div>
					</div>

					<div className="col-span-2 flex flex-col items-start">
						<p className="load-card-value text-gray-700 text-sm text-left">
							<span className="badge badge-accent badge-sm mr-1 text-white">
								{stopCount}
							</span>
							{destination}
						</p>
						<div className="flex items-center text-gray-500 text-center">
							<p className="load-card-prop mt-1 text-xs">{dropDate}</p>
						</div>
					</div>

					<div className="col-span-1 flex flex-col items-start">
						<p className="load-card-value text-gray-700 text-sm text-center">
							{numberWithCommas(distance, 'miles')} mi
						</p>
						<div className="flex items-center text-gray-500 text-center">
							<p className="load-card-prop text-xs mt-1">{tripDuration || 0}</p>
						</div>
					</div>

					<div className="col-span-1 flex flex-col items-start">
						<p className="load-card-value text-gray-700 text-sm text-center">
							${priceTotal}
						</p>
						<div className="flex items-center text-gray-500 text-center">
							<p className="load-card-prop mt-1 text-xs">
								${ppm ? ppm.toFixed(2) : 0}/mi
							</p>
						</div>
					</div>

					<div className="col-span-1 flex flex-col items-start">
						<p className="load-card-value text-gray-700 text-sm text-center">
							{equipment}
						</p>
						<div className="flex items-center text-gray-500 text-center">
							<p className="load-card-prop mt-1 text-xs">Equipment</p>
						</div>
					</div>

					<div
						onClick={handleSelectClick}
						className="mt-2 col-span-1 flex flex-col items-start"
					>
						<LoadSelectDriver load={load} drivers={drivers} />
					</div>

					<div
						onClick={handleSelectClick}
						className="ml-12 col-span-1 flex flex-col items-start"
					>
						{user?.isAdmin ? (
							<div className="mt-2">
								<LoadSelectDispatcher load={load} />
							</div>
						) : (
							<div className="ml-6">
								{dispatcher && (
									<>
										<p className="load-card-value text-gray-700 text-sm text-center">
											{dispatcher}
										</p>
										<div className="flex items-center text-gray-500 text-center">
											<p className="load-card-prop mt-1 text-xs">Booked</p>
										</div>
									</>
								)}
							</div>
						)}
					</div>
				</div>
			</div>

			{isCollapsed && (
				<LoadCollapse
					load={load}
					onRemoveLoad={onRemoveLoad}
					onEditLoad={onEditLoad}
				/>
			)}
		</>
	)
}

export default LoadCard
