import { Disclosure } from "@headlessui/react";
import { Popover } from "@headlessui/react";
import { LightningBoltIcon } from "@heroicons/react/solid";

import { Link } from "react-router-dom";

export default function LoggedOutNav() {
  return (
    <Popover as="header" className="relative">
      <div className="bg-gray-900 pt-6">
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6 pb-6"
          aria-label="Global"
        >
          <Link exact="true" to="/dashboard">
            <div className="flex center justify-center align-center gap-2 py-2 items-center">
              <LightningBoltIcon className="text-yellow-200 h-7 w-7 inline mb-1" />{" "}
              <span className="text-white font-extrabold hover:text-gray-200 ">
                RapidBoard
              </span>
            </div>
          </Link>
        </nav>
      </div>
    </Popover>
  );
}

/* <Disclosure as="nav" className="">
{({ open }) => (
	<>
		<div className="flex justify-between items-center border-b-2 border-gray-100 py-6 px-6 md:space-x-10 mx-6">
			<div className="flex justify-start w-0 flex-1">
				<Link exact="true" to="/dashboard">
					<div className="flex center justify-center align-center gap-2 py-2 items-center">
						<h1 className="whitespace-nowrap text-lg font-extrabold text-gray-700 hover:text-gray-900">
							Rapid Board
						</h1>
					</div>
				</Link>
			</div>
		</div>
	</>
)}
</Disclosure> */
