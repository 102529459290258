import { Link } from 'react-router-dom'

import { UserIcon, ChevronRightIcon } from '@heroicons/react/solid'

import Skeleton from '../../common/NewSpinner'

const ppmFormat = (gross, miles) => {
	let result = gross
	if (gross && miles) {
		result = (gross / miles).toFixed(2)
	}

	return result
}

function msToTime(duration) {
	if (!duration) return 0
	return Number(duration / 3600 / 1000).toFixed(1)
}

function numberWithCommas(number, item) {
	const result = number?.toFixed(item !== 'totalMiles' ? 2 : 0)

	return result?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function driverWorkHours(driverId, workingHours) {
	//workingHours

	const res = workingHours?.find(w => w._id === driverId)

	if (!res) return 0

	return res?.workingHours / 3600 / 1000
}

export default function DriversTable({ data, loading }) {
	console.log('data', data)
	return (
		<>
			{loading ? (
				<div className="max-w-fit mx-auto h-full flex items-center justify-center mt-2">
					<Skeleton size={2500} height={750} />
				</div>
			) : (
				<>
					{data && data?.loads?.length ? (
						<div className="max-w-17xl">
							<div className="shadow sm:hidden">
								<ul
									role="list"
									className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
								>
									{data?.loads?.map((d, index) => {
										const driver = `${d?.driver?.firstName} ${d?.driver?.lastName}`
										const teamDriver = d?.teamDriverData?.firstName
											? `${d?.teamDriverData?.firstName} ${d?.teamDriverData?.lastName}`
											: null

										return (
											<li key={index}>
												<div className="block px-4 py-4 bg-white hover:bg-gray-50">
													<span className="flex items-center space-x-4">
														<span className="flex-1 flex space-x-2 truncate">
															<UserIcon
																className="flex-shrink-0 h-5 w-5 text-gray-400"
																aria-hidden="true"
															/>
															<span className="flex flex-col text-gray-500 text-sm truncate">
																<span className="truncate">
																	{driver} {teamDriver && teamDriver}
																</span>
															</span>
														</span>
														<ChevronRightIcon
															className="flex-shrink-0 h-5 w-5 text-gray-400"
															aria-hidden="true"
														/>
													</span>
												</div>
											</li>
										)
									})}
								</ul>

								<nav
									className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
									aria-label="Pagination"
								>
									<div className="flex-1 flex justify-between">
										<a className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
											Previous
										</a>
										<a className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500">
											Next
										</a>
									</div>
								</nav>
							</div>

							<div className="mb-24 hidden sm:block">
								<div className="mx-auto px-4 sm:px-6 lg:px-8">
									<div className="flex flex-col mt-2 mb-12">
										<div className="align-middle overflow-x-auto shadow overflow-hidden sm:rounded-lg">
											<table className="divide-y divide-gray-200">
												<thead className="bg-gradient-to-r from-blue-500 to-blue-600">
													<tr>
														<th className="px-10 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wide">
															DRIVER
														</th>
														<th className="px-10 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wide">
															GROSS
														</th>
														<th className="px-10 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wide">
															MILES
														</th>
														<th className="px-10 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wide">
															PPM
														</th>
														<th className="px-10 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500  tracking-wide">
															HOURS
														</th>
													</tr>
												</thead>
												<tbody className="bg-white divide-y divide-gray-200">
													{data?.loads?.map((d, index) => {
														// const dDetails = driverDetails(driver?._id, data?.loads)
														const workHours = driverWorkHours(
															d?.driverId,
															data?.workingHours
														)

														const driver = `${d?.driver?.firstName} ${d?.driver?.lastName}`
														const teamDriver = d?.teamDriverData?.firstName
															? `${d?.teamDriverData?.firstName} ${d?.teamDriverData?.lastName}`
															: null
														return (
															<tr key={index} className="bg-white">
																<td className=" w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
																	<div className="flex">
																		<div className="text-gray-500 h-6 w-6 text-base">
																			#{index + 1}{' '}
																		</div>

																		<div className="group inline-flex space-x-2 truncate text-sm">
																			<UserIcon
																				className="flex-shrink-0 h-5 w-5 text-gray-600"
																				aria-hidden="true"
																			/>
																			<p className="text-blue-800 truncate">
																				<Link
																					to={`/dashboard/driverStats/${d?.driver?._id}`}
																				>
																					{driver}{' '}
																					{teamDriver && ` / ${teamDriver}`}
																				</Link>
																			</p>
																		</div>
																	</div>
																</td>
																<td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-700">
																	<span>
																		${numberWithCommas(d?.total_gross) || 0}
																	</span>
																</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
																	<span>
																		{numberWithCommas(
																			d?.total_miles,
																			'totalMiles'
																		) || 0}{' '}
																		mi
																	</span>
																</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 ">
																	<span>
																		${ppmFormat(d?.total_gross, d?.total_miles)}
																	</span>
																</td>
																<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 ">
																	<span>
																		{`${msToTime(d?.total_hours)}`} hrs
																	</span>
																</td>
															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div>
							<h1 className="mt-24 text-center font-bold text-blue-800 text-2xl font-semibold">
								No Driver Data
							</h1>
						</div>
					)}
				</>
			)}
		</>
	)
}
