import { Link } from "react-router-dom";
import { LightningBoltIcon} from "@heroicons/react/solid";

import { Popover } from "@headlessui/react";

const Header = () => {
  return (
    <Popover as="header" className="relative">
      <div className="bg-gray-900 pt-6">
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
          aria-label="Global"
        >
          <Link exact="true" to="/dashboard">
            <div className="flex center justify-center align-center gap-2 py-2 items-center">
              <LightningBoltIcon className="text-yellow-200 h-7 w-7 inline mb-1" />{" "}
              <span className="text-white font-extrabold hover:text-gray-200 ">RapidBoard</span>
            </div>
          </Link>
          <div className=" md:flex md:items-center md:space-x-6">
            <Link
              exact="true"
              to="/login"
              className="whitespace-nowrap text-base font-medium text-white hover:text-gray-200"
            >
              Sign in
            </Link>
            <Link
              exact="true"
              to="/sign-up"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Start for free
            </Link>
          </div>
        </nav>
      </div>
    </Popover>
  );
};

export default Header;
