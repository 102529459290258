import React, { useState, useEffect } from 'react'
import UserInfo from './CompanyInfo/UserInfo'
import { getUser } from '../services/httpService'

export default function Settings() {
	const [user, setUser] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		async function getUserData() {
			setLoading(true)
			const user = await getUser()

			user?.data && setUser(user?.data?.user)
			setLoading(false)
		}

		getUserData()
	}, [])

	return (
		<div
			className="flex flex-col items-left
        flex flex-col w-full my-4 items-left m-auto mt-4"
			style={{ width: '75vw' }}
		>
			<UserInfo user={user} loading={loading} />
		</div>
	)
}
