import axios from 'axios'

import { getDefaultCompany } from './authService'

const userToken = localStorage.getItem('jwtToken')

export const updateArrived = async (loadId, stopIndex) => {
	const company = getDefaultCompany()

	const res = await axios.put(
		`/api/load/mark-as-arrived`,
		{ companyId: company._id, loadId, stopIndex },
		{
			headers: {
				authorization: userToken,
			},
		}
	)

	return res.data
}

export const deleteLoadService = async loadId => {
	const company = getDefaultCompany()

	const res = await axios.delete(`/api/load/${loadId}`, {
		headers: {
			authorization: userToken,
			'x-company-id': company?._id,
		},
	})

	return res.data
}
