import React, { useState, useEffect } from 'react'
import axios from 'axios'

import AddLoadboardModal from './AddLoadboardModal'
import Spinner from '../../common/NewSpinner'

import { getDefaultCompany, getCurrentUser } from '../../services/authService'

function Integrations() {
	const [isModalVisible, setModalVisible] = useState(false)
	const [integrations, setIntegrations] = useState([])
	const [loading, setLoading] = useState(false)

	const company = getDefaultCompany()
	const user = getCurrentUser()
	const isAdmin = user?.isAdmin

	useEffect(() => {
		const getCompanyIntegrations = async () => {
			const companyId = company?._id

			try {
				setLoading(true)
				const { data } = await axios.get(
					`/api/company/integrations/${companyId}`
				)
				console.log('integrations', data)
				setIntegrations(data?.integrations)
			} catch (error) {
				console.error(error)
			} finally {
				setLoading(false)
			}
		}

		getCompanyIntegrations()
	}, [company?._id])

	return (
		<div className="p-8 bg-white text-white">
			<div className="mb-8">
				{isAdmin && (
					<button
						className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
						onClick={() => setModalVisible(true)}
					>
						+ Add New Integration
					</button>
				)}
				{isModalVisible && (
					<AddLoadboardModal
						onClose={() => setModalVisible(false)}
						company={company}
						user={user}
					/>
				)}
			</div>

			{loading ? (
				<Spinner />
			) : (
				<div className="grid grid-cols-3 gap-8">
					{integrations.length > 0 ? (
						integrations.map((integration, index) => (
							<div
								key={index}
								className="bg-white text-gray-900 p-6 rounded shadow-lg"
							>
								<h3 className="text-xl font-bold mb-2">
									Service Name: {integration?.serviceType}
								</h3>

								{isAdmin && (
									<div className="flex space-x-4 mt-6">
										<button className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600">
											Delete
										</button>
									</div>
								)}
							</div>
						))
					) : (
						<p className="text-gray-900 text-3xl col-span-3 text-center">
							There are no integrations.
						</p>
					)}
				</div>
			)}
		</div>
	)
}

export default Integrations
