import React from 'react'
import { Link } from 'react-router-dom'

const Main = () => {
	return (
		<>
			<main style={{ backgroundColor: '#111827', height: '100vh' }}>
				<div className="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
					<div className="mx-auto max-w-7xl lg:px-8">
						<div className="lg:grid lg:grid-cols-2 lg:gap-8">
							<div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
								<div className="lg:py-24">
									<h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
										<span className="block">A better way to</span>
										<span className="pb-3 block bg-clip-text bg-gradient-to-r from-teal-200 to-cyan-400 sm:pb-5">
											manage trucking company
										</span>
									</h1>
									<p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
										Automate your trucking company. Let us do the heavy lifting
										focus on growing your company.
									</p>
								</div>
							</div>
							<div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
								<div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
									<img
										className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
										src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg"
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<footer className="text-center py-4">
					<Link to="/policy" className="text-blue-500">
						Privacy Policy
					</Link>
				</footer>
			</main>
		</>
	)
}
export default Main
