import React, { useState } from 'react'

import {
	XCircleIcon,
	CheckCircleIcon,
	CurrencyDollarIcon,
	InformationCircleIcon,
	TrashIcon,
	RefreshIcon,
} from '@heroicons/react/solid'

import {
	updateAdjustment,
	deleteAdjustment,
} from '../../../services/httpService'

import EditorConfirmDelete from './EditorConfirmDelete'

export default function StatementAdjustments({
	fetchedStatement,
	showInput,
	setShowInput,
	fetchStatement,
	minusPercent,
}) {
	const [newAdjust, setNewAdjust] = useState({
		isRecurring: false,
		type: 'deduction',
		description: '',
		amount: 0,
	})

	const [confirmButtonOpen, setConfirmButtonOpen] = useState(false)
	const [selectedAdjustment, setSelectedAdjustment] = useState(null)

	const handleNewAdjust = e => {
		const value =
			e.target.type === 'checkbox' ? e.target.checked : e.target.value

		setNewAdjust({ ...newAdjust, [e.target.name]: value })
	}

	const handleHidePayment = () => {
		setShowInput(false)
	}

	const handleSavePayment = async () => {
		const res = await updateAdjustment(fetchedStatement?._id, newAdjust)

		// Setting data to initial state
		setNewAdjust({
			isRecurring: false,
			type: 'deduction',
			description: '',
			amount: 0,
		})
		await fetchStatement()

		setShowInput(false)
	}

	const handleDelete = async () => {
		await deleteAdjustment(
			selectedAdjustment,
			fetchedStatement?._id,
			fetchedStatement?.driver?._id
		)
		await fetchStatement()
		setConfirmButtonOpen(false)
		setSelectedAdjustment(null)
	}

	return (
		<div className="mt-6 mb-2 px-4 sm:px-6 lg:px-8">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="font-medium text-lg">Adjustments</h1>
				</div>
			</div>
			<div className="-mx-4 mt-4 flex flex-col sm:-mx-6 md:mx-0">
				<table className="min-w-full divide-y divide-gray-300">
					<tbody>
						{fetchedStatement?.adjustments?.map((adj, i) => (
							<tr key={i} className="border-b border-gray-200">
								<td className="py-2 pl-2 pr-3 text-sm sm:pl-6 md:pl-0">
									<div className="font-medium text-gray-900">
										{adj?.isRecurring && (
											<RefreshIcon className="-ml-1 mr-1 h-5 w-5 text-green-500 inline-block" />
										)}
										{adj?.description}
										{adj?.type === 'percentage' && ` ${adj.amount}` + '%'}
									</div>
								</td>

								<td className="py-2 pl-2 pr-3 text-sm sm:pl-6 md:pl-0">
									<div className="font-medium text-gray-900">
										$
										{adj?.type === 'percentage'
											? minusPercent(
													fetchedStatement?.totalAmount,
													adj?.amount
											  )?.toFixed(2)
											: adj?.amount}{' '}
									</div>
								</td>

								<td className="py-2 pl-2 pr-3 text-sm sm:pl-6 md:pl-0">
									<div className="font-medium text-gray-900">
										<TrashIcon
											onClick={() => {
												setConfirmButtonOpen(true)
												setSelectedAdjustment(adj)
											}}
											className="cursor-pointer h-5 w-5 text-red-500 hover:text-red-700"
										/>
									</div>
								</td>
							</tr>
						))}

						{showInput && (
							<tr>
								<td>
									<div className="md:flex items-left mb-6">
										<div className=""></div>
										<label className="block text-gray-500 font-bold">
											<input
												className="leading-tight"
												type="checkbox"
												name="isRecurring"
												onChange={handleNewAdjust}
												checked={newAdjust?.isRecurring}
											/>
											<span className="text-sm">Recurring</span>
										</label>
									</div>
								</td>

								<td>
									<div className="inline-block relative">
										<select
											name="type"
											onChange={handleNewAdjust}
											value={newAdjust?.type}
											className="block appearance-none w-auto bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
										>
											<option value="deduction">Deduction</option>
											<option value="addition">Addition</option>
											<option value="percentage">Dispatch Fee</option>
										</select>
									</div>
								</td>

								<td>
									<div className="ml-4 mt-1 relative rounded-md shadow-sm">
										<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
											<InformationCircleIcon
												className="h-5 w-5 text-gray-400"
												aria-hidden="true"
											/>
										</div>
										<input
											onChange={handleNewAdjust}
											type="text"
											name="description"
											id="description"
											className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
											placeholder="Description"
										/>
									</div>
								</td>

								<td>
									<div className="ml-1 mt-1 relative rounded-md shadow-sm">
										<div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
											<CurrencyDollarIcon
												className="h-5 w-5 text-gray-400"
												aria-hidden="true"
											/>
										</div>
										<input
											onChange={handleNewAdjust}
											type="number"
											name="amount"
											id="amount"
											className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
											placeholder="Enter the amount"
										/>
									</div>
								</td>

								<td>
									<XCircleIcon
										onClick={handleHidePayment}
										className="ml-4 w-6 h-6 text-red-500 hover:text-red-400 inline-block"
									/>

									<CheckCircleIcon
										onClick={handleSavePayment}
										className="ml-4 w-6 h-6 text-green-500	 hover:text-green-400 inline-block"
									/>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			<button
				onClick={() => setShowInput(true)}
				className="text-sm mt-4 mb-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1.5 px-2 rounded inline-flex items-center"
			>
				+Add Payment
			</button>

			<EditorConfirmDelete
				open={confirmButtonOpen}
				setOpen={setConfirmButtonOpen}
				handleDelete={handleDelete}
				fetchedStatement={fetchedStatement}
			/>
		</div>
	)
}
