import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'

import {
	BriefcaseIcon,
	CurrencyDollarIcon,
	ClockIcon,
} from '@heroicons/react/solid'

import DriverStatsDateRange from './DriverStatsDateRange'
import DriverCharts from './DriverCharts'

export default function DriverStats() {
	const { driverId } = useParams()
	const [chartData, setChartData] = useState()
	const [driver, setDriver] = useState()
	const [filters, setFilters] = useState({
		startDate: moment().startOf('week'),
		endDate: moment().endOf('week'),
	})

	useEffect(() => {
		const fetchDriver = async () => {
			try {
				const res = await axios.get(`/api/dashboard/driverStats/${driverId}`)

				setChartData(res?.data?.driverHours)
				setDriver(res?.data?.driver)
			} catch (error) {
				console.log(error)
			}
		}

		fetchDriver()
	}, [driverId])

	return (
		<>
			<div className="mx-36 mt-16 mb-2 lg:flex lg:items-center lg:justify-between">
				<div className="float-left flex-1 min-w-0">
					<h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
						{driver?.firstName} {driver?.lastName}
					</h2>
					<div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
						<div className="mt-2 flex items-center text-sm text-gray-500">
							<BriefcaseIcon
								className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
							Full-time
						</div>
						<div className="mt-2 flex items-center text-sm text-gray-500">
							<ClockIcon
								className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
							Hours
						</div>
						<div className="mt-2 flex items-center text-sm text-gray-500">
							<CurrencyDollarIcon
								className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
							$120k
						</div>
					</div>
				</div>
				<div className="float-right mt-5 flex lg:mt-0 lg:ml-4">
					<DriverStatsDateRange setFilters={setFilters} filters={filters} />
				</div>
			</div>
			<div
				className="mx-36"
				style={{ position: 'relative', height: '40vh', width: '40vw' }}
			>
				<DriverCharts chartData={chartData} />
			</div>
		</>
	)
}
