import React, { useState } from 'react'

import toast from 'react-hot-toast'

import { getCurrentUser } from '../../services/authService'
import { updateCompanyInfo } from '../../services/httpService'

const states = [
	'Alabama',
	'Alaska',
	'Arizona',
	'Arkansas',
	'California',
	'Colorado',
	'Connecticut',
	'Delaware',
	'Florida',
	'Georgia',
	'Hawaii',
	'Idaho',
	'Illinois',
	'Indiana',
	'Iowa',
	'Kansas',
	'Kentucky',
	'Louisiana',
	'Maine',
	'Maryland',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Mississippi',
	'Missouri',
	'Montana',
	'Nebraska',
	'Nevada',
	'New Hampshire',
	'New Jersey',
	'New Mexico',
	'New York',
	'North Carolina',
	'North Dakota',
	'Ohio',
	'Oklahoma',
	'Oregon',
	'Pennsylvania',
	'Rhode Island',
	'South Carolina',
	'South Dakota',
	'Tennessee',
	'Texas',
	'Utah',
	'Vermont',
	'Virginia',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
]

export default function UserEditModal({
	onClose,
	initialCompany,
	getUserInfoOnUpdate,
}) {
	const [company, setCompany] = useState(initialCompany)

	const timezones = ['EST', 'CST', 'MST', 'PST']

	const { isAdmin } = getCurrentUser()

	if (!isAdmin) return null

	const handleChange = (field, value) => {
		if (field.includes('.')) {
			const [parentField, childField] = field.split('.')

			setCompany(prevState => ({
				...prevState,
				[parentField]: {
					...prevState[parentField],
					[childField]: value,
				},
			}))
		} else {
			setCompany(prevState => ({
				...prevState,
				[field]: value,
			}))
		}
	}

	const street = company?.address?.street
	const city = company?.address?.city
	const state = company?.address?.state
	const timezone = company?.timezone

	const handleSubmit = async () => {
		try {
			await updateCompanyInfo(company)

			onClose()

			toast.success('Company information updated!')
			getUserInfoOnUpdate()
		} catch (error) {
			console.error('Error updating company:', error)
			toast.error('Something went wrong!')
		}
	}

	return (
		<div className="space-y-6 overflow-auto p-4">
			<div>
				<label
					htmlFor="street"
					className="block text-sm font-medium text-gray-700"
				>
					Street
				</label>
				<input
					value={street || ''}
					onChange={e => handleChange('address.street', e.target.value)}
					className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
				/>
			</div>
			<div className="grid grid-cols-2 gap-2">
				<div>
					<label
						htmlFor="city"
						className="block text-sm font-medium text-gray-700"
					>
						City
					</label>
					<input
						value={city || ''}
						onChange={e => handleChange('address.city', e.target.value)}
						className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					/>
				</div>

				<div>
					<label
						htmlFor="state"
						className="block text-sm font-medium text-gray-700"
					>
						State
					</label>
					<select
						value={state || ''}
						onChange={e => handleChange('address.state', e.target.value)}
						className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					>
						<option disabled value="Choose state">
							Choose state
						</option>

						{states.map(state => (
							<option key={state} value={state}>
								{state}
							</option>
						))}
					</select>
				</div>
			</div>
			<hr className="my-4" />
			<div className="grid grid-cols-2 gap-2">
				<div>
					<label
						htmlFor="usdot"
						className="block text-sm font-medium text-gray-700"
					>
						USDOT#
					</label>
					<input
						value={company?.usdot || ''}
						onChange={e => handleChange('usdot', e.target.value)}
						className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					/>
				</div>

				<div>
					<label
						htmlFor="mc"
						className="block text-sm font-medium text-gray-700"
					>
						MC#
					</label>
					<input
						value={company?.mc || ''}
						onChange={e => handleChange('mc', e.target.value)}
						className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					/>
				</div>
			</div>
			<div>
				<label
					htmlFor="timezone"
					className="block text-sm font-medium text-gray-700"
				>
					Timezone
				</label>

				<select
					id="timezone"
					value={timezone || ''}
					onChange={e => handleChange('timezone', e.target.value)}
					className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
				>
					{timezones.map(tz => (
						<option key={tz} value={tz}>
							{tz}
						</option>
					))}
				</select>
			</div>
			<div className="mt-4 flex justify-end space-x-4">
				<button className="py-2 px-4 border rounded-md" onClick={onClose}>
					Close
				</button>
				<button
					onClick={handleSubmit}
					className="py-2 px-4 bg-indigo-500 text-white rounded-md"
				>
					Submit
				</button>
			</div>
		</div>
	)
}
