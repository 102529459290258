import React, { createContext, useState, useContext, useMemo } from 'react'
import moment from 'moment-timezone'

const DateContext = createContext()

export function DateProvider({ children }) {
	const tz = 'America/Los_Angeles'

	// here
	const [startDate, setStartDate] = useState(moment.tz(tz).startOf('week'))

	const [endDate, setEndDate] = useState(moment.tz(tz).endOf('week'))

	const [weekNumber, setWeekNumber] = useState(moment.tz(startDate, tz).week())

	const initialStartDate = moment.tz(tz).startOf('week').toDate()
	const initialEndDate = moment.tz(tz).endOf('week').toDate()
	const initialWeekNumber = moment.tz(initialStartDate, tz).week()

	const resetDates = () => {
		setStartDate(initialStartDate)
		setEndDate(initialEndDate)
		setWeekNumber(initialWeekNumber)
	}

	const addWeek = () => {
		const tz = 'America/Los_Angeles'

		const newStartDate = moment.tz(startDate, tz).add(1, 'weeks').toDate()

		const newEndDate = moment.tz(endDate, tz).add(1, 'weeks').toDate()

		console.log('addWeek called', { newStartDate, newEndDate })

		setStartDate(newStartDate)
		setEndDate(newEndDate)
		setWeekNumber(moment(newStartDate).week())
	}

	const subtractWeek = () => {
		const tz = 'America/Los_Angeles'

		const newStartDate = moment.tz(startDate, tz).subtract(1, 'weeks').toDate()

		const newEndDate = moment.tz(endDate, tz).subtract(1, 'weeks').toDate()

		console.log('subtractWeek called', { newStartDate, newEndDate })

		setStartDate(newStartDate)
		setEndDate(newEndDate)
		setWeekNumber(moment(newStartDate).week())
	}

	const value = useMemo(
		() => ({
			startDate,
			endDate,
			weekNumber,
			setStartDate,
			setEndDate,
			setWeekNumber,
			addWeek,
			subtractWeek,
			resetDates,
		}),
		[startDate, endDate, weekNumber]
	)

	return <DateContext.Provider value={value}>{children}</DateContext.Provider>
}

export function useDate() {
	const context = useContext(DateContext)

	if (!context) {
		throw new Error('useDate must be used within a DateProvider')
	}
	return context
}
