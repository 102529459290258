import React from 'react'
import { PlusCircleIcon } from '@heroicons/react/solid'

function LoadFormFooter({ addStop, handleCancel, handleSubmit }) {
	return (
		<div className="flex justify-between space-x-2 mt-6">
			<button
				onClick={addStop}
				type="button"
				className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
			>
				<PlusCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
				Add Stop
			</button>

			<div className="flex space-x-4">
				<button
					onClick={handleCancel}
					className="text-gray-600 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
				>
					Cancel
				</button>
				<button
					onClick={handleSubmit}
					className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded"
				>
					Submit
				</button>
			</div>
		</div>
	)
}

export default LoadFormFooter
