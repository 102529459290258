import { UserIcon, ChevronRightIcon } from '@heroicons/react/solid'
// import Skeleton from '../../common/Skeleton'
import Skeleton from '../../common/NewSpinner'

const ppmFormat = (gross, miles) => {
	let result = gross
	if (gross && miles) {
		result = (gross / miles).toFixed(2)
	}

	return result
}

function numberWithCommas(number, item) {
	const result = number?.toFixed(item !== 'totalMiles' ? 2 : 0)

	return result?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default function DispatchersTable({ data, loading }) {
	return (
		<>
			{loading ? (
				<div className="flex items-center justify-center mt-2">
					<Skeleton size={2500} height={750} />
				</div>
			) : (
				<>
					{data &&
						(data?.dispatchers?.length ? (
							<>
								<div className="shadow sm:hidden">
									<ul
										role="list"
										className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
									>
										{data?.dispatchers?.map((d, index) => (
											<li key={index}>
												<a
													// href={transaction.href}
													className="block px-4 py-4 bg-white hover:bg-gray-50"
												>
													<span className="flex items-center space-x-4">
														<span className="flex-1 flex space-x-2 truncate">
															<UserIcon
																className="flex-shrink-0 h-5 w-5 text-gray-400"
																aria-hidden="true"
															/>
															<span className="flex flex-col text-gray-500 text-sm truncate">
																<span className="truncate">
																	{d?.dispatcher?.name}
																</span>
															</span>
														</span>
														<ChevronRightIcon
															className="flex-shrink-0 h-5 w-5 text-gray-400"
															aria-hidden="true"
														/>
													</span>
												</a>
											</li>
										))}
									</ul>

									<nav
										className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200"
										aria-label="Pagination"
									>
										<div className="flex-1 flex justify-between">
											<a
												// href="#"
												className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
											>
												Previous
											</a>
											<a
												// href="#"
												className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:text-gray-500"
											>
												Next
											</a>
										</div>
									</nav>
								</div>

								<div className="mb-24 hidden sm:block">
									<div className="mx-auto px-4 sm:px-6 lg:px-8">
										<div className="flex flex-col mt-2">
											<div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
												<table className="min-w-full divide-y divide-gray-200">
													<thead className="bg-gradient-to-r from-blue-500 to-blue-600">
														<tr>
															<th className="px-10 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wide">
																DISPATCHER
															</th>
															<th className="px-10 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wide">
																GROSS
															</th>
															<th className="px-10 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wide">
																MILES
															</th>
															<th className="px-10 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wide">
																PPM
															</th>
														</tr>
													</thead>
													<tbody className="bg-white divide-y divide-gray-200">
														{data?.dispatchers?.map((d, index) => {
															return (
																<tr key={index} className="bg-white">
																	<td className=" w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
																		<div className="flex">
																			<div class="text-gray-500 h-6 w-6 text-base">
																				#{index + 1}{' '}
																			</div>
																			<a className="group inline-flex space-x-2 truncate text-sm">
																				<UserIcon
																					className="flex-shrink-0 h-5 w-5 text-gray-700"
																					aria-hidden="true"
																				/>
																				<p className="text-gray-700 truncate">
																					{d?.dispatcher[0]?.name || 'N/A'}
																				</p>
																			</a>
																		</div>
																	</td>
																	<td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-700">
																		<span>
																			${numberWithCommas(d?.total_gross) || 0}
																		</span>
																	</td>
																	<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
																		<span>
																			{numberWithCommas(
																				d?.total_miles,
																				'totalMiles'
																			) || 0}{' '}
																			mi
																		</span>
																	</td>
																	<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 ">
																		<span>
																			$
																			{ppmFormat(
																				d?.total_gross,
																				d?.total_miles
																			)}
																		</span>
																	</td>
																</tr>
															)
														})}
													</tbody>
												</table>
												{/* Pagination */}
												{!data.dispatchers ||
													(data.dispatchers.length > 9 && (
														<nav
															className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
															aria-label="Pagination"
														>
															<div className="hidden sm:block">
																<p className="text-sm text-gray-700">
																	Showing <span className="font-medium">1</span>{' '}
																	to <span className="font-medium">10</span> of{' '}
																	<span className="font-medium">20</span>{' '}
																	results
																</p>
															</div>
															<div className="flex-1 flex justify-between sm:justify-end">
																<a className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
																	Previous
																</a>
																<a className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
																	Next
																</a>
															</div>
														</nav>
													))}
											</div>
										</div>
									</div>
								</div>
							</>
						) : (
							<div>
								<h1 className="mt-24 text-center font-bold text-blue-800 text-2xl font-semibold">
									No Dispatcher Data
								</h1>
							</div>
						))}
				</>
			)}
		</>
	)
}
