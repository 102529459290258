import { getDrivers } from '../../services/httpService'
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Skeleton from '../../common/NewSpinner'
import DriverForSettings from '../DriverForSettings'
import DotMenu from './DotMenuDrivers'
import ConfirmDriverAction from './ConfirmDriverAction'
import axios from 'axios'
import Filters from './Filters'
import DriverSlideOver from './DriverSlideOver'

export default function Drivers() {
	const [drivers, setDrivers] = useState([])
	const [loading, setLoading] = useState(false)
	const [confirm, setConfirm] = useState(false)
	const [actionType, setActionType] = useState('')
	const [selectedDriver, setSelectedDriver] = useState(null)
	const [searchText, setSearchText] = useState('')
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		const CancelToken = axios.CancelToken
		const source = CancelToken.source()

		const fetchData = async () => {
			try {
				setLoading(true)
				const res = await getDrivers(source.token)

				if (axios.isCancel(res)) {
					console.log('Request canceled', res.message)
				} else {
					setDrivers(Array.isArray(res) ? res : [])
				}

				// setDrivers(driversData);

				setLoading(false)
			} catch (error) {
				console.error('An error occurred: ', error)
				setLoading(false)
			}
		}

		fetchData()

		return () => source.cancel('Previous request canceled')
	}, [])

	async function getDriversData() {
		setLoading(true)
		const drivers = await getDrivers()

		setDrivers(drivers?.data)
		setLoading(false)
	}

	const handleSearchChange = event => {
		setSearchText(event.target.value)
	}

	return (
		<>
			<div className="flex flex-col w-full justify-center items-center my-14 mt-6 overflow-x-auto overflow-y-hidden">
				<div className="min-w-full  -mt-4 overflow-hidden sm:-mx-6 lg:-mx-8 ">
					<div className="py-4 min-w-full sm:px-6 lg:px-8">
						<div className="">
							<DriverForSettings getUpdatedData={getDriversData} />
							<Filters handleSearchChange={handleSearchChange} />
						</div>

						{loading && (
							<div className="max-w-max	mx-auto items-center justify-center p-1 pt-0">
								<Skeleton size="90vw" height={750} />
							</div>
						)}

						{drivers && drivers?.length ? (
							<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
								<table className="z-0 min-w-full divide-y divide-gray-200">
									<thead className="bg-gray-50">
										<tr>
											<th
												scope="col"
												className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Name
											</th>

											<th
												scope="col"
												className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Status
											</th>
											<th
												scope="col"
												className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Loads
											</th>
											<th
												scope="col"
												className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Last Active
											</th>
											<th
												scope="col"
												className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Actions
											</th>
										</tr>
									</thead>
									<tbody className="bg-white divide-y divide-gray-200">
										{drivers &&
											drivers?.map(driver => (
												<tr
													key={driver._id}
													onClick={() => {
														setIsOpen(true)
														setSelectedDriver(driver)
													}}
													className="cursor-pointer"
												>
													<td className="px-6 py-4 whitespace-nowrap">
														<div className="text-sm text-gray-900">
															{driver.firstName}
														</div>
														<div className="text-sm text-gray-500">
															{driver.lastName}
														</div>
													</td>
													<td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
														<div
															className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${
																driver.isActive ? 'bg-green-100' : 'bg-red-100'
															} ${
																driver.isActive
																	? 'text-green-800'
																	: 'text-red-800'
															}`}
														>
															{driver.isActive ? 'Active' : 'Inactive'}
														</div>
													</td>
													<td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
														{driver?.loads?.length > 0
															? driver?.loads?.length + ' Load'
															: 'N/A'}
														{driver?.loads?.length > 1 && 's'}
													</td>
													<td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500">
														{moment(driver.updatedAt).fromNow()}
													</td>
													<td
														className="px-1 pr-2 whitespace-wrap align-right text-right text-xs font-medium"
														onClick={e => e.stopPropagation()}
													>
														<DotMenu
															driver={driver}
															setConfirm={setConfirm}
															setActionType={setActionType}
															setSelectedDriver={setSelectedDriver}
														/>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						) : (
							!loading && (
								<div className="mt-24 text-center font-bold text-blue-800 text-2xl font-semibold mb-64">
									No Driver Data
								</div>
							)
						)}
					</div>
				</div>
			</div>

			<DriverSlideOver
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false)
					setSelectedDriver(null)
				}}
				selectedDriver={selectedDriver}
			/>

			<ConfirmDriverAction
				confirm={confirm}
				setConfirm={setConfirm}
				setActionType={setActionType}
				actionType={actionType}
				selectedDriver={selectedDriver}
				setSelectedDriver={setSelectedDriver}
				drivers={drivers}
				setDrivers={setDrivers}
			/>
		</>
	)
}
