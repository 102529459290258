import React from 'react';

const DriverInfo = ({ driver }) => {
  return (
    <div className='grid grid-cols-6 gap-4 items-end'>
      <div className='col-span-1'>
        <label className='text-sm text-gray-600 block'>Hometown</label>
        <div className='font-semibold text-gray-700'>
          {driver.hometown || 'N/A'}
        </div>
      </div>

      <div className='col-span-1'>
        <label className='text-sm text-gray-600 block'>Contact</label>
        <div className='font-semibold text-gray-700'>
          {driver.phoneNumber || 'N/A'}
        </div>
      </div>

      <div className='col-span-1'>
        <label className='text-sm text-gray-600 block'>Location</label>
        <div className='font-semibold text-gray-700'>
          {driver?.location?.city || 'N/A'}
        </div>
      </div>

      <div className='col-span-1'>
        <label className='text-sm text-gray-600 block'>Truck</label>
        <div className='font-semibold text-gray-700'>
          {driver?.truck?.number ? '#' + driver?.truck?.number : 'N/A'}
        </div>
      </div>

      <div className='col-span-1'>
        <label className='text-sm text-gray-600 block'>Dispatcher</label>
        <div className='font-semibold text-gray-700'>
          {driver?.dispatcherInfo?.name || 'N/A'}
        </div>
      </div>

      <div className='col-span-1'>
        <label className='text-sm text-gray-600 block'>Status</label>
        <div
          className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium ${
            driver.isActive ? 'bg-green-100' : 'bg-red-100'
          } ${driver.isActive ? 'text-green-800' : 'text-red-800'}`}
        >
          {' '}
          {driver?.isActive ? 'Active' : 'Inactive'}{' '}
        </div>
      </div>
    </div>
  );
};

export default DriverInfo;
