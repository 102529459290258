import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { utcToZonedTime } from 'date-fns-tz'
import { startOfWeek, endOfWeek } from 'date-fns'

import axios from 'axios'

import Filters from './Filters/FiltersMain'
import Load from './Load/LoadCard'
import Pagination from './Pagination'
import Spinner from '../../common/NewSpinner'
import EditLoadModal from './EditModal'
import Modal from '../../common/Modal'
import CreateNewLoad from '../NewLoadModal/CreateNewLoad'
import SelectedFilters from './SelectedFilters'

import { getLoads } from '../../services/httpService'
import { debounce } from '../../utils/utils'

const scrollToTop = () => {
	window.scrollTo(0, 0)
}

const ITEMS_PER_PAGE = 20

const tabsArray = ['Upcoming', 'In Transit', 'History']

export default function LoadsMain() {
	const initializeFilters = activeTab => {
		let status
		let startDate
		let endDate

		switch (activeTab) {
			case 'Upcoming':
				status = ['NEW']
				startDate = null
				endDate = null
				break
			case 'In Transit':
				status = ['DISPATCHED']
				startDate = null
				endDate = null
				break
			case 'History':
				status = ['Delivered', 'Invoiced', 'Cancelled', 'Paid', 'Rejected']

				const timezone = 'America/Los_Angeles'

				const nowInZone = utcToZonedTime(new Date(), timezone)

				const startDatee = startOfWeek(nowInZone, { weekStartsOn: 0 })
				const endDatee = endOfWeek(nowInZone, { weekStartsOn: 0 })

				startDate = startDatee
				endDate = endDatee

				break
			default:
				return {
					status: null,
					startDate: null,
					endDate: null,
					dispatcher: null,
					driver: null,
				}
		}

		return {
			status,
			startDate: startDate,
			endDate: endDate,
			dispatcher: null,
			dispatcherName: null,
			driver: null,
			driverName: null,
		}
	}

	const [activeTab, setActiveTab] = useState('Upcoming')
	const [showEditModal, setShowEditModal] = useState(false)
	const [currentLoadToEdit, setCurrentLoadToEdit] = useState(null)
	const [open, setOpen] = useState(false)
	const [isAutoLoadActive, setIsAutoLoadActive] = useState(false)
	const [state, setState] = useState(() => {
		return {
			loads: [],
			loading: false,
			offset: 0,
			loadsCount: 0,
			totalSum: 0,
			filterInput: '',
			filters: initializeFilters('Upcoming'),
		}
	})

	const handleEditLoad = loadData => {
		setCurrentLoadToEdit(loadData)
		setShowEditModal(true)
	}

	const updateLoadInState = updatedLoadData => {
		setState(prevState => ({
			...prevState,
			loads: prevState.loads.map(load =>
				load._id === updatedLoadData._id ? updatedLoadData : load
			),
		}))
		setShowEditModal(false)
	}

	useEffect(() => {
		const source = axios.CancelToken.source()

		const fetchData = async () => {
			setState(prevState => ({ ...prevState, loading: true }))

			try {
				const result = await getLoads(
					state.filterInput,
					ITEMS_PER_PAGE,
					state.offset,
					state.filters,
					source.token
				)

				if (result?.name !== 'CanceledError') {
					setState(prevState => ({
						...prevState,
						loads: result?.loads || [],
						loadsCount: result?.count,
						totalSum: result?.totalSum,
						loading: false,
					}))
				}
			} catch (error) {
				console.error('Error fetching loads:', error)
				setState(prevState => ({ ...prevState, loading: false }))
			}
		}

		fetchData()

		return () => source.cancel('Previous request canceled')
	}, [state.filters])

	useEffect(() => {
		const newFilters = initializeFilters(activeTab)

		setState(prevState => ({
			...prevState,
			filters: newFilters,
		}))
	}, [activeTab])

	const handleSearchChange = debounce(async e => {
		const value = e.target.value || null

		setState(prevState => ({
			...prevState,
			filterInput: value,
			loading: true,
			offset: 0,
		}))

		const result = await getLoads(
			value,
			ITEMS_PER_PAGE,
			state.offset,
			state.filters
		)

		setState(prevState => ({
			...prevState,
			loads: result?.loads || [],
			loadsCount: result?.count,
			loading: false,
		}))
	}, 600)

	const nextPage = () => {
		setState(prevState => ({
			...prevState,
			loading: true,
			offset: prevState.offset + 20,
			filters: {
				...prevState.filters,
			},
		}))

		scrollToTop()
	}

	const previousPage = () => {
		setState(prevState => ({
			...prevState,
			loading: true,
			offset: prevState.offset - 20,
			filters: {
				...prevState.filters,
			},
		}))
		scrollToTop()
	}

	const removeLoadFromState = loadId => {
		setState(prevState => ({
			...prevState,
			loads: prevState.loads.filter(load => load._id !== loadId),
		}))
	}

	const incrementWeek = () => {
		setState(prevState => {
			if (!prevState.filters.startDate || !prevState.filters.endDate) {
				console.error('Invalid start or end date')
				return prevState
			}

			const startDate = moment(prevState.filters.startDate)
				.add(1, 'weeks')
				.toDate()
			const endDate = moment(prevState.filters.endDate).add(1, 'weeks').toDate()

			return {
				...prevState,
				filters: {
					...prevState.filters,
					startDate,
					endDate,
				},
				offset: 0,
			}
		})
	}

	const decrementWeek = () => {
		setState(prevState => {
			if (!prevState.filters.startDate || !prevState.filters.endDate) {
				console.error('Invalid start or end date')
				return prevState
			}

			const startDate = moment(prevState.filters.startDate)
				.subtract(1, 'weeks')
				.toDate()
			const endDate = moment(prevState.filters.endDate)
				.subtract(1, 'weeks')
				.toDate()

			return {
				...prevState,
				filters: {
					...prevState.filters,
					startDate,
					endDate,
				},
				offset: 0,
			}
		})
	}

	const handleStartDate = date => {
		setState(prevState => ({
			...prevState,
			filters: {
				...prevState.filters,
				startDate: moment(date),
			},
		}))
	}

	const handleEndDate = date => {
		setState(prevState => ({
			...prevState,
			filters: {
				...prevState.filters,
				endDate: moment(date),
			},
		}))
	}

	const onDispatcherChange = (dispatcher, dispatcherName) => {
		console.log('dispatcherName', dispatcherName)

		setState(prevState => ({
			...prevState,
			filters: {
				...prevState.filters,
				dispatcher: dispatcher,
				dispatcherName: dispatcherName,
			},
		}))
	}

	const onDriverChange = (driver, driverName) => {
		setState(prevState => ({
			...prevState,
			filters: {
				...prevState.filters,
				drivers: [driver],
				driverName: driverName,
			},
		}))
	}

	const onStatusChange = status => {
		setState(prevState => ({
			...prevState,
			filters: {
				...prevState.filters,
				status: status?.label,
			},
		}))
	}

	const removeFilter = key => {
		setState(prevState => {
			const newFilters = { ...prevState.filters }

			if (key === 'dispatcherName') {
				newFilters.dispatcher = null
				newFilters.dispatcherName = null
			} else if (key === 'driverName') {
				newFilters.driver = null
				newFilters.driverName = null
				newFilters.drivers = null
			} else if (key === 'status') {
				newFilters.status = [
					'Delivered',
					'Invoiced',
					'Cancelled',
					'Paid',
					'Rejected',
				]
			} else {
				newFilters[key] = null
			}

			return { ...prevState, filters: newFilters }
		})
	}

	return (
		<div className="px-4 container mx-auto flex flex-col items-start">
			<Filters
				setFilters={setState}
				filters={state.filters}
				incrementWeek={incrementWeek}
				decrementWeek={decrementWeek}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
				tabs={tabsArray}
				handleSearchChange={handleSearchChange}
				setOpen={setOpen}
				handleEndDate={handleEndDate}
				handleStartDate={handleStartDate}
				totalSum={state?.totalSum}
				onDispatcherChange={onDispatcherChange}
				onDriverChange={onDriverChange}
				onStatusChange={onStatusChange}
			/>

			<SelectedFilters filters={state.filters} removeFilter={removeFilter} />

			<div className="w-full">
				{state.loading ? (
					<div className="mb-36 flex justify-center items-center h-40">
						<Spinner />
					</div>
				) : Array.isArray(state.loads) && state.loads.length > 0 ? (
					state.loads.map(load => (
						<Load
							key={load?._id}
							load={load}
							onRemoveLoad={removeLoadFromState}
							onEditLoad={handleEditLoad}
						/>
					))
				) : (
					<p className="font-medium text-center text-gray-700 text-2xl mt-16">
						No loads available for the selected filter.
					</p>
				)}

				{state.loads.length > 0 && (
					<Pagination
						next={nextPage}
						previous={previousPage}
						itemsCount={state.loadsCount}
						currentItems={state.offset}
						returnedLoads={state.loads?.length}
					/>
				)}
			</div>
			<EditLoadModal
				load={currentLoadToEdit}
				onClose={() => setShowEditModal(false)}
				onSave={updateLoadInState}
				open={showEditModal}
				setOpen={setShowEditModal}
			/>
			<div className="mb-8"></div>
			<Modal open={open} setOpen={setOpen} isWide={isAutoLoadActive}>
				<CreateNewLoad
					setOpen={setOpen}
					isAutoLoadActive={isAutoLoadActive}
					setIsAutoLoadActive={setIsAutoLoadActive}
				/>
			</Modal>
		</div>
	)
}
