import Select from 'react-select'
import toast from 'react-hot-toast'

import { updateLoadStatus } from '../../../services/httpService'
import { capitalizeString } from '../../../services/stateConversion'

const SelectStatus = ({ value, loadId }) => {
	let selected
	if (value) {
		selected = { name: value, label: capitalizeString(value) }
	}

	const options = [
		{ name: 'New', label: 'NEW' },
		{ name: 'Dispatched', label: 'DISPATCHED' },
		{ name: 'Cancelled', label: 'CANCELLED' },
		{ name: 'Delivered', label: 'DELIVERED' },
		{ name: 'Invoiced', label: 'INVOICED' },
		{ name: 'Rejected', label: 'REJECTED' },
		{ name: 'Paid', label: 'PAID' },
	]

	const handleChange = async newValue => {
		try {
			toast.success(`Load status updated`)

			await updateLoadStatus(loadId, newValue.label)
		} catch (error) {
			console.log(error)
			toast.error('Error updating load status')
		}
	}

	return (
		<div className="w-28">
			<Select
				defaultValue={selected}
				options={options}
				onChange={handleChange}
				components={{
					IndicatorSeparator: () => null,
				}}
				styles={{
					option: provided => ({
						...provided,
					}),
					menu: provided => ({ ...provided, zIndex: 9999 }),
					control: provided => ({
						...provided,
						minHeight: '30px',
						height: '30px',
					}),
					valueContainer: provided => ({
						...provided,
						height: '25px',
						padding: '0 4px',
					}),
					input: provided => ({ ...provided, margin: '0px' }),
					singleValue: provided => ({
						...provided,
						fontSize: '10px',
						marginBottom: '6px',
					}),
				}}
			/>
		</div>
	)
}

export default SelectStatus
