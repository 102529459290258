import StatementItems from './StatementItems'

import Spinner from '../../common/NewSpinner'

const calculateTotalGross = statements => {
	let amount = 0

	if (!statements.length) return amount

	statements.forEach(statement => {
		amount = statement?.totalAmount + amount
	})

	return amount.toFixed(2)
}

function numberWithCommas(number) {
	return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function StatementsTable({
	statements,
	loading,
	setEditorModalOpen,
	setSelectedStatement,
}) {
	if (loading)
		return (
			<div className="mt-8 flex justify-center items-center h-40">
				<Spinner />
			</div>
		)

	if (!loading && !statements?.length)
		return (
			<h3 className="text-3xl font-semibold mt-8 flex justify-center items-center h-40">
				No data found selected date range
			</h3>
		)

	const totalGross = calculateTotalGross(statements)

	return (
		<div className="bg-white shadow-lg rounded-md border border-gray-200 relative">
			<header className="px-5 py-4">
				<h2 className="font-semibold text-gray-800">
					Gross{' '}
					<span className="text-gray-500 font-medium">
						${numberWithCommas(totalGross)}
					</span>
				</h2>
			</header>
			<div>
				{/* Table */}
				<div className="overflow-x-auto">
					<table className="table-auto w-full">
						{/* Table header */}
						<thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
							<tr>
								<th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div className="font-semibold text-left">#</div>
								</th>

								<th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div className="font-semibold text-left">Driver</div>
								</th>

								<th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div className="font-semibold text-left">Total Gross</div>
								</th>

								<th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div className="font-semibold text-left">Total Miles</div>
								</th>

								<th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div className="font-semibold text-left">Price Per Mile</div>
								</th>

								<th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div className="font-semibold text-left">On Duty Hours</div>
								</th>

								<th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div className="font-semibold text-left">Period</div>
								</th>

								<th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div className="font-semibold text-left">Actions</div>
								</th>
							</tr>
						</thead>
						{/* Table body */}
						<tbody className="text-sm divide-y divide-gray-200">
							{statements.map((st, i) => {
								return (
									<StatementItems
										index={i}
										key={st?._id}
										statementId={st?._id}
										totalAmount={st?.totalAmount}
										totalHoursMS={st?.totalHoursMS}
										totalMiles={st?.totalMiles}
										driver={st?.driverFirstName + ' ' + st?.driverLastName}
										startDate={st?.startDate}
										endDate={st?.endDate}
										setEditorModalOpen={setEditorModalOpen}
										setSelectedStatement={setSelectedStatement}
										numberWithCommas={numberWithCommas}
									/>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default StatementsTable
