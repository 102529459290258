import React from 'react'

import { states, equipments } from '../../../../services/stateConversion'

export default function Select({
	label,
	defaultValue,
	onChangeHandler,
	required,
	submitted,
}) {
	const inputClassStyle = {
		border: submitted && !defaultValue ? '1px solid red' : 'none',
	}

	const labelType =
		label.toLowerCase().trim() === 'equipment' ? equipments : states

	let options = labelType

	return (
		<div className="relative mb-1">
			<label
				htmlFor={defaultValue}
				className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
			>
				{label}
				{required && <span className="text-red-500">*</span>}
			</label>
			<select
				style={inputClassStyle}
				value={defaultValue || ''}
				onChange={onChangeHandler}
				required={required}
				id={label}
				name={label}
				className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 text-xs shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
			>
				{!defaultValue && (
					<option value="" disabled>
						Select {label}
					</option>
				)}
				{options?.length &&
					options.map((option, index) => {
						return (
							<option key={index} value={option[1]}>
								{option[1]}
							</option>
						)
					})}
			</select>
		</div>
	)
}
