import moment from 'moment'

import StatementAdjustments from './StatementAdjustments'

function numberWithCommas(number) {
	return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default function StatementEditorContent({
	fetchedStatement,
	setShowInput,
	showInput,
	fetchStatement,
	adjustmentAmount,
	netAmount,
	minusPercent,
	loading,
}) {
	const totalAmount = fetchedStatement?.totalAmount
	const driver = fetchedStatement?.driver

	if (loading)
		return (
			<h1 className="text-gray-900 text-lg mx-96 my-44 font-extrabold">
				Loading...
			</h1>
		)

	return (
		<>
			<div className="mt-4 mb-4 px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="font-bold text-xl">
							{driver?.firstName} {driver?.lastName}
						</h1>
						<p className="text-sm underline decoration-1 text-gray-800">
							{moment(fetchedStatement?.startDate).format('MMM Do')} -{' '}
							{moment(fetchedStatement?.endDate).format('MMM Do, YYYY')}
						</p>

						<p className="mt-5 text-sm text-gray-700">
							Gross Revunue:{' '}
							<span className="font-medium">
								${numberWithCommas(totalAmount?.toFixed(2))}
							</span>
						</p>
						<p className="mt-2 text-sm text-gray-700">
							Adjustments:{' '}
							<span className="font-medium">
								${numberWithCommas(adjustmentAmount?.toFixed(2))}
							</span>
						</p>
						<p className="mt-2 text-sm text-gray-700">
							Net Amount:{' '}
							<span className="font-medium text-black-500">
								${numberWithCommas(netAmount?.toFixed(2))}
							</span>
						</p>
					</div>
				</div>
				<div className="mt-3 flex flex-col sm:-mx-6 md:mx-0">
					<table className="min-w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th
									scope="col"
									className="py-1 pl-1 text-left text-sm font-semibold text-gray-700"
								>
									Trip ID#
								</th>

								<th
									scope="col"
									className="py-1 pl-1 text-left text-sm font-semibold text-gray-700"
								>
									Date
								</th>

								<th
									scope="col"
									className="py-1 px-1 text-left text-sm font-semibold text-gray-700 sm:table-cell"
								>
									Origin
								</th>

								<th
									scope="col"
									className="hidden py-3.5 px-3 text-left text-sm font-semibold text-gray-700 sm:table-cell"
								>
									Destination
								</th>
								<th
									scope="col"
									className="py-1 pl-2 pr-2 text-left text-sm font-semibold text-gray-700 sm:pr-6 md:pr-0"
								>
									Distance
								</th>

								<th
									scope="col"
									className="py-1 pl-2 pr-2 text-left text-sm font-semibold text-gray-700 sm:pr-6 md:pr-0"
								>
									Pay Amount
								</th>
							</tr>
						</thead>
						<tbody>
							{fetchedStatement?.loads?.map((st, i) => (
								<tr
									key={i}
									className={`border-b border-gray-100 ${
										i % 2 == 0 && 'bg-gray-100'
									}`}
								>
									<td className="py-2 pl-2 pr-3 text-sm sm:pl-6 md:pl-0">
										<div className="font-medium text-gray-900">
											{st?.loadId}
										</div>
									</td>

									<td className="py-2 pl-2 pr-3 text-sm sm:pl-6 md:pl-0">
										<div className="font-medium text-gray-900">
											{moment(st?.pickupDate).format('MM-DD-YY')}
										</div>
									</td>

									<td className="py-2 pl-2 pr-3 text-sm sm:pl-6 md:pl-0">
										<div className="font-medium text-gray-900">
											{st?.originCity}, {st?.originState}
										</div>
									</td>

									<td className="py-2 pl-2 pr-3 text-sm sm:pl-6 md:pl-0">
										<div className="font-medium text-gray-900">
											{st?.deliveryCity}, {st?.deliveryState}
										</div>
									</td>

									<td className="py-2 pl-2 pr-3 text-sm sm:pl-6 md:pl-0">
										<div className="font-medium text-gray-900">
											{numberWithCommas(st?.distance)} mi
										</div>
									</td>

									<td className="pl-6 pr-2 text-sm">
										<div className="font-medium text-gray-900">
											${numberWithCommas(st?.price)}
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<StatementAdjustments
				fetchedStatement={fetchedStatement}
				setShowInput={setShowInput}
				showInput={showInput}
				fetchStatement={fetchStatement}
				minusPercent={minusPercent}
			/>
		</>
	)
}
