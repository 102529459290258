import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import axios from 'axios'
import moment from 'moment'
import { Tooltip } from 'react-tooltip'

import { LocationMarkerIcon } from '@heroicons/react/solid'

import { updateDispatchBoardDriver } from '../../services/httpService'

import { getCurrentUser } from '../../services/authService'

import './driverCard.css'

function getStatusColor(status) {
	switch (status) {
		case 'DRIVING':
			return 'bg-emerald-300 text-teal-700'
		case 'READY':
			return 'bg-red-500 text-gray-200'
		case 'REST':
			return 'bg-yellow-200 text-yellow-700'
		case 'HOME':
			return 'bg-gray-800 text-gray-200'
		case 'MAINTENANCE':
			return 'bg-stone-500 text-gray-100'
		default:
			return 'bg-gray-200 text-gray-700'
	}
}

const formatDelay = minutes => {
	const days = Math.floor(minutes / 1440)
	const hours = Math.floor((minutes % 1440) / 60)
	const minutesLeft = minutes % 60

	let result = ''
	if (days > 0) result += `${days}d `
	if (hours > 0) result += `${hours}hr `
	if (minutesLeft > 0 && !result?.length) result += `${minutesLeft}m`
	return result
}

const getEtaClass = delay => {
	if (delay < 0) {
		return 'text-blue-800'
	} else if (!delay) {
		return 'text-gray-700'
	} else {
		return 'text-red-500'
	}
}

const getRelativeTime = date => {
	if (!date) return 'years ago'

	const now = new Date()
	const inputDate = new Date(date)
	const diffInSeconds = (now - inputDate) / 1000

	if (diffInSeconds < 60) {
		return ' 1 min'
	} else if (diffInSeconds < 3600) {
		const minutes = Math.floor(diffInSeconds / 60)
		return ` ${minutes} min`
	} else if (diffInSeconds < 86400) {
		const hours = Math.floor(diffInSeconds / 3600)
		return ` ${hours} hr`
	} else if (diffInSeconds < 2592000) {
		const days = Math.floor(diffInSeconds / 86400)
		return ` ${days} day(s)`
	} else if (diffInSeconds < 31536000) {
		const months = Math.floor(diffInSeconds / 2592000)
		return ` ${months} mo`
	} else {
		const years = Math.floor(diffInSeconds / 31536000)
		if (years === 1) {
			return ' a year'
		}
		return ` years`
	}
}

function DriverCard({ driver, onSelectDriver }) {
	const [status, setStatus] = useState(driver?.status || 'N/A')
	const [eta, setETA] = useState('')
	const [distanceRemaining, setDistanceRemaining] = useState(0)
	const [delay, setDelay] = useState(0)
	const [nextStop, setNextStop] = useState({})

	const driverName = `${driver?.firstName} ${driver?.lastName}`
	const teamDriverName = driver?.teamDriver?.firstName
		? `${driver?.teamDriver?.firstName} ${driver?.teamDriver?.lastName}`
		: null
	const truckNumber = driver?.truck?.number
	const driverId = driver?._id
	const currUser = getCurrentUser()

	const nextStopAdress = `${nextStop?.company} ${nextStop?.city}, ${nextStop?.state}`

	const nextTimezone = nextStop?.timezone

	const nextStopDate = moment
		.utc(nextStop?.date)
		?.tz(nextTimezone)
		?.format('MMM D, h:mm A z')

	const loadId = driver?.loads?.length ? driver?.loads[0]?.loadId : null

	const recentNote = driver?.recentNote?.length ? driver?.recentNote[0] : null

	useEffect(() => {
		setStatus(driver?.status)
	}, [driver?.status])

	useEffect(() => {
		const fetchDriverETA = async () => {
			const load = driver?.loads?.length ? driver?.loads[0] : {}
			const stopCheck = load?.stops?.filter(stop => stop?.status === 'PLANNED')

			if (stopCheck?.length) {
				const nextStopLocation = stopCheck[0]

				setNextStop(nextStopLocation)

				const stopLocation =
					nextStopLocation?.company !== 'HOU2'
						? nextStopLocation?.coordinates
						: { lat: 29.9344439, lng: -95.4316995 }

				const driverLocation = driver?.location?.coordinates

				const nextStopTimeZone = nextStopLocation?.timezone

				if (!nextStopTimeZone) {
					console.error('Timezone is undefined for the next stop.')
					return
				}

				try {
					const pu = driverLocation
					const del = stopLocation

					if (!pu || !del) return

					const token =
						'pk.eyJ1IjoidWZhcnJ1aCIsImEiOiJjbHEyN3VmOHcwMTljMmtuenZqa2V2bzVhIn0.D0nuHleLNCsGiRPqbqpDdg'

					let response = await axios.get(
						`https://api.mapbox.com/directions/v5/mapbox/driving/${pu?.lng},${pu?.lat};${del?.lng},${del?.lat}?geometries=geojson&access_token=${token}`
					)

					const routeData = response?.data?.routes[0]
					const remainingDist = routeData?.distance
					const remainingDistanceMiles = Math.floor(remainingDist / 1609)

					setDistanceRemaining(remainingDistanceMiles)

					const etaInSeconds = routeData?.duration

					const etaTime = moment()
						?.tz(nextStopTimeZone)
						?.add(etaInSeconds, 'seconds')

					setETA(etaTime.format('MMM D, h:mm A'))

					const plannedTime = nextStopLocation?.date
					const delayMinutes = etaTime.diff(moment(plannedTime), 'minutes')

					setDelay(delayMinutes)
				} catch (error) {
					console.error('Error getting ETA:', error)
				}
			}
		}

		fetchDriverETA()
	}, [driver])

	const handleStatusChange = async event => {
		const newValue = event.target.value
		const initialStatus = status

		setStatus(newValue)

		try {
			const update = await updateDispatchBoardDriver(
				driverId,
				newValue,
				'STATUS',
				currUser?.name
			)

			if (!update) {
				setStatus(initialStatus)
				toast.error('Failed to update status. Please try again.')
			} else {
				toast.success('Status updated successfully!')
			}
		} catch (error) {
			setStatus(initialStatus)
			console.error('Error updating status:', error)
			toast.error(
				'Unexpected error occurred while updating status. Please try again.'
			)
		}
	}

	const renderNoteSection = recentNote => {
		if (recentNote) {
			return (
				<div
					className="bg-blue-50 p-2 rounded-lg max-w-xs -mt-1 cursor-pointer hover:bg-blue-100"
					onClick={onSelectDriver}
				>
					<div className="text-xxs text-gray-800">{recentNote.content}</div>
					<div className="text-xxs italic text-gray-600">
						{getRelativeTime(recentNote.createdAt)} ago
					</div>
				</div>
			)
		} else {
			return (
				<div
					onClick={onSelectDriver}
					className="mr-4 text-xxs text-blue-500 cursor-pointer hover:underline"
				>
					Add Note
				</div>
			)
		}
	}

	const locationSource = driver.location?.source
		? `${driver.location?.source}
	 updated ${
			driver?.location?.time && `${getRelativeTime(driver?.location?.time)} ago`
		}`
		: 'Location not available'

	return (
		<div className="border p-3 my-1 mx-3 w-full px-4 rounded shadow-sm bg-white overflow-x-auto">
			<div className="grid grid-cols-12 gap-1">
				<div className="col-span-3 flex flex-col">
					<div
						className="font-semibold text-sm cursor-pointer"
						onClick={onSelectDriver}
					>
						<div>
							<span
								className={`${
									truckNumber
										? 'text-xs mr-1 bg-gray-700 badge rounded text-gray-100 font-weight-bold px-2 py-2'
										: ''
								} `}
							>
								{truckNumber ? `${truckNumber}` : ''}
							</span>
							<span>{driverName}</span>
						</div>
						<span>{teamDriverName}</span>
					</div>

					<div className="flex items-center mt-0">
						<select
							value={status}
							onChange={handleStatusChange}
							className={`mt-1 rounded text-sm px-2 py-1 w-2/6 focus:outline-none focus:border-none border-none ${getStatusColor(
								status
							)}`}
						>
							<option value="READY">Ready</option>
							<option value="REST">Rest</option>
							<option value="DRIVING">Driving</option>
							<option value="HOME">Home</option>
							<option value="MAINTENANCE">Maintenance</option>
						</select>

						<p className="italic ml-2 text-gray-700 text-sm">
							for {getRelativeTime(driver?.lastStatusUpdate)}
						</p>
					</div>
				</div>

				{/* <div className="col-span-1 flex flex-col">
					<p className="font-semibold text-gray-700 text-xs text-left">
						{driver?.dispatcherInfo?.name || 'N/A'}
					</p>
					<p className="mt-2 text-gray-500 text-xs text-left">Dispatcher</p>
				</div> */}

				<Tooltip id="my-tooltip" />

				<div className="col-span-2 flex flex-col items-center">
					<a data-tooltip-id="my-tooltip" data-tooltip-content={locationSource}>
						<p className="font-semibold text-gray-700 text-xs text-center">
							{driver?.location?.city || 'Location not available'}
						</p>
						<div className="flex items-center text-gray-500 text-xs text-center">
							<LocationMarkerIcon className="mt-2 w-4 h-4 mr-1" />

							<p className="mt-2 text-xs">
								Location{' '}
								<span className="italic">
									{driver?.location?.time &&
										`${getRelativeTime(driver?.location?.time)} ago`}
								</span>
							</p>
						</div>
					</a>
				</div>

				<div className="-ml-4 divider divider-horizontal"></div>

				<div className="col-span-3 flex flex-col">
					<p
						className={`font-semibold text-xs ${getEtaClass(
							delay,
							driverName
						)}`}
						style={{ whiteSpace: 'nowrap' }}
					>
						{driver?.loads?.length
							? `${distanceRemaining} mi left | ETA ${eta}` +
							  (delay > 0 ? ` ~ ${formatDelay(delay)} late` : '')
							: 'Assign a load to track.'}
					</p>

					<p className="mt-2 text-gray-800 text-xs">
						{driver?.loads?.length ? `load#  ${loadId}` : 'No Load Assigned.'}
					</p>
				</div>

				<div className="col-span-2">
					{driver?.loads?.length ? (
						<div className="flex flex-col">
							<p className="font-semibold text-gray-700 text-xs text-center">
								{nextStopAdress}
							</p>
							<p className="mt-2 text-gray-500 text-xs text-center">
								{nextStopDate}
							</p>
						</div>
					) : (
						<div className="flex flex-col">
							<p className="font-semibold text-gray-700 text-xs text-center">
								Not Set
							</p>
							<p className="mt-2 text-gray-500 text-xs text-center">
								End Location
							</p>
						</div>
					)}
				</div>

				<div className="col-span-1 flex flex-col items-end justify-center pr-4">
					{renderNoteSection(recentNote)}
				</div>
			</div>
		</div>
	)
}

export default DriverCard
