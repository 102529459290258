import React, { useState } from 'react'
import axios from 'axios'

function AddLoadIntegrationModal({ company, onClose, user }) {
	const [integration, setIntegration] = useState('')
	const [apiKey, setApiKey] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const handleSubmit = async () => {
		const userId = user?._id

		const payload = {
			integration,
			apiKey,
			email,
			password,
			userId,
		}

		try {
			const companyId = company?._id

			await axios.put(`/api/company/${companyId}/integration`, payload)
			alert('Data sent successfully')
			onClose()
		} catch (error) {
			alert('Error sending data')
		}
	}

	return (
		<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
			<div className="bg-white rounded-lg p-8 w-1/2 shadow">
				<h2 className="text-black text-2xl mb-6">Add New Integration</h2>

				<div className="mb-4">
					<select
						className="block w-full bg-gray-100 text-black rounded px-4 py-2"
						id="integrationSelect"
						value={integration}
						onChange={e => setIntegration(e.target.value)}
					>
						<option value="" disabled>
							Choose Options
						</option>
						<option value="TTELD">TTELD</option>
						<option value="RELAYAMAZON">Relay Amazon</option>
						<option value="BLUESTARELD">Blue Start ELD</option>
					</select>
				</div>

				{integration === 'TTELD' || integration === 'BLUESTARELD' ? (
					<div className="mb-4">
						<label
							className="block text-black text-sm mb-2"
							htmlFor="apiKeyInput"
						>
							API Key
						</label>
						<input
							className="block w-full bg-gray-100 text-black rounded px-4 py-2"
							type="text"
							id="apiKeyInput"
							placeholder="Type your API key"
							value={apiKey}
							onChange={e => setApiKey(e.target.value)}
						/>
					</div>
				) : integration === 'RELAYAMAZON' ? (
					<>
						<div className="mb-4">
							<label
								className="block text-black text-sm mb-2"
								htmlFor="emailInput"
							>
								Email
							</label>
							<input
								className="block w-full bg-gray-100 text-black rounded px-4 py-2"
								type="email"
								id="emailInput"
								placeholder="Type your email"
								value={email}
								onChange={e => setEmail(e.target.value)}
							/>
						</div>

						<div className="mb-6">
							<label
								className="block text-black text-sm mb-2"
								htmlFor="passwordInput"
							>
								Password
							</label>
							<input
								className="block w-full bg-gray-100 text-black rounded px-4 py-2"
								type="password"
								id="passwordInput"
								placeholder="Type your password"
								value={password}
								onChange={e => setPassword(e.target.value)}
							/>
						</div>
					</>
				) : null}
				<div className="flex justify-end">
					<button
						className="bg-gray-300 text-black rounded px-4 py-2 mr-2"
						onClick={onClose}
					>
						CLOSE
					</button>
					<button
						className="bg-green-500 text-white rounded px-4 py-2"
						onClick={handleSubmit}
					>
						SUBMIT
					</button>
				</div>
			</div>
		</div>
	)
}

export default AddLoadIntegrationModal
