import Select from 'react-select'

const SelectStatus = ({ value, onChange }) => {
	let selected
	if (value) {
		selected = { name: value, label: value }
	}

	const options = [
		{ name: 'Cancelled', label: 'CANCELLED' },
		{ name: 'Delivered', label: 'DELIVERED' },
		{ name: 'Invoiced', label: 'INVOICED' },
		{ name: 'Rejected', label: 'REJECTED' },
		{ name: 'Not Paid', label: 'NOT PAID' },
		{ name: 'Paid', label: 'PAID' },
	]

	const handleChange = async newValue => {
		onChange(newValue)
	}

	return (
		<div className="">
			<Select
				defaultValue={selected}
				options={options}
				onChange={handleChange}
				placeholder="Select status..."
				components={{
					IndicatorSeparator: () => null,
				}}
				styles={{
					menu: provided => ({ ...provided, zIndex: 9999 }),
					control: provided => ({
						...provided,
						minHeight: '30px',
						height: '30px',
					}),
					valueContainer: provided => ({
						...provided,
						height: '30px',
						padding: '0 6px',
					}),
					input: provided => ({ ...provided, margin: '0px' }),
					singleValue: provided => ({
						...provided,
						fontSize: '10px',
						marginBottom: '8px',
					}),
					option: (provided, state) => ({
						...provided,
						fontSize: '0.65rem',
						padding: '8px 12px',
						backgroundColor: state.isSelected ? 'blue' : 'white',
						color: state.isSelected ? 'white' : 'black',
						':active': {
							...provided[':active'],
							backgroundColor:
								!state.isDisabled && (state.isSelected ? 'blue' : 'lightgray'),
						},
					}),
				}}
			/>
		</div>
	)
}

export default SelectStatus
