import axios from 'axios'
import moment from 'moment'
import { getCurrentUser, getDefaultCompany } from './authService'
import { convertState } from './stateConversion'

const userToken = localStorage.getItem('jwtToken')

export const getUsers = async cancelToken => {
	try {
		const { id } = getCurrentUser()
		const { _id } = getDefaultCompany()

		const res = await axios.get(
			`/api/dispatchBoard?userId=${id}&companyId=${_id}`,
			{
				cancelToken,
				headers: {
					authorization: userToken,
				},
			}
		)

		return res
	} catch (err) {
		if (err.name === 'CanceledError') return 'CanceledError'
		return 'err'
	}
}

export const getActiveLoads = async () => {
	try {
		const { id } = getCurrentUser()
		const { _id } = getDefaultCompany()

		const res = await axios.get(
			`/api/activeloads?userId=${id}&companyId=${_id}`
		)
		return res
	} catch (err) {
		console.log(err)
	}
}

export const getDispatchers = async () => {
	try {
		const company = getDefaultCompany()

		const res = await axios.get(
			`/api/user/dispatchers?companyId=${company._id}`
		)

		return res.data
	} catch (err) {
		console.log(err)
	}
}

const _formatCity = objs => {
	const formattedData = objs.map(obj => {
		const [city, state_name, country] = obj.place_name.split(',')
		const state_id = convertState(state_name)
		const [lng, lat] = obj.center

		return { city, state_id, lng, lat }
	})

	return formattedData
}

export const searchCity = async searchInput => {
	try {
		const cleanStr = searchInput.replace(/[^a-zA-Z ]/g, '')

		const token =
			'pk.eyJ1IjoidWZhcnJ1aCIsImEiOiJjbHEyN3VmOHcwMTljMmtuenZqa2V2bzVhIn0.D0nuHleLNCsGiRPqbqpDdg'

		const axiosRes = await axios.get(
			`https://api.mapbox.com/geocoding/v5/mapbox.places/${cleanStr}.json?access_token=${token}&cachebuster=1627451746100&autocomplete=true&country=us&types=place&limit=15`
		)

		const result = _formatCity(axiosRes.data.features)

		return result
	} catch (error) {
		console.log(error)
	}
}

export const getTimezone = async (lng, lat) => {
	try {
		const token =
			'pk.eyJ1IjoidWZhcnJ1aCIsImEiOiJjbHEyN3VmOHcwMTljMmtuenZqa2V2bzVhIn0.D0nuHleLNCsGiRPqbqpDdg'

		let res = await axios.get(
			`https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${lng},${lat}.json?access_token=${token}`
		)

		return res.data.features[0].properties.TZID
	} catch (err) {
		console.log(err)
	}
}

export const getDrivers = async signal => {
	try {
		const { id } = getCurrentUser()
		const { _id } = getDefaultCompany()

		const res = await axios.get(`/api/drivers?userId=${id}&companyId=${_id}`, {
			// cancelToken: signal,
		})

		return res.data
	} catch (error) {
		if (axios.isCancel(error)) {
			console.log('Request canceled', error.message)
			return 'CanceledError'
		}

		throw error
	}
}

// export const getDrivers = async cancelToken => {
// 	try {
// 		const response = await axios.get('/api/drivers', {
// 			cancelToken: cancelToken,
// 		})
// 		return response.data
// 	} catch (error) {
// 		if (axios.isCancel(error)) {
// 			console.log('Request canceled', error.message)
// 			return 'CanceledError'
// 		}
// 		throw error
// 	}
// }

export const getTrucks = async () => {
	try {
		const { id } = getCurrentUser()
		const { _id } = getDefaultCompany()

		const res = await axios.get(`/api/trucks?userId=${id}&companyId=${_id}`)

		return res.data
	} catch (err) {
		console.log(err)
	}
}

export const updateCompanyInfo = async company => {
	try {
		const { _id } = getDefaultCompany()

		const res = await axios.put(`/api/company/${_id}/update`, company)

		return res.data
	} catch (error) {
		console.log('error', error)
	}
}

export const getSelectDispatchers = async () => {
	try {
		const { id } = getCurrentUser()
		const { _id } = getDefaultCompany()

		const res = await axios.get(
			`/api/dispatchers?userId=${id}&companyId=${_id}`
		)

		return res.data
	} catch (error) {
		console.log(error)
	}
}

export const updateLoadDispatcher = async (loadId, newDispatcherId) => {
	try {
		const company = getDefaultCompany()

		const res = await axios.put(
			`/api/loads/updateDispatcher`,
			{ loadId, newDispatcherId, companyId: company._id },
			{
				headers: {
					authorization: userToken,
				},
			}
		)

		return res.data
	} catch (err) {
		console.log(err)
	}
}

export const createDriver = async data => {
	try {
		const { id } = getCurrentUser()
		const { _id } = getDefaultCompany()

		const res = await axios.post(`/api/drivers?userId=${id}&companyId=${_id}`, {
			...data,
		})
		return res
	} catch (err) {
		console.log(err)
	}
}

export const downloadLoads = async filters => {
	try {
		const { id } = getCurrentUser()
		const { _id } = getDefaultCompany()

		const loads = await axios.get(
			`/api/loads/download?companyId=${_id}&user=${id}&filters=${JSON.stringify(
				filters
			)}`,
			{
				headers: {
					authorization: userToken,
				},
			}
		)

		const res = loads?.data?.map(l => {
			return { ...l, dropoffDate: moment(l?.dropoffDate).format('lll') }
		})

		return res
	} catch (error) {
		console.log(error)
	}
}

export const getLoads = async (
	searchInput,
	limit,
	offset,
	filters,
	cancelToken
) => {
	try {
		const { id } = getCurrentUser()
		const { _id } = getDefaultCompany()

		let res

		if (searchInput && searchInput?.length > 2) {
			res = await axios.get(
				`/api/loads?companyId=${_id}&user=${id}&offset=${offset || 0}&limit=${
					limit || 20
				}&searchInput=${searchInput}&filters=${JSON.stringify(filters)}`,
				{
					headers: {
						authorization: userToken,
					},
					cancelToken,
				}
			)
		} else {
			res = await axios.get(
				`/api/loads?companyId=${_id}&user=${id}&offset=${offset || 0}&limit=${
					limit || 20
				}&filters=${JSON.stringify(filters)}`,
				{
					headers: {
						authorization: userToken,
					},
					cancelToken,
				}
			)
		}

		return res.data
	} catch (err) {
		// console.log(err);
		return err
	}
}

export const updateLoadDriverId = async (loadId, newDriverId, oldDriverId) => {
	// try {
	// const { company } = getCurrentUser()
	const company = getDefaultCompany()

	const res = await axios.put(
		`/api/loads/updateDriver`,
		{ loadId, newDriverId, oldDriverId, companyId: company._id },
		{
			headers: {
				authorization: userToken,
			},
		}
	)

	return res.data
	// } catch (err) {
	// 	console.log(err)
	// }
}

export const updateDriverTruck = async (
	driverId,
	truckId,
	newTruckId,
	oldTruckId
) => {
	const company = getDefaultCompany()

	const res = await axios.put(
		`/api/driver-truck/update`,
		{ driverId, truckId, newTruckId, oldTruckId, companyId: company._id },
		{
			headers: {
				authorization: userToken,
			},
		}
	)

	return res.data
}

export const updateLoadStatus = async (loadId, newStatus, userId) => {
	try {
		const res = await axios.put(`/api/loads/status`, {
			loadId,
			newStatus,
			userId,
		})

		return res
	} catch (error) {
		console.log(error)
	}
}

export const getInvitations = async userId => {
	try {
		const { id } = getCurrentUser()
		const { _id } = getDefaultCompany()
		const res = await axios.get(`/api/invites?userId=${id}&companyId=${_id}`)
		return res
	} catch (err) {
		console.log(err)
	}
}

export const sendInvitation = async data => {
	try {
		const { _id } = getDefaultCompany()
		const res = await axios.post(`/api/invites`, { ...data, companyId: _id })
		return res
	} catch (err) {
		console.log(err)
	}
}

export const getActivity = async (driverId, limit) => {
	try {
		const res = await axios.get(
			`/api/activity/${driverId}?limit=${limit || 10}&offset=0`
		)
		return res
	} catch (err) {
		console.log(err)
	}
}

export const getUser = async () => {
	try {
		const { id } = getCurrentUser()

		const res = await axios.get(`/api/user/settings/${id}`, {
			headers: {
				authorization: userToken,
			},
		})
		return res
	} catch (err) {
		console.log(err)
	}
}

export const getDriverActivityAndGross = async ({ period, driverId }) => {
	try {
		const res = await axios.get(
			`/api/activity/${driverId}/hour?period=${JSON.stringify(period)}`
		)

		return res.data
	} catch (err) {
		console.log(err)
	}
}

export const getDashboardStats = async (
	startDate,
	endDate,
	prevStartDate,
	prevEndDate,
	companyId,
	cancelToken
) => {
	try {
		const res = await axios.get(
			`/api/dashboard/stats?startDate=${JSON.stringify(
				startDate
			)}&endDate=${JSON.stringify(endDate)}&prevStartDate=${JSON.stringify(
				prevStartDate
			)}&prevEndDate=${JSON.stringify(prevEndDate)}&companyId=${companyId}`,
			{ cancelToken }
		)

		return res.data
	} catch (err) {
		console.log('My error:', err)
	}
}

export const getDriverTableStats = async (
	startDate,
	endDate,
	prevStartDate,
	prevEndDate,
	companyId
) => {
	try {
		const res = await axios.get(
			`/api/dashboard/driverStats?startDate=${JSON.stringify(
				startDate
			)}&endDate=${JSON.stringify(endDate)}&prevStartDate=${JSON.stringify(
				prevStartDate
			)}&prevEndDate=${JSON.stringify(prevEndDate)}&companyId=${companyId}`
		)

		return res.data
	} catch (err) {
		console.log('My error:', err)
	}
}

export const getDispatchTableStats = async (
	startDate,
	endDate,
	prevStartDate,
	prevEndDate,
	companyId,
	cancelToken
) => {
	try {
		const res = await axios.get(
			`/api/dashboard/dispatchersStats?startDate=${startDate}&endDate=${endDate}&prevStartDate=${prevStartDate}&prevEndDate=${prevEndDate}&companyId=${companyId}`,
			{ cancelToken }
		)

		return res.data
	} catch (err) {
		console.log('My error:', err)
	}
}

export const createBusiness = async (userId, companyName) => {
	try {
		const result = await axios.post('/api/user/addNewBusiness', {
			userId,
			companyName,
		})

		return result.data
	} catch (error) {
		console.log(error)
	}
}

export const updateDispatchBoardDriver = async (
	driverId,
	value,
	type,
	userName
) => {
	try {
		const result = await axios.put('/api/dispatchBoard/driver/update', {
			driverId,
			value,
			type,
			userName,
		})

		return true
	} catch (error) {
		console.log(error)
		return false
	}
}

export const updateDriverStatus = async driver => {
	try {
		const result = await axios.put('/api/updateDriverStatus', {
			_id: driver._id,
			isActive: driver?.isActive,
		})

		return result.data
	} catch (error) {
		console.log(error)
	}
}

export const deleteDriver = async driverId => {
	try {
		const user = getCurrentUser()
		const { _id } = getDefaultCompany()

		const url = `/api/driver/${driverId}/delete`

		const bodyData = {
			companyId: _id,
			userId: user?.id,
		}

		const response = await axios.delete(url, {
			headers: {
				Authorization: 'Bearer ' + userToken,
			},
			data: bodyData,
		})

		return response
	} catch (error) {
		console.error('Error in deleteDriver:', error)
		throw error // Throw the error for the caller to handle
	}
}

export const getStatements = async (filters, cancelToken) => {
	try {
		const { _id } = getDefaultCompany()

		const { startDate, endDate, weekNumber } = filters

		const result = await axios.get(
			`/api/statements?startDate=${JSON.stringify(
				startDate
			)}&endDate=${JSON.stringify(
				endDate
			)}&companyId=${_id}&weekNumber=${weekNumber}`,
			{ cancelToken }
		)

		return result?.data
	} catch (err) {
		if (err.name === 'CanceledError') return 'CanceledError'
		return 'err'
	}
}

export const generateStatements = async filters => {
	try {
		const { startDate, endDate, weekNumber } = filters

		const { _id } = getDefaultCompany()

		const result = await axios.get(
			`/api/statementsGen?startDate=${JSON.stringify(
				startDate
			)}&endDate=${JSON.stringify(
				endDate
			)}&companyId=${_id}&weekNumber=${weekNumber}`
		)

		return result?.data
	} catch (error) {
		console.log(error)
	}
}

export const getSingleStatement = async statementId => {
	try {
		const { _id } = getDefaultCompany()

		const result = await axios.get(
			`/api/statement?statementId=${statementId}&companyId=${_id}`
		)

		return result?.data
	} catch (error) {
		console.log(error)
	}
}

export const updateAdjustment = async (statementId, data) => {
	try {
		const { _id } = getDefaultCompany()

		const result = await axios.put(
			`api/statement?statementId=${statementId}&companyId=${_id}`,
			{
				data,
			}
		)
		return result?.data
	} catch (error) {
		console.log(error)
	}
}

export const deleteAdjustment = async (adjustment, statementId, driverId) => {
	try {
		const { _id } = getDefaultCompany()

		const result = await axios.delete(
			`api/statement?statementId=${statementId}`,
			{ data: { companyId: _id, driverId: driverId, ...adjustment } }
		)

		return result?.data
	} catch (error) {
		console.log(error)
	}
}

export const updateDriverDispatcher = async (driverId, newDispatcherId) => {
	try {
		const { _id } = getDefaultCompany()

		const result = await axios.patch(`/api/drivers/update-dispatcher`, {
			driverId,
			newDispatcherId,
			companyId: _id,
		})

		return result?.data
	} catch (error) {
		console.log(error)
	}
}

export const updateTeamDriver = async (newDriverId, oldDriverId) => {
	try {
		const { _id } = getDefaultCompany()

		const result = await axios.patch(`/api/drivers/update-team-driver`, {
			newDriverId,
			oldDriverId,
			companyId: _id,
		})

		return result?.data
	} catch (error) {
		console.log(error)
		return error
	}
}
