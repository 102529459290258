import React from 'react'

function Spinner({ className }) {
	const spinnerClass = className || 'w-14 h-14 mt-32'

	return (
		<div className="relative">
			<span
				className={`${spinnerClass} loading loading-spinner loading-lg`}
			></span>
		</div>
	)
}

export default Spinner
