import React, { useState, useEffect } from 'react'
import Downshift from 'downshift'
import axios from 'axios'

import './autocomplete.css'

// fix here
const AutocompleteCityOrigin = props => {
	const { handleAddressChange, name, label, required, stop } = props

	const address = stop?.address || ''

	const [addresses, setAddresses] = useState([])
	const [inputDisplayValue, setInputDisplayValue] = useState(address)

	const onInputValueChange = async inputValue => {
		setInputDisplayValue(inputValue)

		if (!inputValue || inputValue.length < 3) {
			handleAddressChange(null)
			setAddresses([])
			return
		}

		const mapboxToken =
			'pk.eyJ1IjoidWZhcnJ1aCIsImEiOiJjbHEyN3VmOHcwMTljMmtuenZqa2V2bzVhIn0.D0nuHleLNCsGiRPqbqpDdg'
		const input = encodeURIComponent(inputValue)

		const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?access_token=${mapboxToken}&autocomplete=true&country=US&types=address&limit=5`

		try {
			const response = await axios.get(url)
			const suggestions = response.data.features.map(feature => {
				const parts = feature.place_name.split(',')

				const street = parts[0]
				const city = parts[1]

				const state = feature.context
					.find(c => c.id.startsWith('region'))
					.short_code.split('-')[1]

				const zipcode = feature.context.find(c => c.id.startsWith('postcode'))

				return {
					id: feature.id,
					address: `${street}, ${city}, ${state} ${zipcode?.text}`,
					center: feature.center,
				}
			})
			setAddresses(suggestions)
		} catch (error) {
			console.error('Error fetching suggestions:', error)
		}
	}

	const handleSelectedItemChange = selectedItem => {
		handleAddressChange(selectedItem)

		const [selectedAddress] = selectedItem?.address?.split(',')

		setInputDisplayValue(selectedItem ? selectedAddress?.trim() : '')
	}

	useEffect(() => {
		if (address) {
			setInputDisplayValue(address)
		}
	}, [address])

	return (
		<Downshift
			selectedItem={address}
			onInputValueChange={onInputValueChange}
			onChange={handleSelectedItemChange}
			itemToString={item => (item ? item.address : '')}
		>
			{({
				getInputProps,
				getItemProps,
				getMenuProps,
				isOpen,
				highlightedIndex,
			}) => (
				<div className="autocomplete-container relative mb-1">
					<label
						htmlFor={name}
						className="absolute -top-2 left-2 bg-white px-1 text-xs font-medium text-gray-500"
					>
						{label}
						{required && <span className="text-red-500">*</span>}
					</label>
					<input
						{...getInputProps({
							name,
							placeholder: 'Type to search...',
							value: inputDisplayValue,
							className:
								'p-2 block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm',
						})}
					/>
					<ul className="dropdown-menu light-mode" {...getMenuProps()}>
						{isOpen &&
							addresses.map((item, index) => (
								<li
									{...getItemProps({ key: item.id, index, item })}
									className={`dropdown-item light-mode ${
										highlightedIndex === index ? 'is-highlighted' : ''
									}`}
								>
									{item.address}
								</li>
							))}
					</ul>
				</div>
			)}
		</Downshift>
	)
}

export default AutocompleteCityOrigin
