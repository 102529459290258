import moment from 'moment-timezone'

export const setDateFilterHelper = period => {
	let result
	let startDate, endDate, prevStartDate, prevEndDate

	if (!period || period === 'WEEK') {
		const now = moment.tz('America/Los_Angeles')

		startDate = now
			.clone()
			.startOf('week')
			.hour(0)
			.minute(0)
			.second(0)
			.millisecond(0)

		endDate = now
			.clone()
			.endOf('week')
			.hour(23)
			.minute(59)
			.second(59)
			.millisecond(999)

		prevStartDate = startDate.clone().subtract(1, 'week')
		prevEndDate = endDate.clone().subtract(1, 'week')

		result = {
			period: 'WEEK',
			startDate: startDate.toDate(),
			endDate: endDate.toDate(),
			prevStartDate: prevStartDate.toDate(),
			prevEndDate: prevEndDate.toDate(),
		}
	} else if (period === 'MONTH') {
		const now = moment.tz('America/Los_Angeles')

		startDate = now
			.clone()
			.startOf('month')
			.hour(0)
			.minute(0)
			.second(0)
			.millisecond(1)

		endDate = now
			.clone()
			.endOf('month')
			.hour(23)
			.minute(59)
			.second(59)
			.millisecond(99)

		prevStartDate = startDate.clone().subtract(1, 'month')
		prevEndDate = endDate.clone().subtract(1, 'month')

		result = {
			period: 'MONTH',
			startDate: startDate.toDate(),
			endDate: endDate.toDate(),
			prevStartDate: prevStartDate.toDate(),
			prevEndDate: prevEndDate.toDate(),
		}
	}

	return result
}

export function prevMonth(data) {
	let { startDate, endDate, period } = data

	const timeZone = 'America/Los_Angeles'

	startDate = moment
		.tz(startDate, timeZone)
		.subtract(1, 'month')
		.startOf('month')
		.hour(0)
		.minute(0)
		.second(0)
		.millisecond(1)

	endDate = moment
		.tz(endDate, timeZone)
		.subtract(1, 'month')
		.endOf('month')
		.hour(23)
		.minute(59)
		.second(59)
		.millisecond(99)

	let prevStartDate = moment
		.tz(startDate, timeZone)
		.subtract(1, 'month')
		.startOf('month')
		.hour(0)
		.minute(0)
		.second(0)
		.millisecond(1)

	let prevEndDate = moment
		.tz(endDate, timeZone)
		.subtract(1, 'month')
		.endOf('month')
		.hour(23)
		.minute(59)
		.second(59)
		.millisecond(99)

	return { startDate, endDate, prevStartDate, prevEndDate, period }
}

export function nextMonth(data) {
	let { startDate, endDate, period } = data

	const timeZone = 'America/Los_Angeles'

	startDate = moment
		.tz(startDate, timeZone)
		.add(1, 'month')
		.startOf('month')
		.hour(0)
		.minute(0)
		.second(0)
		.millisecond(1)

	endDate = moment
		.tz(endDate, timeZone)
		.add(1, 'month')
		.endOf('month')
		.hour(23)
		.minute(59)
		.second(59)
		.millisecond(99)

	let prevStartDate = moment
		.tz(startDate, timeZone)
		.subtract(1, 'month')
		.toDate()
	let prevEndDate = moment.tz(endDate, timeZone).subtract(1, 'month').toDate()

	return { startDate, endDate, prevStartDate, prevEndDate, period }
}
