import Axios from 'axios'
import jwtDecode from 'jwt-decode'

const _simpleLogin = user => {
	Axios.post('/api/login', user).then(res => {
		const { token } = res.data

		localStorage.setItem('jwtToken', token)
		localStorage.setItem('email', user.email)
		return (window.location = '/')
	})
}

export async function register(user) {
	await Axios.post('/api/register', user)
		.then(() => {
			const savedModal = document.getElementById('successfullyCreatedModal')

			savedModal.style.display = 'block'
			setTimeout(() => {
				savedModal.style.display = 'none'
				_simpleLogin(user)
			}, 1000)
		})
		.catch(err => {
			const modal = document.getElementById('modalForSignupError')

			modal.style.display = 'block'
			modal.textContent = 'Something went wrong!'

			return setTimeout(() => {
				modal.style.display = 'none'
			}, 3000)
		})
}

export async function inviteRegister(userData) {
	await Axios.post('/api/register/invite', userData)
		.then(() => {
			const savedModal = document.getElementById('successfullyCreatedModal')

			savedModal.style.display = 'block'
			setTimeout(() => {
				savedModal.style.display = 'none'
				_simpleLogin(userData)
			}, 1000)
		})
		.catch(err => {
			const modal = document.getElementById('modalForSignupError')

			modal.style.display = 'block'
			modal.textContent = 'Something went wrong!'

			return setTimeout(() => {
				modal.style.display = 'none'
			}, 3000)
		})
}

export async function acceptInvite(userData) {
	await Axios.post('/api/register/accept-invite', userData)
		.then(() => {
			const savedModal = document.getElementById('successfullyCreatedModal')

			savedModal.style.display = 'block'
			setTimeout(() => {
				savedModal.style.display = 'none'
			}, 2000)
		})
		.catch(err => {
			console.log('err', err)
			const modal = document.getElementById('modalForSignupError')

			modal.style.display = 'block'
			modal.textContent = `${err.message}`

			return setTimeout(() => {
				modal.style.display = 'none'
			}, 3000)
		})
}

const _setCompany = company => {
	localStorage.setItem('defaultCompany', JSON.stringify(company))
}

export async function login(user) {
	return await Axios.post('/api/login', user)
		.then(res => {
			const { token } = res.data

			localStorage.setItem('jwtToken', token)
		})
		.then(() => {
			const logged = document.getElementById('loggedInMsg')
			const user = getCurrentUser()

			localStorage.setItem('userId', user.id)

			_setCompany(user?.companies[0])

			if (user) {
				logged.style.display = 'block'

				return (window.location = '/')
			}
		})
		.catch(err => {
			// console.log(err, 'err login')
			throw err
		})
}

export async function extensionLogin(user) {
	return await Axios.post('/api/ext-login', user)
		.then(res => {
			const { token } = res.data

			return token
		})
		.then(token => {
			return token
		})
		.catch(err => {
			throw err
		})
}

export function getCurrentUser() {
	try {
		const jwt = localStorage.getItem('jwtToken')
		const user = jwtDecode(jwt)

		return user
	} catch (error) {}
}

export function logout() {
	try {
		localStorage.removeItem('jwtToken')
		localStorage.removeItem('userId')
		localStorage.removeItem('defaultCompany')

		return (window.location = '/')
	} catch (err) {}
}

export async function checkCurrentUser() {
	try {
		const { exp } = getCurrentUser()
		if (Date.now() >= exp * 1000) {
			console.log('logout')
			logout()
		}
	} catch (error) {
		console.log(error)
	}
}

export function getDefaultCompany() {
	let defaultCompany = null
	try {
		const storedCompany = localStorage.getItem('defaultCompany')
		if (storedCompany) {
			defaultCompany = JSON.parse(storedCompany)
		}
	} catch (err) {
		console.error('Error parsing defaultCompany from localStorage:', err)
	}

	return defaultCompany
}

export async function removeUser(dispatcherEmail) {
	try {
		const { _id } = getDefaultCompany()

		const response = await fetch(
			`api/user/dispatcher?companyId=${_id}&dispatcherEmail=${dispatcherEmail}`,
			{
				method: 'DELETE',
			}
		)

		return response.json()
	} catch (error) {
		console.log('error', error)
		return error
	}
}
