import React from 'react'

function ExtSelectCompany({
	companies,
	selectedCompany,
	handleCompanySelection,
	handleCompanySubmit,
}) {
	return (
		<div className="flex flex-col items-center justify-center p-6 space-y-4 bg-white rounded-md shadow">
			<label
				htmlFor="company-select"
				className="text-lg font-medium text-gray-700"
			>
				Select a Company
			</label>
			<select
				id="company-select"
				value={selectedCompany}
				onChange={handleCompanySelection}
				className="w-full p-2 text-gray-700 bg-gray-50 border border-gray-300 rounded-md focus:border-indigo-500 focus:ring-indigo-500"
			>
				{companies.map(company => (
					<option key={company?._id} value={company?._id}>
						{company?.name}
					</option>
				))}
			</select>
			<button
				onClick={handleCompanySubmit}
				className="w-full px-4 py-2 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
			>
				Select Company
			</button>
		</div>
	)
}

export default ExtSelectCompany
