import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
)

const mapChartData = (statusType, data) => {
	const res = data?.map(d => {
		const status = d.find(l => l.statusType === statusType)

		return (status?.duration / 60)?.toFixed(1)
	})

	return res
}

export default function DriverStats({ chartData }) {
	if (!chartData) return null

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: 'Work Hours',
			},
		},
	}

	const labels = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	]

	const data = {
		labels,
		datasets: [
			{
				label: 'Driving',
				data: mapChartData('DRIVING', chartData),
				backgroundColor: 'rgba(50, 186, 64, 0.5)',
				stack: 1,
			},
			{
				label: 'Rest',
				data: mapChartData('REST', chartData),
				backgroundColor: 'rgba(247, 227, 2, 0.5)',
				stack: 1,
			},
			{
				label: 'Home',
				data: mapChartData('HOME', chartData),
				backgroundColor: 'rgba(168, 50, 92, 0.5)',
				stack: 1,
			},
			{
				label: 'Ready',
				data: mapChartData('READY', chartData),
				backgroundColor: 'rgba(247, 15, 2, 0.5)',
				stack: 1,
			},
		],
	}

	return (
		<>
			<Bar options={options} data={data} />
		</>
	)
}
