import React from 'react'
import moment from 'moment-timezone'

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

import { useDate } from '../../context/dateContext'

const formatDate = date => {
	return date ? moment(date).format('MM/DD/YYYY') : ''
}

const DateRangeFilter = () => {
	const { startDate, endDate, addWeek, subtractWeek } = useDate()

	const newStartDate = formatDate(startDate)
	const newEndDate = formatDate(endDate)

	const dateRangeString = `${newStartDate} - ${newEndDate}`

	return (
		<div className="flex items-center justify-center bg-white rounded-lg shadow-md border-b border-t border-gray-200">
			<button
				onClick={subtractWeek}
				className="flex items-center justify-center p-2 border-r hover:bg-blue-100 focus:outline-none focus:bg-blue-200 transition duration-150 ease-in-out"
				aria-label="Previous"
			>
				<ChevronLeftIcon className="h-5 w-5 text-blue-600" />
			</button>

			<span className="px-3 py-1 text-sm font-medium text-gray-800 border-l border-r border-gray-200">
				{dateRangeString}
			</span>

			<button
				onClick={addWeek}
				className="flex items-center justify-center p-2 border-l hover:bg-blue-100 focus:outline-none focus:bg-blue-200 transition duration-150 ease-in-out"
				aria-label="Next"
			>
				<ChevronRightIcon className="h-5 w-5 text-blue-600" />
			</button>
		</div>
	)
}

export default DateRangeFilter
