import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { getCurrentUser } from '../../services/authService'

import Skeleton from '../../common/Skeleton'
import UserEditModal from './UserEditModal'

import Modal from '../../common/Modal'

export default function UserInfo({ user }) {
	const [company, setCompany] = useState({})
	const [loading, setLoading] = useState(false)

	const defaultCompany = JSON.parse(localStorage.getItem('defaultCompany'))
	const { isAdmin } = getCurrentUser()

	const getUserInfoOnUpdate = async () => {
		try {
			setLoading(true)
			const id = defaultCompany?._id

			const { data } = await axios.get(`/api/company/companyInfo/${id}`)

			setCompany(data)
			setLoading(false)
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		const getUserInfo = async () => {
			try {
				setLoading(true)
				const id = defaultCompany?._id

				const { data } = await axios.get(`/api/company/companyInfo/${id}`)

				setCompany(data)
				setLoading(false)
			} catch (error) {
				console.error(error)
			}
		}

		getUserInfo()
	}, [])

	const position = company?.users?.find(c => c?.user === user?._id)

	const [openModal, setOpenModal] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleCloseModal = () => {
		setIsModalOpen(false)
	}

	return (
		<>
			<section>
				{loading ? (
					<div className="w-full mx-auto h-full flex items-center justify-center">
						<Skeleton size="w-full " />
					</div>
				) : (
					<>
						<div className="bg-white shadow sm:rounded-lg">
							<div className="px-4 py-5 sm:px-6">
								<h1 className="text-lg leading-6 font-medium text-gray-900 mb-4">
									{company?.name}
								</h1>
								<h2 className="text-lg leading-6 font-medium text-gray-900 flex gap-2">
									{user?.company?.name}
								</h2>
								<p className="mt-1 max-w-2xl text-sm text-gray-500">
									{user?.name}
								</p>
							</div>
							<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
								<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											Position
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{position?.role}
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											Email address
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{user?.email}
										</dd>
									</div>

									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">USDOT</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{company?.usdot}
										</dd>
									</div>

									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											MC Number
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{company?.mc}
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											Address
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{company?.address?.street &&
											company?.address?.city &&
											company?.address?.state
												? `${company.address.street}, ${company.address.city}, ${company.address.state}`
												: 'N/A'}
										</dd>
									</div>
									<div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">
											Time Zone
										</dt>
										<dd className="mt-1 text-sm text-gray-900">
											{company?.timezone}
										</dd>
									</div>
								</dl>

								{isAdmin && (
									<button
										onClick={() => setOpenModal(true)}
										className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
									>
										Edit
									</button>
								)}
							</div>
						</div>
					</>
				)}
			</section>
			<Modal open={openModal} setOpen={setOpenModal} onClose={handleCloseModal}>
				<UserEditModal
					onClose={() => setOpenModal(false)}
					initialCompany={company}
					getUserInfoOnUpdate={getUserInfoOnUpdate}
				/>
			</Modal>
		</>
	)
}
