import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import axios from 'axios'

import { getUsers } from '../../services/httpService'
import { getDefaultCompany } from '../../services/authService'

import DriverCard from './DriverCard'
import DispatchBoardFilters from './DispatchBoardFilters'
import Skeleton from './Spinner'
import DriverSlideOver from './DriverSlideOver/DriverSlideOver'

const URL = ''

function DispatchBoardMain() {
	const [selectedFilter, setSelectedFilter] = useState('filter-0')
	const [drivers, setDrivers] = useState([])
	const [loading, setLoading] = useState(true)
	const [isOpen, setIsOpen] = useState(false)
	const [selectedDriver, setSelectedDriver] = useState(null)

	const [searchText, setSearchText] = useState('')
	const [filteredDrivers, setFilteredDrivers] = useState([])

	useEffect(() => {
		const socket = io(URL, { autoConnect: false })

		socket.connect()

		socket.on('connect', () => {
			console.log('Connected to the server')
			const { _id: companyId } = getDefaultCompany()
			socket.emit('joinCompany', companyId)
		})

		socket.on('driverChange', change => {
			if (change.operationType === 'update' && change.fullDocument) {
				setDrivers(prevDrivers => {
					return prevDrivers?.map(driver => {
						if (driver._id === change.fullDocument._id) {
							return change.fullDocument
						}
						return driver
					})
				})
			} else if (change.operationType === 'insert' && change.fullDocument) {
				setDrivers(prevDrivers => [...prevDrivers, change.fullDocument])
			} else if (change.operationType === 'delete') {
				setDrivers(prevDrivers =>
					prevDrivers.filter(driver => driver._id !== change.documentKey._id)
				)
			}
		})

		socket.on('disconnect', reason => {
			console.log(`Disconnected: ${reason}`)
		})

		return () => {
			socket.disconnect()
		}
	}, [])

	useEffect(() => {
		setFilteredDrivers(
			drivers?.filter(
				driver =>
					driver?.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
					driver?.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
					driver?.truck?.number?.toLowerCase()?.includes(searchText) ||
					driver?.teamDriver?.firstName
						?.toLowerCase()
						?.includes(searchText.toLowerCase()) ||
					driver?.teamDriver?.lastName
						?.toLowerCase()
						?.includes(searchText?.toLowerCase())
			)
		)
	}, [searchText, drivers])

	useEffect(() => {
		const source = axios.CancelToken.source()

		const fetchData = async () => {
			setLoading(true)
			const res = await getUsers(source.token)

			if (res !== 'err' && res !== 'CanceledError') setDrivers(res?.data)

			if (res !== 'CanceledError') setLoading(false)
		}

		fetchData()

		return () => source.cancel('Previous request canceled')
	}, [selectedFilter])

	const handleSearchChange = event => {
		setSearchText(event.target.value)
	}

	const filterDriversByStatus = () => {
		switch (selectedFilter) {
			case 'filter-1':
				return filteredDrivers?.filter(driver => driver.status === 'READY')
			// case 'filter-2':
			// 	return filteredDrivers?.filter(driver => driver.status === 'Late')
			case 'filter-2':
				return filteredDrivers?.filter(driver => driver.status === 'DRIVING')
			case 'filter-3':
				return filteredDrivers?.filter(
					driver =>
						!(
							driver.status === 'READY' ||
							driver.status === 'Late' ||
							driver.status === 'DRIVING'
						)
				)
			default:
				return filteredDrivers
		}
	}

	const getStatusCounts = () => {
		return {
			total: drivers?.length,
			ready: drivers?.filter(driver => driver?.status === 'READY')?.length,
			late: drivers?.filter(driver => driver?.status === 'Late')?.length,
			driving: drivers?.filter(driver => driver?.status === 'DRIVING')?.length,
			others: drivers?.filter(
				driver =>
					!(
						driver.status === 'READY' ||
						driver.status === 'Late' ||
						driver.status === 'DRIVING'
					)
			)?.length,
		}
	}

	const statusCounts = getStatusCounts()
	const displayedDrivers = filterDriversByStatus()

	return (
		<div className="container mx-auto">
			<DispatchBoardFilters
				setSelectedFilter={setSelectedFilter}
				selectedFilter={selectedFilter}
				statusCounts={statusCounts}
				handleSearchChange={handleSearchChange}
			/>

			<div className="mb-10 flex flex-col items-center w-full">
				{loading ? (
					<div className="mt-28">
						<Skeleton />
					</div>
				) : displayedDrivers && displayedDrivers.length > 0 ? (
					displayedDrivers?.map(driver => (
						<DriverCard
							key={driver._id}
							driver={driver}
							onSelectDriver={() => {
								setSelectedDriver(driver)
								setIsOpen(true)
							}}
						/>
					))
				) : (
					<p className="mt-10 text-2xl text-center mt-5">
						No drivers available for the selected filter.
					</p>
				)}
			</div>

			<DriverSlideOver
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false)
					setSelectedDriver(null)
				}}
				selectedDriver={selectedDriver}
			/>
		</div>
	)
}

export default DispatchBoardMain
