import React from 'react'

import { Routes, Route, useLocation } from 'react-router-dom'

import { getCurrentUser, checkCurrentUser } from './services/authService'

import Navbar from './components/NavigationBar/index'
import Login from './components/Registration/Login'
import Signup from './components/Registration/Signup'
import LandingPage from './components/LandingPage/index'
import DispatchBoard from './components/DispatchBoardNew/DispatchBoard'

// import Drivers from './components/Drivers/Drivers'
import Drivers from './components/DriversV2/Drivers'
import Dashboard from './components/Dashboard/Dashboard'
import DriverStats from './components/Dashboard/DriverStats/DriverStats'
// import Loads from './components/Loads/Loads'
import Loads from './components/LoadsNew/LoadsMain'
import Statements from './components/Statements/Statements'
import Users from './components/Users'
import Settings from './components/Settings'
import InviteSignup from './components/Registration/InviteSignup'

import InviteUser from './components/Registration/InviteUser'
import Integrations from './components/Integrations/Integrations'
import ExtensionAuth from './components/Registration/Extention/ExtensionAuth'

import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'

function AppRoutes() {
	const user = getCurrentUser()
	const location = useLocation()

	async function checkUser() {
		await checkCurrentUser()
	}

	const shouldShowNavbar = user && !location.pathname.includes('extension-auth')

	if (user) {
		checkUser()
		return (
			<div className="flex flex-col">
				{shouldShowNavbar && <Navbar />}

				<div className="px-12">
					<Routes>
						<Route path="extension-auth" element={<ExtensionAuth />} />
						<Route path="dispatch-board" element={<DispatchBoard />} />
						<Route path="dashboard/*" element={<Dashboard />}>
							<Route path="driverStats/:driverId" element={<DriverStats />} />
						</Route>
						<Route path="drivers" element={<Drivers />} />
						<Route path="statements" element={<Statements />} />
						<Route path="loads" element={<Loads />} />
						<Route path="users" element={<Users />} />
						<Route path="settings" element={<Settings />} />
						<Route path="integrations" element={<Integrations />} />
						<Route path="confirm/:token" element={<InviteUser />} />
						<Route path="policy" element={<PrivacyPolicy />} />
						<Route path="*" element={<Dashboard />} />
					</Routes>
				</div>
			</div>
		)
	}

	return (
		<Routes>
			<Route path="/" element={<LandingPage />} />
			<Route path="login" element={<Login />} />
			<Route path="sign-up" element={<Signup />} />
			<Route path="confirm/:token" element={<InviteSignup />} />
			<Route path="extension-auth" element={<ExtensionAuth />} />
			<Route path="policy" element={<PrivacyPolicy />} />
			<Route path="*" element={<LandingPage />} />
		</Routes>
	)
}

export default AppRoutes
