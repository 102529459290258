import moment from 'moment'
import ReactDOMServer from 'react-dom/server'
import jsPDF from 'jspdf'

import { getSingleStatement } from '../../../services/httpService'
import { getDefaultCompany } from '../../../services/authService'

function minusPercent(n, p) {
	return (p * n) / 100
}

export default async function downloadDoc(statementId) {
	const fetchedStatement = await getSingleStatement(statementId)

	const company = getDefaultCompany()

	const totalAmount = fetchedStatement?.totalAmount
	let totalAdjustmentAmount = 0
	let totalNetProfit = 0

	fetchedStatement?.adjustments?.forEach(adj => {
		if (adj?.type === 'deduction') {
			totalAdjustmentAmount += parseFloat(adj?.amount)
		}

		if (adj?.type === 'percentage') {
			totalAdjustmentAmount += minusPercent(
				fetchedStatement?.totalAmount,
				parseFloat(adj?.amount)
			)
		}
	})

	totalNetProfit = totalAmount - totalAdjustmentAmount

	const driver =
		fetchedStatement?.driver?.firstName +
		' ' +
		fetchedStatement?.driver?.lastName

	const startDate = fetchedStatement?.startDate
	const endDate = fetchedStatement?.endDate

	const doc = new jsPDF()

	const weekNumber = moment(startDate).week()
	const start = moment(startDate)?.format('MMM Do')
	const end = moment(endDate)?.format('MMM Do')

	doc.setFont('times', 'normal', 700)
	doc.setTextColor('#383838')
	doc.setFontSize(20)
	doc.text(15, 15, company?.name)

	// doc.setFontSize(10)
	// doc.text(15, 22, '411 S Johnson ave # S')
	// doc.text(15, 28, 'El Cajon, CA 92020')

	doc.setFontSize(20)
	doc.setFillColor('#6495ED')
	doc.rect(15, 42, 180, 9, 'F')
	doc.setTextColor('White')
	doc.setFont('times', 'normal', 700)
	doc.text(17, 49, 'Driver Pay Settlement')

	doc.setFontSize(14)
	doc.setTextColor('#383838')
	doc.text(15, 60, 'Name: ' + driver)

	doc.text(
		115,
		60,
		'Period: ' +
			moment(startDate).format('MMM Do') +
			' - ' +
			moment(endDate).format('MMM Do, YYYY')
	)

	doc.setFontSize(20)
	doc.text(85, 85, 'Summary')

	doc.setFontSize(14)
	doc.text(15, 105, 'Gross Revunue: ' + '$' + totalAmount?.toFixed(2))

	doc.text(15, 115, 'Adjustments: ' + '$' + totalAdjustmentAmount?.toFixed(2))

	doc.text(15, 125, 'Net Amount: ' + '$' + totalNetProfit?.toFixed(2))

	// Table header
	doc.setFillColor('#585858')
	doc.setTextColor('#F0F0F0')
	doc.rect(15, 134, 180, 9, 'F')

	doc.text(17, 140, 'Trip ID')
	doc.text(45, 140, 'Date')
	doc.text(65, 140, 'From')
	doc.text(105, 140, 'To')
	doc.text(145, 140, 'Distance')
	doc.text(167, 140, 'Pay Amount')

	doc.setTextColor('#383838')
	doc.setFontSize(10)
	let cellX = 148
	let i = 0
	let pageHeight = doc.internal.pageSize.height

	for (const trip of fetchedStatement?.loads) {
		const pickupDate = new Date(trip.pickupDate)
		const formattedDate = moment(pickupDate).format('MM-DD-YY')

		if (i % 2 !== 0) {
			doc.setFillColor('#E8E8E8')
			doc.rect(15, cellX - 4, 180, 6, 'F')
		}
		doc.text(17, cellX, trip.loadId)
		doc.text(45, cellX, formattedDate)
		doc.text(65, cellX, trip.originCity + ', ' + trip.originState)
		doc.text(105, cellX, trip.deliveryCity + ', ' + trip.deliveryState)
		doc.text(150, cellX, `${trip?.distance} mi`)
		doc.text(176, cellX, `$${trip?.price}`)

		if (pageHeight - 20 <= cellX) {
			doc.addPage()
			cellX = 10
		}
		cellX = cellX + 7
		i++
	}

	// Adjustments
	doc.setFontSize(20)
	doc.text(15, cellX + 10, 'Adjustments')

	doc.setFontSize(12)
	cellX = cellX + 20

	if (fetchedStatement?.adjustments && fetchedStatement?.adjustments.length) {
		for (const adjsmnt of fetchedStatement?.adjustments) {
			let description = adjsmnt?.description
			let amount = adjsmnt?.amount

			if (adjsmnt?.type === 'percentage') {
				amount = minusPercent(parseInt(adjsmnt?.amount), totalAmount)?.toFixed(
					2
				)
				description = description + ` ${adjsmnt?.amount}%`
			}

			let pointY = 65
			if (description?.length > 20) pointY = pointY + description?.length / 2.5

			doc.text(17, cellX, description)
			doc.text(pointY, cellX, `$${amount}`)

			pageHeight = doc.internal.pageSize.height

			if (pageHeight - 20 <= cellX) {
				doc.addPage()
				cellX = 10
			}
			cellX = cellX + 7
		}
	}

	const pdfDoc = <PDFGenerator />

	doc.html(ReactDOMServer.renderToStaticMarkup(pdfDoc), {
		callback: () => {
			doc.save(`wk${weekNumber} ${driver} ${start}-${end}.pdf`)
		},
	})
}

function PDFGenerator() {
	return <div></div>
}
