import React, { useState } from 'react'
// import { DateRangePicker } from 'react-dates'
import moment from 'moment-timezone'

import { AiFillLeftSquare, AiFillRightSquare } from 'react-icons/ai'

// import 'react-dates/initialize'
// import 'react-dates/lib/css/_datepicker.css'
import './DriverStatsDateRange.css'

const DriverStatsDateRange = ({ filters, setFilters }) => {
	const [startDate, setStartDate] = useState(filters?.startDate)
	const [endDate, setEndDate] = useState(filters?.endDate)
	const [focusedInput, setFocusedInput] = useState(null)

	function onDateChange(dates) {
		let { endDate, startDate } = dates

		setStartDate(startDate)
		setEndDate(endDate)

		setFilters({
			...filters,
			startDate,
			endDate,
		})
	}

	function onFocusChange(focusedInput) {
		setFocusedInput(focusedInput)
	}

	const addDateHandler = () => {
		if (startDate === null || endDate === null) return

		const startDateM = moment(filters?.startDate).add(7, 'days')
		const endDateM = moment(filters?.endDate).add(7, 'days')

		setStartDate(startDateM)
		setEndDate(endDateM)

		setFilters({
			...filters,
			startDate: startDateM,
			endDate: endDateM,
		})
	}

	const substractDateHandler = () => {
		if (startDate === null || endDate === null) return

		const startDateM = moment(filters?.startDate).subtract(7, 'days')
		const endDateM = moment(filters?.endDate).subtract(7, 'days')

		setEndDate(startDateM)
		setStartDate(endDateM)

		setFilters({
			...filters,
			startDate: startDateM,
			endDate: endDateM,
		})
	}

	return (
		<div className="inline flex flex-row items-center">
			<AiFillLeftSquare
				className="rounded-l-full"
				onClick={substractDateHandler}
				style={{ marginRight: '-7px' }}
				cursor="pointer"
				color="#475569"
				size="50"
				border-radius="80%"
			/>
			{/* <DateRangePicker
				style={{ innerHeight: '20px' }}
				startDate={filters?.startDate}
				endDate={filters?.endDate}
				startDateId="your_unique_start_date_id"
				endDateId="your_unique_end_date_id"
				onDatesChange={onDateChange}
				focusedInput={focusedInput}
				onFocusChange={onFocusChange}
				isOutsideRange={() => false}
				showClearDates={true}
				showDefaultInputIcon={true}
			/> */}
			<AiFillRightSquare
				className="rounded-r-full"
				onClick={addDateHandler}
				style={{ marginLeft: '-7px' }}
				cursor="pointer"
				color="#475569"
				size="50"
			/>
		</div>
	)
}

export default DriverStatsDateRange
