// import React, { useState, useEffect } from 'react'
// import { useParams, useNavigate } from 'react-router-dom'
// import axios from 'axios'

// import { acceptInvite, getCurrentUser } from '../../services/authService'

// export default function Index() {
// 	const params = useParams()
// 	const { token } = params

// 	const navigate = useNavigate()

// 	const currentUser = getCurrentUser()

// 	const [redirctTo, setRedirctTo] = useState(false)
// 	const [error, setError] = useState(null)
// 	const [state, setState] = useState({
// 		name: '',
// 		companyName: '',
// 		companyId: '',
// 		token: token,
// 	})

// 	const modal = document.getElementById('modalForSignupError')

// 	const showError = () => {
// 		modal.style.display = 'block'
// 		return setTimeout(() => {
// 			modal.style.display = 'none'
// 		}, 5000)
// 	}

// 	const waitFunc = async () => {
// 		return await acceptInvite(state)
// 	}

// 	const submitHandler = async e => {
// 		e.preventDefault()

// 		waitFunc()
// 	}

// 	useEffect(() => {
// 		const ac = new AbortController()

// 		async function checkInvitation() {
// 			const res = await axios.get(`/api/invites/accept/${token}`, {
// 				signal: ac.signal,
// 			})

// 			if (res?.status === 200 && res?.data?.success) {
// 				const { email, companyId, company } = res.data

// 				if (email.trim() !== currentUser?.email.trim()) {
// 					showError()
// 					setRedirctTo(true)
// 				}

// 				setState({
// 					...state,
// 					email,
// 					companyName: company,
// 					companyId,
// 				})
// 			} else {
// 				setRedirctTo(true)
// 				console.log(res)
// 				setError(res?.data?.message)
// 				showError()
// 			}
// 		}

// 		checkInvitation()
// 		return () => ac.abort()
// 	}, [redirctTo])

// 	return (
// 		<>
// 			{redirctTo ? (
// 				navigate('/dashboard')
// 			) : (
// 				<div className="max-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
// 					<div className="sm:mx-auto sm:w-full sm:max-w-md">
// 						<h2 className="mt-1 text-center text-3xl font-extrabold text-gray-900">
// 							Accept Invitation
// 						</h2>
// 					</div>
// 					<div
// 						className="text-center text-red text-danger"
// 						id="modalForSignupError"
// 						style={{ display: 'none' }}
// 					>
// 						{error}
// 					</div>
// 					<div id="successfullyCreatedModal" style={{ display: 'none' }}>
// 						You Accepted Invitation!
// 					</div>
// 					<div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
// 						<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
// 							<form className="space-y-6" onSubmit={submitHandler}>
// 								<div>
// 									<label
// 										htmlFor="companyName"
// 										className="block text-sm font-medium text-gray-500"
// 									>
// 										Company Name
// 									</label>
// 									<span className="font-semibold text-lg appearance-none block w-full px-1 py-2 shadow-sm sm:text-sm">
// 										{state?.companyName}
// 									</span>
// 								</div>
// 								<div className="-mt-5">
// 									<label
// 										htmlFor="email"
// 										className="block text-sm font-medium text-gray-500"
// 									>
// 										Your Email
// 									</label>

// 									<span className="font-semibold text-lg appearance-none block w-full px-1 py-2 shadow-sm sm:text-sm">
// 										{state?.email}
// 									</span>
// 								</div>

// 								<div>
// 									<button
// 										type="submit"
// 										className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
// 									>
// 										Accept
// 									</button>
// 								</div>
// 							</form>
// 						</div>
// 					</div>
// 				</div>
// 			)}
// 		</>
// 	)
// }

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { acceptInvite, getCurrentUser } from '../../services/authService';

export default function Index() {
  const params = useParams();
  const { token } = params;

  const navigate = useNavigate();

  const currentUser = getCurrentUser();

  const [redirectTo, setRedirectTo] = useState(false);
  const [error, setError] = useState(null);
  const [state, setState] = useState({
    name: '',
    companyName: '',
    companyId: '',
    token: token,
  });

  const modal = document.getElementById('modalForSignupError');

  const showError = () => {
    modal.style.display = 'block';
    return setTimeout(() => {
      modal.style.display = 'none';
    }, 5000);
  };

  const waitFunc = async () => {
    return await acceptInvite(state);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    waitFunc();
  };

  useEffect(() => {
    const ac = new AbortController();

    async function checkInvitation() {
      const res = await axios.get(`/api/invites/accept/${token}`, {
        signal: ac.signal,
      });

      if (res?.status === 200 && res?.data?.success) {
        const { email, companyId, company } = res.data;

        if (email.trim() !== currentUser?.email.trim()) {
          showError();
          setRedirectTo(true);
        }

        setState({
          ...state,
          email,
          companyName: company,
          companyId,
        });
      } else {
        setRedirectTo(true);
        console.log(res);
        setError(res?.data?.message);
        showError();
      }
    }

    checkInvitation();
    return () => ac.abort();
  }, [redirectTo]);

  useEffect(() => {
    if (redirectTo) {
      navigate('/dashboard');
    }
  }, [redirectTo, navigate]);

  return (
    <>
      {!redirectTo ? (
        <div className='max-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <div className='sm:mx-auto sm:w-full sm:max-w-md'>
            <h2 className='mt-1 text-center text-3xl font-extrabold text-gray-900'>
              Accept Invitation
            </h2>
          </div>
          <div
            className='text-center text-red text-danger'
            id='modalForSignupError'
            style={{ display: 'none' }}
          >
            {error}
          </div>
          <div id='successfullyCreatedModal' style={{ display: 'none' }}>
            You Accepted Invitation!
          </div>
          <div className='mt-4 sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
              <form className='space-y-6' onSubmit={submitHandler}>
                <div>
                  <label
                    htmlFor='companyName'
                    className='block text-sm font-medium text-gray-500'
                  >
                    Company Name
                  </label>
                  <span className='font-semibold text-lg appearance-none block w-full px-1 py-2 shadow-sm sm:text-sm'>
                    {state?.companyName}
                  </span>
                </div>
                <div className='-mt-5'>
                  <label
                    htmlFor='email'
                    className='block text-sm font-medium text-gray-500'
                  >
                    Your Email
                  </label>

                  <span className='font-semibold text-lg appearance-none block w-full px-1 py-2 shadow-sm sm:text-sm'>
                    {state?.email}
                  </span>
                </div>

                <div>
                  <button
                    type='submit'
                    className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    Accept
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
