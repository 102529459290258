export function debounce(func, wait, immediate) {
	let timeout
	return function () {
		let context = this,
			args = arguments
		let later = function () {
			timeout = null
			if (!immediate) func.apply(context, args)
		}
		let callNow = immediate && !timeout
		clearTimeout(timeout)
		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}

export const cancelApiCalls = sources => {
	sources.map((source, index) => {
		if (1 + index !== sources.length) source.cancel('Previous request canceled')
	})
}

export const debounceV2 = (callback, delay) => {
	let timer

	return (...args) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			callback(...args)
		}, delay)
	}
}

export const numberWithCommas = (number, item) => {
	const result = number?.toFixed(item?.objName !== 'totalMiles' ? 2 : 0)

	return result?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const states = [
	['Alabama', 'AL'],
	['Alaska', 'AK'],
	['American Samoa', 'AS'],
	['Arizona', 'AZ'],
	['Arkansas', 'AR'],
	['Armed Forces Americas', 'AA'],
	['Armed Forces Europe', 'AE'],
	['Armed Forces Pacific', 'AP'],
	['California', 'CA'],
	['Colorado', 'CO'],
	['Connecticut', 'CT'],
	['Delaware', 'DE'],
	['District Of Columbia', 'DC'],
	['Florida', 'FL'],
	['Georgia', 'GA'],
	['Guam', 'GU'],
	['Hawaii', 'HI'],
	['Idaho', 'ID'],
	['Illinois', 'IL'],
	['Indiana', 'IN'],
	['Iowa', 'IA'],
	['Kansas', 'KS'],
	['Kentucky', 'KY'],
	['Louisiana', 'LA'],
	['Maine', 'ME'],
	['Marshall Islands', 'MH'],
	['Maryland', 'MD'],
	['Massachusetts', 'MA'],
	['Michigan', 'MI'],
	['Minnesota', 'MN'],
	['Mississippi', 'MS'],
	['Missouri', 'MO'],
	['Montana', 'MT'],
	['Nebraska', 'NE'],
	['Nevada', 'NV'],
	['New Hampshire', 'NH'],
	['New Jersey', 'NJ'],
	['New Mexico', 'NM'],
	['New York', 'NY'],
	['North Carolina', 'NC'],
	['North Dakota', 'ND'],
	['Northern Mariana Islands', 'NP'],
	['Ohio', 'OH'],
	['Oklahoma', 'OK'],
	['Oregon', 'OR'],
	['Pennsylvania', 'PA'],
	['Puerto Rico', 'PR'],
	['Rhode Island', 'RI'],
	['South Carolina', 'SC'],
	['South Dakota', 'SD'],
	['Tennessee', 'TN'],
	['Texas', 'TX'],
	['US Virgin Islands', 'VI'],
	['Utah', 'UT'],
	['Vermont', 'VT'],
	['Virginia', 'VA'],
	['Washington', 'WA'],
	['West Virginia', 'WV'],
	['Wisconsin', 'WI'],
	['Wyoming', 'WY'],
]

export function convertState(state) {
	const selectedState = states.find((s, index) => {
		return s.find(x => x.toLowerCase() === state.toLowerCase())
	})
	if (!selectedState) return null

	return selectedState[1]
}

export const capitalCityName = city => {
	if (!city) return city

	return city
		?.toLowerCase()
		?.split(' ')
		?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
		?.join(' ')
}

export const truncateString = (str, num) => {
	if (str.length <= num) {
		return str
	}
	return str.slice(0, num) + '...'
}
