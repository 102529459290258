import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import { getDrivers } from '../../../services/httpService'

const SelectDriver = ({ drivers, props, onChange, loadId }) => {
	const [options, setOptions] = useState([])
	const [selected, setSelected] = useState(null) // Initialize as null

	useEffect(() => {
		const optionsList = drivers?.length
			? drivers.map(d => ({
					value: d._id,
					label: `${d.firstName} ${d.lastName}`,
			  }))
			: []

		setOptions(optionsList)
	}, [drivers])

	const getOptionsAsync = newInput => {
		getDrivers({ input: newInput })
			.then(results => {
				const options = results.map(d => ({
					value: d._id,
					label: `${d.firstName} ${d.lastName}`,
				}))

				setOptions(options ? options : [])
			})
			.catch(error => {
				console.error(error)
			})
	}

	const handleChange = async (newValue, acMeta) => {
		// const newDriverId =
		// 	acMeta.action === 'select-option' ? newValue?.value : null
		// Call the callback function provided by the parent component
		console.log('newValue', newValue)
		onChange(newValue?.value, newValue?.label)

		// // Update the load driver id as before
		// const oldDriverId = props?.row?.original?.drivers[0]?._id
		// const updatedLoadId = props?.row?.original?._id
	}

	return (
		<div className="">
			<Select
				key={loadId}
				placeholder="Select driver..."
				cacheOptions
				defaultOptions
				isSearchable
				value={selected}
				options={options}
				onChange={handleChange}
				onFocus={getOptionsAsync}
				components={{
					IndicatorSeparator: () => null,
				}}
				styles={{
					menu: provided => ({ ...provided, zIndex: 9999 }),
					control: provided => ({
						...provided,
						minHeight: '30px',
						height: '30px',
					}),
					valueContainer: provided => ({
						...provided,
						height: '30px',
						padding: '0 6px',
					}),
					input: provided => ({ ...provided, margin: '0px' }),
					singleValue: provided => ({
						...provided,
						fontSize: '10px',
						marginBottom: '8px',
					}),
					option: (provided, state) => ({
						...provided,
						fontSize: '0.65rem',
						padding: '8px 12px',
						backgroundColor: state.isSelected ? 'blue' : 'white',
						color: state.isSelected ? 'white' : 'black',
						':active': {
							...provided[':active'],
							backgroundColor:
								!state.isDisabled && (state.isSelected ? 'blue' : 'lightgray'),
						},
					}),
				}}
			/>
		</div>
	)
}

export default SelectDriver
