import React from 'react'

import Modal from '../../common/Modal'
import ManualLoad from '../NewLoadModal/LoadForm/LoadForm'

function EditModal({ load, open, setOpen }) {
	return (
		<Modal open={open} setOpen={setOpen}>
			<ManualLoad load={load} setOpen={setOpen} />
		</Modal>
	)
}

export default EditModal
