import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import toast from 'react-hot-toast'

import { getTrucks, updateDriverTruck } from '../../services/httpService'

const SelectTruck = ({ selectedDriver, driverId, props }) => {
	const [options, setOptions] = useState([])
	const [selected, setSelected] = useState(null)

	const truckNumber = selectedDriver?.truck?.number

	useEffect(() => {
		const initialSelector = () => {
			const optionsList = truckNumber
				? [
						{
							value: truckNumber,
							label: `${truckNumber}`,
						},
				  ]
				: [{ label: '', value: '' }]

			setOptions(optionsList)
			setSelected(optionsList[0])
		}

		initialSelector()
	}, [truckNumber])

	const getOptionsAsync = newInput => {
		getTrucks({ input: newInput })
			.then(results => {
				const options = results?.map(d => ({
					value: d?.truck_number,
					label: `${d?.truck_number}`,
				}))

				setOptions(options ? options : [])
			})
			.catch(error => {
				console.error(error)
			})
	}

	const handleChange = async (newValue, acMeta) => {
		try {
			const newTruckId =
				acMeta.action === 'select-option' ? newValue?.value : null

			const oldTruckId = props?.row?.original?.drivers[0]?._id
			const truckId = props?.row?.original?._id

			await updateDriverTruck(driverId, truckId, newTruckId, oldTruckId)
			toast.success(`Truck updated`)

			setSelected(newValue)
		} catch (error) {
			console.log(error)
			toast.error(`Error updating truck`)
		}
	}

	return (
		<div className="w-36">
			<Select
				key={driverId}
				cacheOptions
				defaultOptions
				isSearchable
				defaultValue={selected}
				value={selected}
				options={options}
				onChange={handleChange}
				onFocus={getOptionsAsync}
				components={{
					IndicatorSeparator: () => null,
				}}
				styles={{
					control: provided => ({
						...provided,
						minHeight: '30px',
						height: '30px',
					}),
					valueContainer: provided => ({
						...provided,
						height: '30px',
						padding: '0 6px',
					}),
					input: provided => ({
						...provided,
						margin: '0px',
					}),
					indicatorsContainer: provided => ({
						...provided,
						height: '30px',
					}),
					singleValue: provided => ({
						...provided,
						fontSize: '14px',
						marginBottom: '1px',
					}),
					option: (provided, state) => ({
						...provided,
						fontSize: '0.65rem', // Set font size to 14px
						padding: '8px 12px', // Set smaller padding
						backgroundColor: state.isSelected ? 'blue' : 'white',
						color: state.isSelected ? 'white' : 'black',
						':active': {
							...provided[':active'],
							backgroundColor:
								!state.isDisabled && (state.isSelected ? 'blue' : 'lightgray'),
						},
					}),
				}}
			/>
		</div>
	)
}

export default SelectTruck
