import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import toast from 'react-hot-toast'
import { utcToZonedTime, formatInTimeZone } from 'date-fns-tz'

import {
	updateArrived,
	deleteLoadService,
} from '../../../../services/loadServices'

import DeleteModal from './DeleteModal'
import { capitalCityName } from '../../../../utils/utils'

function LoadCollapseMain({ load, onRemoveLoad, onEditLoad }) {
	const [updatedLoad, setUpdatedLoad] = useState(load)
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

	useEffect(() => {
		setUpdatedLoad(load)
	}, [load])

	const markAsArrived = async stopIndex => {
		try {
			const updated = await updateArrived(load._id, stopIndex)

			if (updated) {
				setUpdatedLoad(currentLoad => {
					const newLoad = {
						...currentLoad,
						stops: currentLoad.stops.map((stop, index) =>
							index === stopIndex ? { ...stop, status: 'ARRIVED' } : stop
						),
					}
					return newLoad
				})
				toast.success('Stop status updated')
			} else {
				toast.error('Failed to update stop status')
				throw new Error('No update was returned from the server.')
			}
		} catch (error) {
			console.error('Failed to update stop status:', error)
			toast.error('Failed to update stop status')

			setUpdatedLoad(currentLoad => ({
				...currentLoad,
				stops: currentLoad.stops.map((stop, index) =>
					index === stopIndex ? { ...stop, status: 'PLANNED' } : stop
				),
			}))
		}
	}

	const deleteLoad = async () => {
		try {
			await deleteLoadService(load._id)
			onRemoveLoad(load._id)
			setIsDeleteModalOpen(false)
			toast.success('Load successfully deleted.')
		} catch (error) {
			console.error('Failed to delete load:', error)
			toast.error('Failed to delete load')
		}
	}

	return (
		<div className="w-full border rounded-sm mt-0 p-2">
			<div className="grid grid-cols-12 gap-1 mb-2">
				<div className="col-span-4 font-bold">Address</div>
				<div className="col-span-4 font-bold">Date</div>
				<div className="col-span-4 font-bold">Notes</div>
			</div>

			{updatedLoad?.stops?.map((stop, index) => {
				const address = stop?.address
				const cityStateZip = `${stop?.city} ${stop?.state} ${stop?.zipcode}`
				let date
				let actualDate

				if (stop?.date) {
					const format = 'yyyy-MM-dd HH:mm:ss zzz'

					const timezone = stop?.timezone
					const originUTC = utcToZonedTime(new Date(stop?.date), timezone)

					date = formatInTimeZone(originUTC, timezone, format)

					if (stop?.actualDate) {
						const actualUTC = utcToZonedTime(
							new Date(stop?.actualDate),
							timezone
						)
						actualDate = formatInTimeZone(actualUTC, timezone, format)
					}
				}

				const statuses = ['PLANNED', 'ARRIVED', 'COMPLETED']
				const status = !statuses.includes(stop?.status?.toUpperCase())
					? stop?.status
					: null

				return (
					<div key={index}>
						<div className="mb-2 grid grid-cols-12 gap-1">
							<div className="col-span-4 flex items-center space-x-2">
								<div>
									<p className="text-xs font-semibold">{stop?.company}</p>
									<p className="text-xs">{address}</p>
									<p className="text-xs">{cityStateZip}</p>
								</div>
								{stop?.status === 'PLANNED' && (
									<button
										onClick={() => markAsArrived(index)}
										type="button"
										className="rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100"
									>
										Mark as Arrived
									</button>
								)}

								{status && (
									<span className="text-sm text-red-500">
										{capitalCityName(status)}
									</span>
								)}
							</div>
							<div className="text-xs col-span-4">
								<p>Planned: {date}</p>
								<p>Actual: {actualDate}</p>
							</div>

							<div className="text-xs col-span-4">{stop?.notes}</div>
						</div>

						<hr className="my-2" />
					</div>
				)
			})}

			<footer className="flex justify-end p-3 bg-white text-base-content mt-1">
				<button
					onClick={() => onEditLoad(updatedLoad)}
					type="button"
					className="mr-2 rounded bg-indigo-50 px-2 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
				>
					Edit
				</button>
				<button
					onClick={() => setIsDeleteModalOpen(true)}
					type="button"
					className="rounded bg-red-50 px-2 py-1 text-xs font-semibold text-red-600 shadow-sm hover:bg-red-100"
				>
					Delete
				</button>
			</footer>

			<DeleteModal
				isDeleteModalOpen={isDeleteModalOpen}
				setIsDeleteModalOpen={setIsDeleteModalOpen}
				deleteLoad={deleteLoad}
			/>
		</div>
	)
}

export default LoadCollapseMain
