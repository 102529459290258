import React from 'react'

export default function Skeleton({ size, height }) {
	return (
		<div
			className={`flex flex-col relative w-8/12 bg-white overflow-hidden card translate-3d-none-after relative bg-white overflow-hidden card translate-3d-none-after rounded border border-gray-300 ${size}`}
			style={{ width: size && size, height: height && height }}
		>
			<div
				className="relative group text-primary-500"
				style={{ paddingTop: '20%' }}
			>
				<div className="absolute top-0 left-0 h-full w-full">
					<span className="skeleton-box group-hover:scale-110 transition-transform transform-center block h-full"></span>
				</div>
			</div>
			<div className="flex flex-col flex-grow">
				<div className="pl-4 pr-4 pt-4 mb-4 text-left relative flex-grow">
					<h3 className="text-lg font-bold text-gray-darkest mr-10">
						<span className="skeleton-box h-5 w-1/6 inline-block"></span>
						<span className="skeleton-box h-5 w-1/2 inline-block"></span>
						<span className="skeleton-box h-5 w-2/4 inline-block"></span>
						<span className="skeleton-box h-5 w-2/5 inline-block"></span>
					</h3>
				</div>
			</div>
		</div>
	)
}
