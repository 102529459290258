import React, { useEffect, useState } from 'react'

import EditorModal from './EditorModal'
import StatementEditorContent from './StatementEditorContent'

import { getSingleStatement } from '../../../services/httpService'

function minusPercent(n, p) {
	return (p * n) / 100
}

function StatementEditor(props) {
	const {
		selectedStatement,
		setSelectedStatement,
		editorModalOpen,
		setEditorModalOpen,
		setShowInput,
		showInput,
	} = props

	const [fetchedStatement, setFetchedStatement] = useState(null)
	const [loading, setLoading] = useState(false)

	const [adjustmentAmount, setAdjustmentAmount] = useState(0)
	const [netAmount, setNetAmount] = useState(0)

	const fetchStatement = async () => {
		setLoading(true)
		const result = await getSingleStatement(selectedStatement)

		let adjNum = 0

		result?.adjustments?.forEach(adj => {
			if (adj?.type === 'deduction') {
				adjNum += parseFloat(adj?.amount)
			}

			if (adj?.type === 'percentage') {
				adjNum += minusPercent(result?.totalAmount, parseFloat(adj?.amount))
			}
		})

		setAdjustmentAmount(adjNum)
		setFetchedStatement(result)
		setNetAmount(result?.totalAmount - adjNum)
		setLoading(false)
	}

	useEffect(() => {
		if (selectedStatement) fetchStatement()
	}, [selectedStatement])

	return (
		<div className="m-1">
			<EditorModal
				id="feedback-modal"
				modalOpen={editorModalOpen}
				setModalOpen={setEditorModalOpen}
				setShowInput={setShowInput}
				showInput={showInput}
				setFetchedStatement={setFetchedStatement}
				setAdjustmentAmount={setAdjustmentAmount}
				setNetAmount={setNetAmount}
				setSelectedStatement={setSelectedStatement}
			>
				{/* Modal content */}
				<StatementEditorContent
					fetchedStatement={fetchedStatement}
					setShowInput={setShowInput}
					showInput={showInput}
					fetchStatement={fetchStatement}
					adjustmentAmount={adjustmentAmount}
					netAmount={netAmount}
					minusPercent={minusPercent}
					loading={loading}
				/>

				{/* Modal footer */}
				<div className="px-3 py-3 border-t border-gray-200">
					<div className="flex flex-wrap justify-end space-x-2">
						<button
							className="font-semibold btn-sm border-gray-200 hover:border-gray-900 text-gray-500"
							onClick={e => {
								e.stopPropagation()
								setEditorModalOpen(false)
								setShowInput(false)
								setNetAmount(0)
								setAdjustmentAmount(0)
							}}
						>
							Close
						</button>
					</div>
				</div>
			</EditorModal>
		</div>
	)
}

export default StatementEditor
