import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import AuthPage from './ExtAuthPage'
import SelectCompany from './ExtSelectCompany'

import { getCurrentUser } from '../../../services/authService'

const ExtensionAuth = () => {
	const [companies, setCompanies] = useState([])
	const [selectedCompany, setSelectedCompany] = useState('')
	const [token, setToken] = useState('')

	const navigate = useNavigate()

	const handleCompanySelection = e => {
		setSelectedCompany(e.target.value)
	}

	const handleCompanySubmit = () => {
		const data = { token }

		const company = companies.find(c => c._id === selectedCompany)
		data.company = { _id: selectedCompany, name: company?.name }

		if (window.opener) {
			window.opener.postMessage(JSON.stringify(data), '*')
			console.log('sending message to opener window')
		} else {
			console.error('The opener window is no longer available.')
		}
	}

	useEffect(() => {
		const getCompanies = () => {
			if (!window.opener) {
				navigate('/dashboard')
				return
			}

			const user = getCurrentUser()

			console.log('user', user)
			const companies = user?.companies
			const jwt = localStorage.getItem('jwtToken')

			if (!token) {
				setToken(jwt)
			}

			setCompanies(companies || [])
		}

		getCompanies()
	}, [])

	return (
		<div>
			{!companies.length ? (
				<>
					<AuthPage
						setCompanies={setCompanies}
						setToken={setToken}
						setSelectedCompany={setSelectedCompany}
					/>
				</>
			) : (
				<SelectCompany
					companies={companies}
					selectedCompany={selectedCompany}
					handleCompanySelection={handleCompanySelection}
					handleCompanySubmit={handleCompanySubmit}
				/>
			)}
		</div>
	)
}

export default ExtensionAuth
