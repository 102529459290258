import { useState } from 'react'

import { sendInvitation } from '../services/httpService'

export default function InviteUser(props) {
	const senderId = localStorage.getItem('userId')

	const [state, setState] = useState({
		sender: senderId,
		reciever: '',
		userType: 'DISPATCHER',
	})

	const changeHandler = e => {
		setState({
			...state,
			reciever: e.target.value?.trim()?.toLowerCase(),
		})
	}

	const selectHandler = e => {
		console.log('select:', e.target.value)
		setState({ ...state, userType: e.target.value })
	}

	const invite = async e => {
		e.preventDefault()
		props.setOpen(false)

		if (state.state === '') return alert('Enter user email!')

		const res = await sendInvitation(state)

		if (res.status === 200) {
			props.getUpdatedData && props.getUpdatedData()
			// alert('Sent !!!')
			props.toaster('success')
		} else {
			props.toaster('fail')
		}
	}

	return (
		<div className="space-y-6 overflow-auto">
			{/* <form onSubmit={invite}> */}
			<div className="bg-white shadow px-4 py-2 sm:rounded-lg sm:p-2">
				<div className="col-span-3 sm:col-span-3 lg:col-span-2">
					<div className="text-center">
						<svg
							className="mx-auto h-12 w-12 text-gray-400"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 48 48"
							aria-hidden="true"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
							/>
						</svg>
						<h2 className="mt-2 text-lg font-medium text-gray-900">
							Add team members
						</h2>
						<p className="mt-1 text-sm text-gray-500">
							Invite dispatchers and administrators to join your company.
						</p>
					</div>

					{/* <input
							required={true}
							id="invite"
							placeholder="Invtiation Email"
							type="email"
							className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
							name="invite"
							onChange={changeHandler}
						/> */}

					<form className="mt-6 sm:flex sm:items-center" onSubmit={invite}>
						<label htmlFor="emails" className="sr-only">
							Email addresses
						</label>
						<div className="relative rounded-md shadow-sm sm:min-w-0 sm:flex-1">
							<input
								onChange={changeHandler}
								required={true}
								type="text"
								name="invite"
								id="emails"
								className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-32 sm:text-sm border-gray-300 rounded-md"
								placeholder="Enter an email"
							/>
							<div className="absolute inset-y-0 right-0 flex items-center">
								<span className="h-4 w-px bg-gray-200" aria-hidden="true" />
								<label htmlFor="role" className="sr-only">
									Role
								</label>
								<select
									onChange={selectHandler}
									id="role"
									name="role"
									className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-4 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
								>
									<option value="DISPATCHER">Dispatcher</option>
									<option value="ADMIN">Administrator</option>
								</select>
							</div>
						</div>
						<div className="mt-3 sm:mt-0 sm:ml-4 sm:flex-shrink-0">
							<button
								type="submit"
								className="block w-full text-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								Send invite
							</button>
						</div>
					</form>
				</div>
			</div>

			{/* <div className="flex justify-end">
					<button
						type="button"
						className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						onClick={() => props.setOpen(false)}
					>
						Cancel
					</button>
					<button
						// onClick={invite}
						type="submit"
						className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					>
						Send
					</button>
				</div> */}
			{/* </form> */}
		</div>
	)
}
