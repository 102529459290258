import React, { useState } from 'react'
import { createBusiness } from '../../services/httpService'
import { getCurrentUser } from '../../services/authService'

export default function CreateNewBusiness(props) {
	const [state, setState] = useState({
		name: '',
	})

	const changeHandler = e => {
		const { name, value } = e.target

		setState({
			[name]: value,
		})
	}

	const submitHandler = async e => {
		e.preventDefault()

		if (state.name === '') {
			return alert('Please fill in the fields!')
		}

		const user = getCurrentUser()

		await createBusiness(user?.id, state.name)

		props.setOpen(false)
	}

	return (
		<div>
			<div className="space-y-6 overflow-auto">
				<form onSubmit={submitHandler}>
					<div className="bg-white shadow px-4 py-2 sm:rounded-lg sm:p-1">
						<div className="grid grid-cols-4 gap-2">
							<div className="col-span-3 sm:col-span-3 lg:col-span-2">
								<label
									htmlFor="broker"
									className="block text-sm font-medium text-gray-700"
								>
									Business Name
								</label>
								<input
									required={true}
									placeholder="First Name"
									type="text"
									name="name"
									onChange={changeHandler}
									id="name"
									autoComplete="name"
									className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
								/>
							</div>
						</div>
					</div>
					<div className="flex justify-end m-1 -pb-2">
						<button
							type="button"
							className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							onClick={() => props.setOpen(false)}
						>
							Cancel
						</button>
						<button
							type="submit"
							className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							Send
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}
