import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../NavigationBar/LoggedOutNavbar'
import { login } from '../../services/authService'

export default function Login() {
	const [state, setState] = useState({
		email: '',
		password: '',
		errorMsg: null,
		loading: false,
	})

	const onChange = e => {
		e.persist()
		setState({
			...state,
			loading: false,
			[e.target.name]: e.target.value,
		})
	}

	const timeoutFunctionNone = modal => {
		setTimeout(() => {
			modal.style.display = 'none'
		}, 5000)
	}

	const timeoutFunctionBlock = modal => {
		modal.style.display = 'block'
	}

	const onSubmit = async e => {
		setState(() => {
			return {
				...state,
				loading: true,
			}
		})
		e.preventDefault()

		const errModal = document.getElementById('modalForLoginError')

		try {
			await login(state)

			return timeoutFunctionNone(errModal)
		} catch (err) {
			timeoutFunctionBlock(errModal)

			setState({
				...state,
				loading: false,
				errorMsg: err?.response?.data?.message,
			})
			timeoutFunctionNone(errModal)

			// return console.log(err)
		}
	}

	return (
		<>
			<Navbar />
			<div className="min-h-screen bg-gray-50 flex flex-col justify-center py-8 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md -mt-28">
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
						Sign in to your account
					</h2>
				</div>
				<div id="modalForLoginError" style={{ display: 'none' }}>
					{state.errorMsg}
				</div>
				<div id="loggedInMsg" style={{ display: 'none' }}>
					Logged in!
				</div>
				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<form className="space-y-6" onSubmit={onSubmit}>
							<div>
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700"
								>
									Email address
								</label>
								<div className="mt-1">
									<input
										id="email"
										name="email"
										type="email"
										onChange={onChange}
										autoComplete="email"
										required
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									/>
								</div>
							</div>
							<div>
								<label
									htmlFor="password"
									className="block text-sm font-medium text-gray-700"
								>
									Password
								</label>
								<div className="mt-1">
									<input
										onChange={onChange}
										id="password"
										name="password"
										type="password"
										autoComplete="current-password"
										required
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									/>
								</div>
							</div>
							<div>
								<button
									type="submit"
									className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									Sign in
								</button>
							</div>
						</form>
						<div className="flex center justify-center align-center gap-2 py-2 items-center">
							<div>Don't Have an Account ?</div>
							<Link to="/sign-up" exact="true">
								<button className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Sign Up
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
