import React from 'react'

function Spinner() {
	return (
		<div className="relative">
			<span className="w-14 h-14 loading loading-spinner loading-lg"></span>
		</div>
	)
}

export default Spinner
