import React from 'react'
import moment from 'moment'

import { PencilIcon, DownloadIcon } from '@heroicons/react/solid'

import downloadDoc from './StatementPDF/PDFGenerator'

function msToTime(duration) {
	if (!duration) return 0
	return Number(duration / 3600 / 1000).toFixed(1)
}

function StatementItems(props) {
	const {
		index,
		statementId,
		totalAmount,
		totalMiles,
		totalHoursMS,
		driver,
		startDate,
		endDate,
		setEditorModalOpen,
		setSelectedStatement,
		numberWithCommas,
	} = props

	const handleEditor = e => {
		setEditorModalOpen(true)
		setSelectedStatement(statementId)
	}

	return (
		<tr>
			<td className="ml-4 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
				<div className="font-medium text-light-blue-500">{index + 1}</div>
			</td>

			<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
				<div
					className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5`}
				>
					{driver}
				</div>
			</td>

			<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
				<div className={`font-medium`}>
					${numberWithCommas(Number.parseFloat(totalAmount).toFixed(2))}
				</div>
			</td>

			<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
				<div className={`font-medium`}>
					{Number.parseFloat(totalMiles).toFixed(0)} mi
				</div>
			</td>

			<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
				<div className={`font-medium`}>
					${Number.parseFloat(totalAmount / totalMiles).toFixed(2)}
				</div>
			</td>

			<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
				<div className={`font-medium`}>{msToTime(totalHoursMS) || 0} hrs</div>
			</td>

			<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
				<div className="font-medium text-gray-800">
					{moment(startDate)?.format('MMM Do')} -{' '}
					{moment(endDate)?.format('MMM Do, YYYY')}
				</div>
			</td>

			<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
				<div className="space-x-4">
					<button
						className="text-gray-400 hover:text-gray-500 rounded-full"
						onClick={() => handleEditor()}
					>
						<PencilIcon className="w-6 h-6" />
					</button>

					<button
						onClick={() => downloadDoc(statementId, startDate, endDate, driver)}
						className="mx-6 text-gray-400 hover:text-gray-500 rounded-full"
					>
						<DownloadIcon className="w-6 h-6" />
					</button>
				</div>
			</td>
		</tr>
	)
}

export default StatementItems
