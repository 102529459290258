import React, { useState, useEffect, memo } from 'react'
import axios from 'axios'
import moment from 'moment-timezone'

import StopType from './StopType'
import Input from '../Input/LoadInput'
import LoadDateInput from '../Input/LoadDateInput'
import Select from '../Input/LoadSelectInput'
import TextArea from '../Input/LoadTextArea'
import AddressAutocomplete from '../AutoComplete/AutoCompletLoad'

import {
	convertState,
	capitalizeString,
} from '../../../../services/stateConversion'

import { cancelApiCalls } from '../../../../utils/utils'

const getTimezone = async (lng, lat) => {
	try {
		const token =
			'pk.eyJ1IjoidWZhcnJ1aCIsImEiOiJjbHEyN3VmOHcwMTljMmtuenZqa2V2bzVhIn0.D0nuHleLNCsGiRPqbqpDdg'

		let res = await axios.get(
			`https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${lng},${lat}.json?access_token=${token}`
		)

		return res.data.features[0].properties.TZID
	} catch (err) {
		console.log(err)
	}
}

const timezoneAbbr = timezone => {
	const abbreviation = moment.tz(timezone).zoneAbbr()

	return abbreviation
}

function LoadStop({
	stop,
	onRemove,
	index,
	onCoordinatesChange,
	onUpdateField,
	submitted,
}) {
	const [searchApiSources, setSearchApiSources] = useState([])
	const [coordinates, setCoordinates] = useState(null)
	const [timezone, setTimezone] = useState()

	const stopType = stop?.stopType === 'PICKUP' ? 'Shipper' : 'Receiver'

	useEffect(() => {
		const fetchCoordinates = async () => {
			try {
				const source = axios.CancelToken.source()
				setSearchApiSources(prev => [...prev, source])

				const token =
					'pk.eyJ1IjoibG9hZGZldGNoZXIiLCJhIjoiY2xwbmFybGM5MGllcjJxcW5rejkwbHZ5aSJ9.PUhc_wBs9RRY-j3ONTcopA'

				const fullAddress = encodeURIComponent(
					`${stop.address}, ${stop.city}, ${stop.state} ${stop.zipcode}`
				)

				const response = await axios.get(
					`https://api.mapbox.com/geocoding/v5/mapbox.places/${fullAddress}.json?access_token=${token}`,
					{ cancelToken: source.token }
				)

				if (response.data.features.length > 0) {
					const { coordinates } = response.data.features[0].geometry

					setCoordinates(coordinates)

					const timezone = await getTimezone(coordinates[0], coordinates[1])

					if (timezone) {
						setTimezone(timezone)
						onUpdateField(index, 'timezone', timezone)
					}
				} else {
					console.error('No coordinates found for this address.')
				}
			} catch (error) {
				console.error('Failed to fetch coordinates:', error)
			}
		}

		if (stop.address && stop.city && stop.state) {
			fetchCoordinates()
		}
	}, [stop.address, stop.city, stop.state, stop.zipcode])

	useEffect(() => {
		if (searchApiSources?.length > 1) cancelApiCalls(searchApiSources)
	}, [searchApiSources])

	useEffect(() => {
		if (coordinates) {
			onCoordinatesChange(index, coordinates)
		}
	}, [coordinates])

	const handleFieldChange = (fieldName, value) => {
		onUpdateField(index, fieldName, value)
	}

	const handleStopTypeChange = (index, newStopType) => {
		onUpdateField(index, 'stopType', newStopType)
	}

	const handleDateChange = (fieldName, dateValue) => {
		if (!dateValue) {
			console.error('No date value provided')
			return
		}

		onUpdateField(index, fieldName, dateValue)
	}

	const handleAddressChange = selectedAddress => {
		if (selectedAddress) {
			const [address, city, statezip] = selectedAddress?.address?.split(',')

			const [state, zipcode] = statezip?.trim()?.split(' ')

			onUpdateField(index, 'address', address || '')
			onUpdateField(index, 'city', city || '')
			onUpdateField(index, 'state', state || '')
			onUpdateField(index, 'zipcode', zipcode || '')
		}
	}

	return (
		<div className="bg-white p-0 mb-2 mb-4">
			<div className="grid grid-cols-2 gap-2 mb-2">
				<div className="flex items-center space-x-2">
					<span>{index + 1}</span>
					<button onClick={onRemove}>🗑️</button>
				</div>

				<StopType
					stopType={stop?.stopType}
					index={index}
					onUpdateStopType={handleStopTypeChange}
				/>

				<Input
					submitted={submitted}
					label={stopType}
					required={true}
					placeholder={`Enter ${stopType}'s Name`}
					value={stop?.company || ''}
					// defaultValue={stop?.company || ''}
					onChangeHandler={e => handleFieldChange('company', e.target.value)}
				/>

				<div id="loadDateContainer">
					<LoadDateInput
						submitted={submitted}
						label="Date"
						required={true}
						placeholder="--:--"
						defaultValue={stop?.date || ''}
						timezone={stop?.timezone}
						onChangeHandler={e => handleDateChange('date', e)}
					/>

					<div className="text-md" id="timezone">
						{timezone && timezoneAbbr(timezone)}
					</div>
				</div>

				<AddressAutocomplete
					stop={stop}
					handleAddressChange={handleAddressChange}
					label="Address"
					required={true}
					name="city"
					className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
				/>

				<Input
					submitted={submitted}
					label="City"
					required={true}
					placeholder="Enter City"
					defaultValue={stop?.city || ''}
					value={stop?.city || ''}
					onChangeHandler={e =>
						handleFieldChange('city', capitalizeString(e.target.value))
					}
				/>

				<Select
					submitted={submitted}
					label="State"
					required={true}
					placeholder="Select State"
					defaultValue={stop?.state && convertState(stop?.state)}
					value={stop?.state && convertState(stop?.state)}
					onChangeHandler={e => handleFieldChange('state', e.target.value)}
				/>

				<Input
					submitted={submitted}
					label="ZIP Code"
					required={true}
					placeholder="Enter ZIP Code"
					defaultValue={stop?.zipcode || ''}
					value={stop?.zipcode || ''}
					onChangeHandler={e => handleFieldChange('zipcode', e.target.value)}
				/>

				<TextArea
					submitted={submitted}
					label="Notes"
					required={false}
					placeholder="Enter notes for this stop"
					defaultValue={stop?.notes || ''}
					onChangeHandler={e => handleFieldChange('notes', e.target.value)}
				/>

				{coordinates && (
					<div>
						Coordinates: {coordinates[1]}, {coordinates[0]}
					</div>
				)}
			</div>
		</div>
	)
}

export default memo(LoadStop)
