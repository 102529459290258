import React, { useState, useEffect, memo } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'

import Input from './Input/LoadInput'
// import FileUpload from './LoadFileInput'
import LoadStop from './Stop/LoadStop'
import Select from './Input/LoadSelectInput'
import FormFooter from './LoadFormFooter'

import { getDefaultCompany } from '../../../services/authService'
import {
	capitalizeString,
	convertState,
} from '../../../services/stateConversion'

const normalizeString = str => str.toLowerCase().replace(/[^a-z0-9]/gi, '')

const findSimilarEquipment = (equipments, equipment) => {
	const normalizedEquipment = normalizeString(equipment)
	return equipments.find(eqp =>
		normalizedEquipment.includes(normalizeString(eqp))
	)
}

const formatData = load => {
	const equipments = ['Dry Van', 'Flatbed', 'Reefer', 'Power Only', 'Step Deck']

	const selectedEquipment = findSimilarEquipment(equipments, load.equipment)

	const result = {
		...load,
		originCity: capitalizeString(load?.originCity),
		originState: convertState(load?.originState),
		deliveryCity: capitalizeString(load?.deliveryCity),
		deliveryState: convertState(load?.deliveryState),
		equipment: selectedEquipment || load.equipment,
	}

	load?.stops?.forEach((stop, index) => {
		const city = capitalizeString(stop?.city)
		const state = convertState(stop?.state)

		result.stops[index] = {
			...stop,
			city,
			state,
		}
	})

	return result
}

const LoadForm = ({ extractedInfo, activeTab, setOpen, load }) => {
	const [data, setData] = useState({})
	const [stops, setStops] = useState([])
	const [totalDistance, setTotalDistance] = useState(0)
	const [submitted, setSubmitted] = useState(false)

	useEffect(() => {
		if (load) {
			setData(load)
			setStops(load.stops || [])
			setTotalDistance(load.distance || 0)
		} else if (extractedInfo) {
			const parsedData = formatData(extractedInfo)

			setStops(parsedData.stops || [])
			setData(parsedData)
		} else if (activeTab === 'manual' && !stops?.length) {
			setStops([{ stopType: 'PICKUP' }, { stopType: 'DROPOFF' }])
		}
	}, [extractedInfo, activeTab, load])

	console.log('extractedInfo', extractedInfo)

	const handleSubmit = async () => {
		setSubmitted(true)

		try {
			if (
				!data?.broker?.name ||
				!data?.loadId ||
				!data?.price ||
				!data?.equipment ||
				!data?.distance ||
				!stops?.length > 1
			) {
				toast.error('Please fill out all required fields.')
				return
			}

			const { _id } = getDefaultCompany()

			if (data._id) {
				await axios.put(`/api/loads/updateLoad`, {
					...data,
					stops: stops,
					company: _id,
					distance: totalDistance,
				})
			} else {
				await axios.post(`/api/loads`, {
					...data,
					stops: stops,
					company: _id,
					distance: totalDistance,
				})
			}

			toast.success('Load created successfully!')

			setOpen(false)
		} catch (error) {
			toast.error('Failed to create load:', error)
		}
	}

	const calculateDistance = async coordinatesList => {
		try {
			const coordsString = coordinatesList.map(c => c.join(',')).join(';')
			const access_token =
				'pk.eyJ1IjoidWZhcnJ1aCIsImEiOiJjbHEyN3VmOHcwMTljMmtuenZqa2V2bzVhIn0.D0nuHleLNCsGiRPqbqpDdg'

			const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordsString}?access_token=${access_token}&geometries=geojson`

			const response = await axios.get(url)
			const distance = response.data.routes[0].distance

			const distanceInMiles = distance * 0.000621371

			setTotalDistance(distanceInMiles.toFixed(0))

			setData(prevData => ({
				...prevData,
				distance: distanceInMiles.toFixed(0),
			}))
		} catch (error) {
			console.error('Failed to calculate distance:', error)
		}
	}

	const handleCoordinatesChange = (index, coords) => {
		const newStops = [...stops]

		newStops[index].coordinates = {
			lat: coords[1],
			lng: coords[0],
		}
		setStops(newStops)

		const coordinatesList = newStops
			?.filter(
				stop =>
					stop.coordinates &&
					typeof stop.coordinates.lng === 'number' &&
					typeof stop.coordinates.lat === 'number'
			)
			.map(stop => [stop.coordinates.lng, stop.coordinates.lat])

		if (coordinatesList.length > 1) {
			calculateDistance(coordinatesList)
		}
	}

	const addStop = () => {
		const stopType = stops.length % 2 === 0 ? 'PICKUP' : 'DROPOFF'

		console.log('stopType', stopType)

		setStops([...stops, { stopType: stopType }])
	}

	const removeStop = index => {
		const newStops = stops.filter((_, idx) => idx !== index)
		setStops(newStops)
	}

	const handleCancel = () => {
		setOpen(false)
	}

	const handleInputChange = (fieldName, value) => {
		setSubmitted(false)

		const keys = fieldName.split('.')

		if (keys.length > 1) {
			setData(prevData => ({
				...prevData,
				[keys[0]]: {
					...prevData[keys[0]],
					[keys[1]]: value,
				},
			}))
		} else {
			setData(prevData => ({ ...prevData, [fieldName]: value }))
		}
	}

	const handleStopFieldUpdate = (stopIndex, fieldName, value) => {
		setStops(prev => {
			return prev.map((el, index) => {
				if (index === stopIndex) {
					return { ...el, [fieldName]: value }
				}

				return el
			})
		})

		setSubmitted(false)
	}

	const sanitizePriceInput = input => {
		const numbers = input.replace(/[^\d.]/g, '')
		return numbers
	}

	return (
		<div className="mt-8 mb-4 bg-white p-0">
			<div className="bg-white p-0 mb-0">
				<div className="grid grid-cols-3 gap-2 mb-3">
					<div className="flex flex-col space-y-2">
						<Input
							submitted={submitted}
							label="Broker"
							required={true}
							placeholder="Enter Broker Name"
							// defaultValue={data?.broker?.name || ''}
							value={data?.broker?.name || ''}
							onChangeHandler={e =>
								handleInputChange('broker.name', e.target.value)
							}
						/>
					</div>

					<div className="flex flex-col space-y-2">
						<Input
							submitted={submitted}
							label="Reference Number"
							required={true}
							placeholder="Enter Reference #"
							value={data?.loadId || ''}
							// defaultValue={data?.loadId || ''}
							onChangeHandler={e => handleInputChange('loadId', e.target.value)}
						/>
					</div>

					<div className="flex flex-col space-y-2">
						<Input
							submitted={submitted}
							label="Total Price"
							required={true}
							placeholder="Enter Amount"
							value={data?.price || ''}
							// defaultValue={data?.price || ''}
							onChangeHandler={e =>
								handleInputChange('price', sanitizePriceInput(e.target.value))
							}
						/>
					</div>

					<div className="flex flex-col space-y-2">
						<Select
							submitted={submitted}
							label="Equipment"
							required={true}
							// value={data?.equipment || ''}
							defaultValue={data?.equipment || ''}
							onChangeHandler={e =>
								handleInputChange('equipment', e.target.value)
							}
						/>
					</div>

					<div className="flex flex-col space-y-2">
						<Input
							submitted={submitted}
							label="Total Distance"
							required={true}
							placeholder="Enter Distance"
							value={data?.distance || ''}
							// defaultValue={data?.distance || ''}
							onChangeHandler={e =>
								handleInputChange('distance', e.target.value)
							}
						/>
					</div>

					<div className="flex flex-col space-y-2">
						<Input
							submitted={submitted}
							label="Total Weight"
							required={false}
							placeholder="Enter Weight"
							value={data?.weight || ''}
							// defaultValue={data?.weight || ''}
							onChangeHandler={e => handleInputChange('weight', e.target.value)}
						/>
					</div>
				</div>

				{/* <div className="grid grid-cols-3 gap-2 mb-1">
					<FileUpload label="Rate Confirmation" />
				</div> */}
			</div>

			{stops?.map((stop, index) => {
				return (
					<LoadStop
						submitted={submitted}
						key={index}
						stop={stop}
						onRemove={() => removeStop(index)}
						index={index}
						onCoordinatesChange={handleCoordinatesChange}
						onUpdateField={handleStopFieldUpdate}
					/>
				)
			})}

			<FormFooter
				addStop={addStop}
				handleCancel={handleCancel}
				handleSubmit={handleSubmit}
			/>
		</div>
	)
}

export default LoadForm //memo(LoadForm)
