import React from 'react'
import LogRocket from 'logrocket'
import { Toaster } from 'react-hot-toast'
import moment from 'moment-timezone'

import { HashRouter as Router } from 'react-router-dom'

import Routes from './Routes'
import { getCurrentUser } from './services/authService'
import { DateProvider } from './components/context/dateContext'

moment.tz.setDefault('America/Los_Angeles')

function App() {
	const user = getCurrentUser()

	LogRocket.init('ggiapn/rapid-board')

	if (user) {
		LogRocket.identify(user?.id, {
			name: user?.name,
			email: user?.email,
		})
	}

	return (
		<Router>
			<DateProvider>
				<Routes />
				<Toaster />
			</DateProvider>
		</Router>
	)
}

export default App
