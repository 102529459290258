import React, { useState } from 'react';
import ManualLoad from './LoadForm/LoadForm';
import AutoLoad from './AutoLoad/AutoLoad';

function CreateNewLoad({ setOpen, setIsAutoLoadActive, isAutoLoadActive }) {
  const [activeTab, setActiveTab] = useState('auto');
  const [extractedInfo, setExtractedInfo] = useState(null);

  const handleExtractedInfo = (info) => {
    setExtractedInfo(info);
  };

  return (
    <div className='p-4'>
      <div className='mb-4 flex border-b'>
        <button
          onClick={() => {
            setActiveTab('auto');
            setIsAutoLoadActive(false);
          }}
          className={`px-4 py-2 flex items-center ${
            activeTab === 'auto'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-400'
          }`}
        >
          Auto Entry
        </button>

        <button
          onClick={() => {
            setActiveTab('manual');
            setIsAutoLoadActive(false);
          }}
          className={`px-4 py-2 flex items-center ${
            activeTab === 'manual'
              ? 'border-b-2 border-blue-500 text-blue-500'
              : 'text-gray-400'
          }`}
        >
          Manual Entry
        </button>
      </div>
      {activeTab === 'manual' ? (
        <ManualLoad
          info={extractedInfo}
          setOpen={setOpen}
          activeTab={activeTab}
        />
      ) : (
        <AutoLoad
          onExtractedInfo={handleExtractedInfo}
          extractedInfo={extractedInfo}
          setOpen={setOpen}
          setIsAutoLoadActive={setIsAutoLoadActive}
          isAutoLoadActive={isAutoLoadActive}
        />
      )}
    </div>
  );
}

export default CreateNewLoad;
