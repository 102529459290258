import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

import {
	getDashboardStats,
	getDriverTableStats,
	getDispatchTableStats,
} from '../../services/httpService'

import { setDateFilterHelper, prevMonth, nextMonth } from './dashboarHelpers'
import { getDefaultCompany } from '../../services/authService'

import DashboardCards from './DashboardCards'
import DashboardTable from './DashboardTable'
import axios from 'axios'

export default function Dashboard() {
	const [dateFilter, setDateFilter] = useState(setDateFilterHelper())

	const [stats, setStats] = useState(null)
	const [tableData, setTableData] = useState(null)
	const [tableDataType, setTableDataType] = useState('DRIVERS')
	const [loading, setLoading] = useState(false)

	const company = getDefaultCompany()

	useEffect(() => {
		const source = axios.CancelToken.source()

		getDashboardStats(
			dateFilter?.startDate,
			dateFilter?.endDate,
			dateFilter?.prevStartDate,
			dateFilter?.prevEndDate,
			company?._id,
			source.token
		)
			.then(res => {
				setStats(res)
			})
			.catch(err => console.log('Error:', err))

		return () => source.cancel('Previous request canceled')
	}, [JSON.stringify(dateFilter)])

	useEffect(() => {
		const source = axios.CancelToken.source()

		if (tableDataType === 'DISPATCHERS') {
			setLoading(true)
			getDispatchTableStats(
				dateFilter?.startDate,
				dateFilter?.endDate,
				dateFilter?.prevStartDate,
				dateFilter?.prevEndDate,
				company?._id,
				source.token
			)
				.then(res => {
					setTableData(res)
					setLoading(false)
				})
				.catch(err => console.log('Error:', err))
		} else {
			setLoading(true)
			getDriverTableStats(
				dateFilter?.startDate,
				dateFilter?.endDate,
				dateFilter?.prevStartDate,
				dateFilter?.prevEndDate,
				company?._id,
				source.token
			)
				.then(res => {
					setTableData(res)
					setLoading(false)
				})
				.catch(err => console.log('Error:', err))
		}

		return () => source.cancel('Previous request canceled')
	}, [dateFilter, tableDataType])

	//Decrease Filter Date
	const decreaseDate = () => {
		if (dateFilter.period === 'MONTH') {
			const dateFilterCopy = { ...dateFilter }
			setDateFilter(prevMonth(dateFilterCopy))
		} else {
			const startDate = new Date(
				dateFilter?.startDate?.setDate(dateFilter?.startDate?.getDate() - 7)
			)
			const endDate = new Date(
				dateFilter?.endDate?.setDate(dateFilter?.endDate?.getDate() - 7)
			)

			const prevStartDate = new Date(
				dateFilter?.prevStartDate?.setDate(
					dateFilter?.prevStartDate?.getDate() - 7
				)
			)

			const prevEndDate = new Date(
				dateFilter?.prevEndDate.setDate(dateFilter?.prevEndDate?.getDate() - 7)
			)
			setDateFilter({ startDate, endDate, prevStartDate, prevEndDate })
		}
	}

	//Increase Filter Date
	const increaseDate = () => {
		if (dateFilter.period === 'MONTH') {
			const dateFilterCopy = { ...dateFilter }
			setDateFilter(nextMonth(dateFilterCopy))
		} else {
			const startDate = new Date(
				dateFilter?.startDate?.setDate(dateFilter?.startDate?.getDate() + 7)
			)
			const endDate = new Date(
				dateFilter?.endDate?.setDate(dateFilter?.endDate?.getDate() + 7)
			)
			const prevStartDate = new Date(
				dateFilter?.prevStartDate?.setDate(
					dateFilter?.prevStartDate?.getDate() + 7
				)
			)
			const prevEndDate = new Date(
				dateFilter?.prevEndDate.setDate(dateFilter?.prevEndDate?.getDate() + 7)
			)

			setDateFilter({ startDate, endDate, prevStartDate, prevEndDate })
		}
	}

	const perioSelectHandler = e => {
		setDateFilter(setDateFilterHelper(e.target.value))
	}

	const tableDataTypeHandler = e => {
		setTableDataType(e.target.value)
	}

	return (
		<>
			<div className="mt-8">
				<div className="max-w-17xl mx-auto px-4 sm:px-6 lg:px-8">
					<div className="flow-root w-auto">
						<div className="inline float-left">
							<span className="text-lg pr-1"> Your</span>
							<select
								value={dateFilter.period}
								onChange={perioSelectHandler}
								className="mt-2 w-auto rounded-md border-0 py-1.5 pl-1 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300"
							>
								<option value="WEEK">Weekly</option>
								<option value="MONTH">Monthly</option>
							</select>{' '}
							<span className="text-lg pl-1"> Dashboard</span>
						</div>
						<div className="inline float-right">
							<button onClick={decreaseDate}>
								<ChevronLeftIcon className="w-6 h-7 inline" />
							</button>
							{moment(dateFilter?.startDate).format('MMM Do') +
								' - ' +
								moment(dateFilter?.endDate).format('MMM Do, YYYY')}
							<button onClick={increaseDate}>
								<ChevronRightIcon className="w-6 h-7 inline" />
							</button>
						</div>
					</div>

					<DashboardCards
						data={stats}
						period={dateFilter.period}
						loading={loading}
					/>

					<DashboardTable
						data={tableData}
						tableDataTypeHandler={tableDataTypeHandler}
						tableDataType={tableDataType}
						loading={loading}
					/>
				</div>
			</div>
		</>
	)
}
