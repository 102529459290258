import React from 'react'
import { formatDistanceToNow } from 'date-fns'
import md5 from 'md5'

// fix here
const DriverNotesList = ({ notes }) => {
	if (!notes?.length)
		return (
			<div className="text-xl font-semibold mb-4 text-center p-2 text-sm text-gray-700 bg-gray-100 rounded">
				No notes for this driver.
			</div>
		)

	const getGravatarUrl = email => {
		const hash = md5(email.trim().toLowerCase())
		return `https://www.gravatar.com/avatar/${hash}?d=identicon&s=100`
	}

	return (
		<div className="space-y-4 w-full md:w-3/4 lg:w-1/2 mx-auto">
			{notes.map((note, index) => {
				const avatarUrl = note?.createdBy?.email
					? getGravatarUrl(note.createdBy.email)
					: null

				return (
					<div
						key={index}
						className="flex items-start space-x-4 p-4 rounded-lg bg-white shadow hover:bg-gray-50 transition duration-300 ease-in-out"
					>
						{avatarUrl ? (
							<img
								className="h-10 w-10 rounded-full object-cover"
								src={avatarUrl}
								alt={note?.createdBy?.name}
							/>
						) : (
							<div className="rounded-full h-10 w-10 flex items-center justify-center bg-indigo-500 text-white uppercase">
								{note?.createdBy?.name[0]}
							</div>
						)}
						<div className="flex-1">
							<div className="flex items-center justify-between">
								<div>
									<span className="text-sm font-bold text-gray-900">
										{note.createdBy.name}
									</span>
									<span className="ml-1 text-sm text-gray-500">
										{formatDistanceToNow(new Date(note.createdAt), {
											addSuffix: true,
										})}
									</span>
								</div>
							</div>
							<p className="mt-1 text-sm text-gray-600">{note.content}</p>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default DriverNotesList
