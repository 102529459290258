import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
  DotsHorizontalIcon,
  PencilAltIcon,
  TrashIcon,
} from '@heroicons/react/solid';

function classNames(...classes) {
  return classes?.filter(Boolean).join(' ');
}

export default function LoadMenuDots({
  // openModal,
  // setOpenModal,
  driver,
  setConfirm,
  setActionType,
  setSelectedDriver,
}) {
  const handleClick = (e) => {
    e.preventDefault();

    setActionType(e.target.innerText);
    setConfirm(true);
    setSelectedDriver(driver);
  };

  return (
    <div className='z-50'>
      <Menu as='div' className='relative flex justify-center items-center'>
        <Menu.Button className='w-8 h-8 bg-white inline-flex items-center justify-center text-gray-700 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'>
          <span className='sr-only'>Open options</span>
          <DotsHorizontalIcon className='w-5 h-5' aria-hidden='true' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='mx-3 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none'>
            <div className='py-1'>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href='#'
                    onClick={handleClick}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm z-20'
                    )}
                  >
                    <PencilAltIcon
                      className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                      aria-hidden='true'
                    />
                    {!driver?.isActive ? 'Activate' : 'Deactivate'}
                  </a>
                )}
              </Menu.Item>
            </div>
            <div className='py-1'>
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={handleClick}
                    href='#'
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm'
                    )}
                  >
                    <TrashIcon
                      className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                      aria-hidden='true'
                    />
                    Delete
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
