import Header from "./Header";
import Main from "./Main";

const LandingPage = () => {
  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <Header />
        <Main />
      </div>
    </div>
  );
};

export default LandingPage;
