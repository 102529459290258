import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

import 'react-datepicker/dist/react-datepicker.css'

import './loaddateinput.css'

const LoadDateInput = ({
	label,
	placeholder,
	onChangeHandler,
	defaultValue,
	required,
	submitted,
	timezone,
}) => {
	const [selectedDate, setSelectedDate] = useState(null)

	const handleDateChange = newDate => {
		if (newDate) {
			const utcDate = zonedTimeToUtc(newDate, timezone)
			setSelectedDate(utcDate)

			onChangeHandler(utcDate)
		} else {
			console.error(
				'handleDateChange was called with a null or undefined newDate.'
			)
		}
	}

	useEffect(() => {
		if (defaultValue) {
			const zonedDate = utcToZonedTime(new Date(defaultValue), timezone)
			setSelectedDate(zonedDate)
		}
	}, [defaultValue, timezone])
	return (
		<div className="relative mb-1 w-full">
			<label
				htmlFor="date"
				className="absolute -top-2 left-2 z-10 inline-block bg-white px-1 text-xs font-medium text-gray-500"
			>
				{label}
				{required && <span className="text-red-500">*</span>}
			</label>
			<DatePicker
				style={{
					width: '100%',
				}}
				selected={selectedDate}
				onChange={handleDateChange}
				showTimeSelect
				timeFormat="HH:mm"
				timeIntervals={15}
				dateFormat="MMMM d, yyyy h:mm aa"
				placeholderText={placeholder}
				className={`p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
					submitted && !defaultValue ? 'datepicker-error' : ''
				}`}
			/>
		</div>
	)
}

export default LoadDateInput
