import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/solid';

const SearchSVG = () => (
  <svg
    className='w-4 h-4 text-gray-500 dark:text-gray-400'
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 20 20'
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
    />
  </svg>
);

const FILTER_CONFIG = [
  { name: 'All', color: 'green', count: 'total' },
  { name: 'Ready', color: 'yellow', count: 'ready' },
  // { name: 'Late', color: 'red', count: 'late' },
  { name: 'Driving', color: 'blue', count: 'driving' },
  { name: 'Others', color: 'neutral', count: 'others' },
];

function DispatchBoardFilters({
  setSelectedFilter,
  selectedFilter,
  statusCounts,
  handleSearchChange,
}) {
  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  return (
    <div className='mb-4 mt-8 flex items-center w-full'>
      <div className='flex-grow flex items-center space-x-4'>
        <div className='relative w-1/3'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <SearchSVG />
          </div>
          <input
            type='search'
            id='default-search'
            className='block w-full p-2 pl-8 text-sm text-gray-800 border border-gray-300 rounded-md bg-gray-50'
            placeholder='Search Driver, Truck...'
            onChange={handleSearchChange}
          />
        </div>

        <ul className='flex items-center space-x-2'>
          {FILTER_CONFIG.map((filter, index) => {
            const filterId = `filter-${index}`;
            const isActive = selectedFilter === filterId;
            const buttonStyle = isActive
              ? 'bg-blue-950 text-white border-gray-800 hover:bg-blue-900'
              : 'bg-gray-100 text-gray-700 border-gray-300 hover:bg-gray-00';

            const filterLabel = `${statusCounts[filter.count]} ${filter.name}`;

            return (
              <li key={index} className='flex-grow'>
                <label
                  htmlFor={filterId}
                  className={`relative inline-flex items-center px-2 py-2 text-sm border rounded-md cursor-pointer ${buttonStyle}`}
                >
                  <input
                    type='radio'
                    id={filterId}
                    name='dispatch-filter'
                    value={filterId}
                    className='absolute opacity-0 w-0 h-0'
                    onChange={handleFilterChange}
                    checked={isActive}
                  />
                  {isActive && (
                    <CheckCircleIcon className='w-4 h-4 ml-1 mr-1' />
                  )}
                  {filterLabel}
                </label>
              </li>
            );
          })}
        </ul>
      </div>
      <div className='relative inline-block text-left'>
        {/* <button className="px-2 py-1 text-sm rounded border border-gray-300">
					More Filters
				</button> */}
        <div className='origin-top-right absolute mt-2 w-56 rounded-md shadow-lg bg-white'></div>
      </div>
    </div>
  );
}

export default DispatchBoardFilters;
