import React, { useState, useRef, useEffect } from 'react'
import { AdjustmentsIcon } from '@heroicons/react/solid'

import SelectDispatcher from './SelectDispatcher'
import SelectStatus from './SelectStatus'
import SelectDriver from './SelectDriver'

const SettingsDropdown = ({
	onDispatcherChange,
	onDriverChange,
	onStatusChange,
	filters,
	activeTab,
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const container = useRef(null)

	const handleClickOutside = event => {
		if (container.current && !container.current.contains(event.target)) {
			setIsOpen(false)
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => document.removeEventListener('mousedown', handleClickOutside)
	}, [])

	const handleDispatcherChange = (dispatcher, dispatcherName) => {
		onDispatcherChange(dispatcher, dispatcherName)
	}

	return (
		<div ref={container} className="relative inline-block text-left">
			<div>
				<button
					onClick={() => setIsOpen(!isOpen)}
					className="flex items-center text-gray-500 focus:outline-none"
					id="options-menu"
					aria-haspopup="true"
					aria-expanded="true"
				>
					<AdjustmentsIcon
						className="text-blue-600 h-6 w-6"
						aria-hidden="true"
					/>
				</button>
			</div>

			{isOpen && (
				<div
					className="origin-top-right absolute left-0 mt-1 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="options-menu"
				>
					<div className="my-3 mx-1 mpy-1 flex flex-col space-y-3 p-3">
						{activeTab === 'History' && (
							<div className="flex flex-col">
								<label className="text-sm font-medium text-gray-700">
									Select Status
								</label>
								<SelectStatus onChange={onStatusChange} />
							</div>
						)}

						<div className="flex flex-col">
							<label className="text-sm font-medium text-gray-700">
								Select Dispatcher
							</label>
							<SelectDispatcher
								filters={filters}
								onChange={handleDispatcherChange}
							/>
						</div>

						<div className="flex flex-col">
							<label className="text-sm font-medium text-gray-700">
								Select Driver
							</label>
							<SelectDriver onChange={onDriverChange} />
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default SettingsDropdown
