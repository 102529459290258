import React, { useState } from 'react'

function StopType({ stopType, index, onUpdateStopType }) {
	const [active, setActive] = useState(
		stopType
			? stopType === 'PICKUP'
				? 'Pick Up'
				: 'Delivery'
			: index + (1 % 2) === 0
			? 'Delivery'
			: 'Pick Up'
	)

	return (
		<div className="btn-group">
			<button
				className={`btn btn-xs px-2 py-1 ${
					active === 'Pick Up' ? 'btn-active' : ''
				}`}
				onClick={() => {
					setActive('Pick Up')
					onUpdateStopType(index, 'PICKUP')
				}}
			>
				Pick Up
			</button>
			<button
				className={`btn btn-xs px-2 py-1 ${
					active === 'Delivery' ? 'btn-active' : ''
				}`}
				onClick={() => {
					setActive('Delivery')
					onUpdateStopType(index, 'DROPOFF')
				}}
			>
				Delivery
			</button>
		</div>
	)
}

export default StopType
