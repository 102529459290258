import DriversTable from './DriversTable'
import DispatcherTable from './DispatchersTable'

import { getCurrentUser } from '../../services/authService'

export default function StatisticsTable({
	data,
	tableDataTypeHandler,
	tableDataType,
	loading,
}) {
	const user = getCurrentUser()

	return (
		<>
			<div className="flow-root mx-auto mt-8 px-2 text-sm leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
				{user?.isAdmin && (
					<div className="float-right">
						<select
							value={tableDataType}
							onChange={tableDataTypeHandler}
							className="mt-2 w-auto rounded-md border-0 py-1.5 pl-1 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300"
						>
							<option value="DRIVERS">Drivers</option>
							<option value="DISPATCHERS">Dispatchers</option>
						</select>
						{'  '}
					</div>
				)}
			</div>

			{tableDataType === 'DRIVERS' ? (
				<DriversTable data={data} loading={loading} />
			) : (
				<DispatcherTable data={data} loading={loading} />
			)}
		</>
	)
}
