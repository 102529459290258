import React from 'react'

const TextArea = ({
	label,
	placeholder,
	defaultValue,
	onChangeHandler,
	required,
	submitted,
}) => {
	const inputClassStyle = {
		border:
			submitted && !defaultValue && required
				? '1px solid red'
				: '1px solid #d1d5db',
	}

	return (
		<div className="relative mb-1">
			<label
				htmlFor="text"
				className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
			>
				{label}
				{required && <span className="text-red-500">*</span>}
			</label>
			<textarea
				style={inputClassStyle}
				defaultValue={defaultValue}
				onChange={onChangeHandler}
				required={required}
				name="text"
				id="text"
				placeholder={placeholder}
				className="p-2 block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
				rows={2}
			/>
		</div>
	)
}

export default TextArea
