import React, { useState } from 'react'

const NotesForm = ({ addNote }) => {
	const [note, setNote] = useState('')
	const [isExpanded, setIsExpanded] = useState(false)

	const handleSubmit = async event => {
		event.preventDefault()
		const trimmedNote = note.trim()

		if (!trimmedNote) return

		try {
			await addNote(trimmedNote)
			setNote('')

			setTimeout(() => setIsExpanded(false), 200)
		} catch (error) {
			console.error('Error adding note:', error)
		}
	}

	return (
		<div className="mt-4 mb-3 w-full md:w-3/4 lg:w-1/2 mx-auto">
			<form onSubmit={handleSubmit} className="space-y-1">
				<textarea
					placeholder="Add your note..."
					className={`textarea textarea-bordered w-full rounded-lg border-gray-200 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
						isExpanded ? 'rows-3' : 'rows-1'
					}`}
					onFocus={() => setIsExpanded(true)}
					onBlur={() => !note.trim() && setIsExpanded(false)}
					value={note}
					onChange={e => setNote(e.target.value)}
					rows={isExpanded ? 3 : 1}
				/>
				{isExpanded && (
					<button
						style={{ width: '100px' }}
						type="submit"
						className="rounded-md text-white bg-green-500 hover:bg-green-600 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md"
					>
						Add Note
					</button>
				)}
			</form>
		</div>
	)
}

export default NotesForm
