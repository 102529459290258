import Skeleton from '../common/Skeleton'
import React, { useState } from 'react'
import Modal from '../common/Modal'
import CreateDriver from './CreateDriver'
import { PlusIcon } from '@heroicons/react/solid'

export default function DriverForSettings({ loading, getUpdatedData }) {
	const [driverOpen, setDriverOpen] = useState(false)

	return (
		<div>
			{loading ? (
				<div className="w-full mx-auto h-full flex items-center justify-center">
					<Skeleton size="w-full " />
				</div>
			) : (
				<div className="mt-1">
					<Modal open={driverOpen} setOpen={setDriverOpen}>
						<CreateDriver
							setOpen={setDriverOpen}
							getUpdatedData={getUpdatedData}
						/>
					</Modal>
					<button
						onClick={() => setDriverOpen(true)}
						type="button"
						className="relative inline-flex items-start px-2 py-2  border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-green-500 mb-2"
					>
						<PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true" />
						<span>Add New Driver</span>
					</button>
				</div>
			)}
		</div>
	)
}
