import React from 'react';

const SearchSVG = () => (
  <svg
    className='w-4 h-4 text-gray-500 dark:text-gray-400'
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 20 20'
  >
    <path
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
    />
  </svg>
);

function Filters({ handleSearchChange }) {
  return (
    <div className='mb-4 mt-2 flex items-center w-full'>
      <div className='flex-grow flex items-center space-x-4'>
        <div className='relative w-1/3'>
          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
            <SearchSVG />
          </div>
          <input
            type='search'
            id='default-search'
            className='block w-full p-2 pl-8 text-sm text-gray-800 border border-gray-300 rounded-md bg-gray-50'
            placeholder='Search Driver...'
            onChange={handleSearchChange}
          />
        </div>
      </div>
    </div>
  );
}

export default Filters;
