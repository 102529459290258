import React, { memo } from 'react'

const Input = ({
	label,
	placeholder,
	onChangeHandler,
	required,
	submitted,
	value,
}) => {
	const inputClassStyle =
		submitted && required && !value ? '1px solid red' : '1px solid transparent'

	return (
		<div className="relative mb-1">
			<label
				htmlFor="name"
				className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
			>
				{label}
				{required && <span className="text-red-500">*</span>}
			</label>
			<input
				style={{ border: inputClassStyle }}
				value={value}
				onChange={onChangeHandler}
				required={required}
				type="text"
				name="text"
				id="text"
				placeholder={placeholder}
				className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
			/>
		</div>
	)
}

export default memo(Input)
