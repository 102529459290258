import React from 'react'

export default function Pagination({
	itemsCount,
	currentItems,
	next,
	previous,
	returnedLoads,
}) {
	// Calculate total pages and current page
	const totalPages = Math.ceil(itemsCount / 20)
	const currentPage = Math.floor(currentItems / 20) + 1

	const startItem = currentItems === 0 ? 1 : currentItems + 1
	const endItem =
		returnedLoads < 20
			? itemsCount
			: currentItems === 0
			? 20
			: currentItems + 20

	return (
		<div>
			<nav
				className="bg-white py-3 border-t border-gray-200 flex items-center"
				aria-label="Pagination"
			>
				<div className="hidden sm:block">
					<p className="text-sm text-gray-700">
						Showing
						<span className="font-medium mx-1">{startItem}</span>
						to
						<span className="font-medium mx-1">{endItem}</span>
						of
						<span className="font-medium  mx-1">{itemsCount}</span>
						results
					</p>
				</div>

				{itemsCount > 20 && (
					<div className="flex-1 flex justify-between sm:justify-end">
						<button
							disabled={currentPage === 1}
							onClick={previous}
							type="button"
							className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							style={{
								opacity: currentPage === 1 ? '0.7' : '1',
								cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
							}}
						>
							Previous
						</button>

						<button
							disabled={returnedLoads < 20}
							onClick={next}
							type="button"
							className="inline-flex items-center disabled:not-allowed px-4 py-2 ml-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							style={{
								opacity: returnedLoads < 20 ? '0.7' : '1',
								cursor: returnedLoads < 20 ? 'not-allowed' : 'pointer',
							}}
						>
							Next
						</button>
					</div>
				)}
			</nav>
		</div>
	)
}
