import { useState } from 'react'

import { extensionLogin } from '../../../services/authService'
import jwtDecode from 'jwt-decode'

export default function AuthPage({
	setCompanies,
	setToken,
	setSelectedCompany,
}) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(false)

	const handleSignIn = async event => {
		event.preventDefault()
		setLoading(true)
		setError('')

		try {
			const response = await extensionLogin({ email, password })

			const token = response?.split(' ')[1]
			const user = jwtDecode(token)

			setToken(response)
			setCompanies(user?.companies || [])
			setSelectedCompany(user?.companies[0]?._id)
		} catch (error) {
			setError(
				error.response?.data?.message ||
					'Failed to sign in. Check your credentials and try again.'
			)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<div className="bg-gray-900 flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-sm">
					<img
						className="mx-auto h-10 w-auto"
						src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
						alt="Your Company"
					/>
					<h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
						Sign in to your account
					</h2>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
					<form className="space-y-6" onSubmit={handleSignIn}>
						{error && <div className="text-red-600">{error}</div>}

						<div>
							<label
								htmlFor="email"
								className="block text-sm font-medium leading-6 text-white"
							>
								Email address
							</label>
							<div className="mt-2">
								<input
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required
									value={email}
									onChange={e => setEmail(e.target.value)}
									className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<div className="flex items-center justify-between">
								<label
									htmlFor="password"
									className="block text-sm font-medium leading-6 text-white"
								>
									Password
								</label>
							</div>
							<div className="mt-2">
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									value={password}
									onChange={e => setPassword(e.target.value)}
									className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>

						<div>
							<button
								type="submit"
								disabled={loading}
								className={`flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 ${
									loading ? 'opacity-50 cursor-not-allowed' : ''
								}`}
							>
								{loading ? 'Signing in...' : 'Sign in'}
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}
