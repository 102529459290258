import React, { useState, useEffect } from 'react'
import axios from 'axios'

import StatementsTable from './StatementsTable'
import DateRangeFilter from './DateFilter/DateRangeFilter'
import StatementEditor from './StatementEditor/StatementEditor'

import { getStatements, generateStatements } from '../../services/httpService'

import { useDate } from '../context/dateContext'

function Statements() {
	const { startDate, endDate, weekNumber } = useDate()

	const [selectedItems, setSelectedItems] = useState([])
	const [offset, setOffset] = useState(0)

	const [statements, setStatements] = useState([])
	const [loading, setLoading] = useState(false)

	const [showInput, setShowInput] = useState(false)

	const [selectedStatement, setSelectedStatement] = useState(null)
	const [editorModalOpen, setEditorModalOpen] = useState(false)

	const handleSelectedItems = selectedItems => {
		setSelectedItems([...selectedItems])
	}

	const handleGenerateStatements = async () => {
		setLoading(true)

		await generateStatements({ startDate, endDate, weekNumber })
		const result = await getStatements({ startDate, endDate, weekNumber })

		setStatements(result)
		setLoading(false)
	}

	useEffect(() => {
		const source = axios.CancelToken.source()

		const fetchStatements = async () => {
			setLoading(true)
			const res = await getStatements(
				{ startDate, endDate, weekNumber },
				source.token
			)

			if (res !== 'err' && res !== 'CanceledError') setStatements(res)
			if (res !== 'CanceledError') setLoading(false)
		}

		fetchStatements()

		return () => source.cancel('Previous request canceled')
	}, [startDate, endDate, weekNumber])

	return (
		<div className="h-96">
			<div className="relative flex flex-col flex-1">
				<main>
					<div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
						<div className="sm:flex sm:justify-between sm:items-center mb-5">
							<div className="-mb-4">
								<h1 className="text-2xl text-gray-800 font-bold">
									Statements wk{weekNumber}
								</h1>
							</div>

							<div className="grid grid-flow-col justify-start gap-5">
								<button
									onClick={handleGenerateStatements}
									type="button"
									className="rounded-md bg-green-500 px-2 py-1 text-sm font-semibold text-white hover:bg-green-600"
								>
									Generate
								</button>

								<DateRangeFilter setOffset={setOffset} />
							</div>
						</div>

						<StatementsTable
							selectedItems={handleSelectedItems}
							statements={statements}
							loading={loading}
							setEditorModalOpen={setEditorModalOpen}
							setSelectedStatement={setSelectedStatement}
						/>
					</div>
				</main>
			</div>

			<StatementEditor
				selectedStatement={selectedStatement}
				editorModalOpen={editorModalOpen}
				setEditorModalOpen={setEditorModalOpen}
				setShowInput={setShowInput}
				setSelectedStatement={setSelectedStatement}
				showInput={showInput}
			/>
		</div>
	)
}

export default Statements
