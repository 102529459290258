import React from 'react'
import PropTypes from 'prop-types'

function TabsFilter({ tabs, activeTab, onTabChange, setFilters }) {
	return (
		<div className="flex border-b">
			{tabs.map(tab => (
				<button
					key={tab}
					className={`py-2 px-4 text-sm ${
						activeTab === tab
							? 'border-b-2 border-blue-500 text-blue-600'
							: 'text-gray-500 hover:text-gray-700'
					}`}
					onClick={() => {
						onTabChange(tab)
						setFilters(prevState => ({
							...prevState,
							offset: 0,
							filters: {
								...prevState.filters,
								status: tab,
							},
						}))
					}}
				>
					{tab}
				</button>
			))}
		</div>
	)
}

TabsFilter.propTypes = {
	tabs: PropTypes.array.isRequired,
	activeTab: PropTypes.string.isRequired,
	onTabChange: PropTypes.func.isRequired,
}

export default TabsFilter
