import Navbar from '../NavigationBar/LoggedOutNavbar'
import React, { useState, useEffect } from 'react'
import { inviteRegister } from '../../services/authService'
import { useParams } from 'react-router-dom'
import axios from 'axios'

export default function Index() {
	const params = useParams()
	const { token } = params

	const [error, setError] = useState(null)
	const [state, setState] = useState({
		name: '',
		email: '',
		password: '',
		password2: '',
		companyName: '',
		companyId: '',
		token: token,
	})

	const modal = document.getElementById('modalForSignupError')
	const showError = () => {
		modal.style.display = 'block'
		return setTimeout(() => {
			modal.style.display = 'none'
		}, 3000)
	}

	const waitFunc = async () => {
		return await inviteRegister(state)
	}

	const submitHandler = async e => {
		e.preventDefault()
		if (state.password !== state.password2) {
			setError("Passwords don't match!")
			return showError()
		}

		if (state.name !== '' && state.email !== '' && state.password !== '') {
			if (state.password.length < 6) {
				modal.style.display = 'block'
				setError('Password must be more than 6 characters!')
				return showError()
			}
			waitFunc()
		}
	}

	const onChange = e => {
		e.preventDefault()
		const { name, value } = e.target
		setState({ ...state, [name]: value })
	}

	useEffect(
		() => {
			const ac = new AbortController()

			async function checkInvitation() {
				const res = await axios.get(`/api/invites/accept/${token}`, {
					signal: ac.signal,
				})
				if (res.status === 200 && res.data.success) {
					const { email, companyId, company } = res.data

					setState({
						...state,
						email,
						companyName: company,
						companyId,
					})
				}
			}

			checkInvitation()
			return () => ac.abort()
		},
		[
			/*state, token*/
		]
	)

	return (
		<>
			<Navbar />
			<div className="max-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<img
						className="mx-auto h-11 w-auto"
						src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
						alt="Workflow"
					/>
					<h2 className="mt-1 text-center text-3xl font-extrabold text-gray-900">
						Sign up for an account
					</h2>
				</div>
				<div
					className="text-center text-red text-danger"
					id="modalForSignupError"
					style={{ display: 'none' }}
				>
					{error}
				</div>
				<div id="successfullyCreatedModal" style={{ display: 'none' }}>
					Created Your Account!
				</div>
				<div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<form className="space-y-6" onSubmit={submitHandler}>
							<div>
								<label
									htmlFor="companyName"
									className="block text-sm font-medium text-gray-700"
								>
									Company Name
								</label>
								<input
									// onChange={onChange}
									value={state.companyName}
									id="companyName"
									name="companyName"
									type="companyName"
									disabled={true}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
							<div className="-mt-5">
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700"
								>
									Company Email
								</label>
								<input
									// onChange={onChange}
									value={state.email}
									disabled={true}
									id="email"
									name="email"
									type="email"
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div>
								<label
									htmlFor="companyName"
									className="block text-sm font-medium text-gray-700"
								>
									Name
								</label>
								<input
									onChange={onChange}
									id="name"
									name="name"
									type="name"
									autoComplete="name"
									required={true}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div>
								<label
									htmlFor="password"
									className="block text-sm font-medium text-gray-700"
								>
									Password
								</label>
								<input
									onChange={onChange}
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required={true}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
							<div>
								<label
									htmlFor="password"
									className="block text-sm font-medium text-gray-700"
								>
									Retype Your Password
								</label>
								<div className="">
									<input
										onChange={onChange}
										id="password2"
										name="password2"
										type="password"
										autoComplete="current-password"
										required={true}
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									/>
								</div>
							</div>

							<div>
								<button
									type="submit"
									className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									Sign Up
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	)
}
