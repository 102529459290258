import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import { getSelectDispatchers } from '../../../services/httpService'

const SelectDispatcher = ({ filters, loadId, onChange }) => {
	const [options, setOptions] = useState([])
	const [selected, setSelected] = useState(null)

	const dispatcher = filters?.dispatcher

	useEffect(() => {
		if (dispatcher) {
			const dispatcherOption = options.find(o => o?.value === dispatcher)
			setSelected({
				value: dispatcherOption?.value,
				label: dispatcherOption?.label,
			})
		}
	}, [dispatcher])

	useEffect(() => {
		const fetchDispatchers = async () => {
			try {
				const results = await getSelectDispatchers()
				const options = results.map(d => ({
					value: d.user._id,
					label: d.user.name,
				}))
				setOptions(options)
			} catch (error) {
				console.error(error)
			}
		}

		fetchDispatchers()
	}, [])

	const handleChange = async (newValue, acMeta) => {
		const newDispatcherId =
			acMeta.action === 'select-option' ? newValue?.value : null

		setSelected(newValue)

		onChange(newDispatcherId, newValue?.label)
	}

	return (
		<div className="">
			<Select
				key={loadId}
				placeholder="Select dispatcher..."
				isSearchable
				value={selected}
				options={options}
				onChange={handleChange}
				components={{
					IndicatorSeparator: () => null,
				}}
				styles={{
					menu: provided => ({ ...provided, zIndex: 9999 }),
					control: provided => ({
						...provided,
						minHeight: '30px',
						height: '30px',
					}),
					valueContainer: provided => ({
						...provided,
						height: '30px',
						padding: '0 6px',
					}),
					input: provided => ({ ...provided, margin: '0px' }),
					singleValue: provided => ({
						...provided,
						fontSize: '10px',
						marginBottom: '8px',
					}),
					option: (provided, state) => ({
						...provided,
						fontSize: '0.65rem',
						padding: '8px 12px',
						backgroundColor: state.isSelected ? 'blue' : 'white',
						color: state.isSelected ? 'white' : 'black',
						':active': {
							...provided[':active'],
							backgroundColor:
								!state.isDisabled && (state.isSelected ? 'blue' : 'lightgray'),
						},
					}),
				}}
			/>
		</div>
	)
}

export default SelectDispatcher
