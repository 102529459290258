import React, { useState } from 'react'
// import { DriverStatus } from '../common/SelectGeneric'
import { createDriver } from '../services/httpService'

export default function CreateDriver(props) {
	const [state, setState] = useState({
		firstName: '',
		lastName: '',
		notes: [],
		loads: [],
		status: 'REST',
		isActive: true,
	})

	const changeHandler = e => {
		const { name, value } = e.target
		if (name === 'notes') {
			setState({
				...state,
				notes: value.split(),
			})
		} else if (name === 'loads') {
			setState({
				...state,
				loads: value.split(),
			})
		} else {
			setState({
				...state,
				[name]: value,
			})
		}
	}

	const submit = async e => {
		e.preventDefault()

		if (state.firstName === '' || state.lastName === '') {
			return alert('Please fill in the fields!')
		}
		const res = await createDriver(state)
		if (res.status === 200) {
			props.setOpen(false)
			props.getUpdatedData && props.getUpdatedData()
			alert('Created driver!')
		}
	}

	return (
		<div>
			<div className="space-y-6 overflow-auto">
				<form onSubmit={submit}>
					<div className="bg-white shadow px-4 py-2 sm:rounded-lg sm:p-1">
						<div className="grid grid-cols-4 gap-2">
							<div className="col-span-3 sm:col-span-3 lg:col-span-2">
								<label
									htmlFor="broker"
									className="block text-sm font-medium text-gray-700"
								>
									First Name
								</label>
								<input
									required={true}
									placeholder="First Name"
									type="text"
									name="firstName"
									onChange={changeHandler}
									id="firstName"
									autoComplete="firstName"
									className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
								/>
							</div>
							<div className="col-span-3 sm:col-span-3 lg:col-span-2">
								<label
									htmlFor="broker"
									className="block text-sm font-medium text-gray-700"
								>
									Last Name
								</label>
								<input
									required={true}
									placeholder="Last Name"
									type="text"
									name="lastName"
									onChange={changeHandler}
									id="lastName"
									autoComplete="lastName"
									className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
								/>
							</div>
							{/* <div className="col-span-3 sm:col-span-3 lg:col-span-2">
								<label
									htmlFor="broker"
									className="block text-sm font-medium text-gray-700"
								>
									Status
								</label>
								<DriverStatus
									value={state.status}
									name="status"
									onChange={changeHandler}
								/>
							</div> */}
							{/* <div className="col-span-3 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="broker"
                  className="block text-sm font-medium text-gray-700"
                >
                  Notes
                </label>
                <input
                  required={true}
                  placeholder="Notes"
                  type="text"
                  name="notes"
                  onChange={changeHandler}
                  id="broker"
                  autoComplete="postal-code"
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                />
              </div> */}
							{/* <div className="col-span-3 sm:col-span-3 lg:col-span-2">
								<label
									htmlFor="broker"
									className="block text-sm font-medium text-gray-700"
								>
									Loads
								</label>
								<input
									required={true}
									placeholder="Loads"
									type="text"
									name="loads"
									onChange={changeHandler}
									id="loads"
									autoComplete="postal-code"
									className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
								/>
							</div> */}
						</div>
					</div>
					<div className="flex justify-end m-1 -pb-2">
						<button
							type="button"
							className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							onClick={() => props.setOpen(false)}
						>
							Cancel
						</button>
						<button
							// onClick={invite}
							type="submit"
							className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
						>
							Send
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}
