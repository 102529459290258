import React, { useRef } from 'react'
import Transition from './statementUtils'

function ModalBasic({
	children,
	id,
	modalOpen,
	setModalOpen,
	setShowInput,
	setSelectedStatement,
	setNetAmount,
	setAdjustmentAmount,
}) {
	const modalContent = useRef(null)

	return (
		<>
			{/* Modal backdrop */}
			<Transition
				className="fixed inset-0 bg-gray-900 bg-opacity-30 z-50 transition-opacity"
				show={modalOpen}
				enter="transition ease-out duration-200"
				enterStart="opacity-0"
				enterEnd="opacity-100"
				leave="transition ease-out duration-200"
				leaveStart="opacity-100"
				leaveEnd="opacity-0"
				aria-hidden="true"
			/>
			{/* Modal dialog */}
			<Transition
				id={id}
				className="fixed inset-0 z-50 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6"
				role="dialog"
				aria-modal="true"
				show={modalOpen}
				enter="transition ease-in-out duration-200"
				enterStart="opacity-0 translate-y-4"
				enterEnd="opacity-100 translate-y-0"
				leave="transition ease-in-out duration-200"
				leaveStart="opacity-100 translate-y-0"
				leaveEnd="opacity-0 translate-y-4"
			>
				<div
					ref={modalContent}
					className="bg-white rounded shadow-lg overflow-auto w-3/5 max-h-full"
				>
					{/* Modal header */}
					<div className="px-5 py-3 border-b border-gray-200">
						<div className="flex justify-end items-right">
							<button
								className="text-gray-400 hover:text-gray-500"
								onClick={e => {
									e.stopPropagation()
									setModalOpen(false)
									setShowInput(false)
									setNetAmount(0)
									setAdjustmentAmount(0)
									setSelectedStatement(null)
								}}
							>
								<div className="font-bold sr-only">Close</div>
								<svg className="w-4 h-4 fill-current">
									<path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
								</svg>
							</button>
						</div>
					</div>
					{children}
				</div>
			</Transition>
		</>
	)
}

export default ModalBasic
