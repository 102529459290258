import React, { useState, useEffect } from 'react'
import moment from 'moment'
import toast from 'react-hot-toast'

import { getInvitations } from '../services/httpService'
import { removeUser, getCurrentUser } from '../services/authService'

import Modal from '../common/Modal'
import InviteUser from './InviteUser'
import Skeleton from '../common/Skeleton'

export default function Users() {
	const userId = localStorage.getItem('userId')
	const [invites, setInvites] = useState([])
	const [loading, setLoading] = useState(false)
	const [inviteUserOpen, setInviteUserOpen] = useState(false)

	const { isAdmin } = getCurrentUser()

	const toaster = res => {
		if (res === 'success') {
			toast.success('Email sent!')
		} else {
			toast.error('Something went wrong!')
		}
	}

	const handleRemoveUser = async dispatcherEmail => {
		const res = await removeUser(dispatcherEmail)

		if (res.success) {
			toast.success('Email sent!')
			getInvitesData()
			getInvitesData()
		} else {
			toast.error('Something went wrong!')
		}
	}

	useEffect(() => {
		async function getInvitesData() {
			setLoading(true)
			const invites = await getInvitations()

			setInvites(invites?.data)
			setLoading(false)
		}

		getInvitesData()
	}, [userId])

	async function getInvitesData() {
		setLoading(true)
		const invites = await getInvitations(userId)

		setInvites(invites?.data)
		setLoading(false)
	}

	return (
		<>
			{loading ? (
				<div className="w-full mx-auto h-full flex items-center justify-center mt-14 p-10">
					<Skeleton />
				</div>
			) : (
				<div
					className="flex flex-col items-left
        flex flex-col w-full my-4 items-left m-auto
        "
					style={{ width: '1000px' }}
				>
					<Modal open={inviteUserOpen} setOpen={setInviteUserOpen}>
						<InviteUser
							setOpen={setInviteUserOpen}
							getUpdatedData={() => getInvitesData()}
							toaster={toaster}
						/>
					</Modal>

					{isAdmin && (
						<button
							type="button"
							className="relative px-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 mb-2"
							style={{ width: '200px' }}
							onClick={() => setInviteUserOpen(true)}
						>
							invite new dispatcher
						</button>
					)}
					<div className="mt-8">
						<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
							<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
								<div>
									{invites && invites.length ? (
										<table className="min-w-full divide-y divide-gray-200 p-5">
											<thead className="bg-gray-50">
												<tr>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
													>
														Name
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
													>
														Status
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
													>
														Date
													</th>
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
													>
														Action
													</th>
												</tr>
											</thead>
											<tbody>
												{invites?.map((person, personIdx) => (
													<tr
														key={person._id}
														className={
															personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'
														}
													>
														<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
															{person.reciever}
														</td>
														<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
															{person.status}
														</td>

														<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
															{moment(person.updatedAt).fromNow()}
														</td>
														<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
															{isAdmin && (
																<button
																	onClick={() =>
																		handleRemoveUser(person.reciever)
																	}
																	type="button"
																	className="relative inline-flex items-center px-1 py-2 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
																>
																	Remove user
																</button>
															)}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									) : (
										<div className="container text-center flex items-center justify-center my-5 text-3xl">
											<h2 className="text-center text-dark-700 font-bold my-10 sticky-top">
												No users found
											</h2>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
