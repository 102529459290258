import React, { useState, useEffect } from 'react'
import SelectTruck from '../SelectTruck'
import SelectDispatcher from './SelectDispatcher'
import SelectTeam from './SelectTeamDriver'
import axios from 'axios'

import toast from 'react-hot-toast'

import { truncateString } from '../../../utils/utils'

const EditableDriverInfo = ({ driver, isEditMode, onEditToggle, onSave }) => {
	const [contact, setContact] = useState('')
	const [hometown, setHometown] = useState('')

	useEffect(() => {
		if (isEditMode) onEditToggle()
		setContact(driver.phoneNumber)
		setHometown(driver.hometown)
	}, [driver])

	const formatPhoneNumber = value => {
		const cleaned = ('' + value).replace(/\D/g, '')
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
		if (match) {
			return `${match[1]}-${match[2]}-${match[3]}`
		}
		return value
	}

	const handleContactChange = e => {
		setContact(formatPhoneNumber(e.target.value))
	}

	const handleSave = async () => {
		try {
			const response = await axios.patch(
				`/api/drivers/${driver._id}/contact-hometown`,
				{
					phoneNumber: contact,
					hometown: hometown,
				}
			)
			const updatedDriver = response.data
			onSave(updatedDriver)
			onEditToggle()
			toast.success('Driver updated successfully')
		} catch (error) {
			console.error('Error updating driver:', error)
			toast.error('Error updating driver')
		}
	}

	const handleCancel = () => {
		setContact(driver.phoneNumber)
		setHometown(driver.hometown)
		onEditToggle()
	}

	const teamDriver = driver?.teamDriver.firstName
		? `${driver?.teamDriver.firstName} ${driver?.teamDriver.lastName}`
		: null

	return (
		<div className="grid grid-cols-5 gap-3 items-start">
			<div className="col-span-1">
				<label className="text-sm text-gray-600 block">Truck#</label>
				{isEditMode ? (
					<SelectTruck selectedDriver={driver} driverId={driver?._id} />
				) : (
					<div className="font-semibold text-gray-700">
						{driver?.truck?.number || (
							<span className="underline underline-offset-4 italic font-medium underline-offset-1">
								Not provided
							</span>
						)}
					</div>
				)}
			</div>

			<div className="col-span-1">
				<label className="text-sm text-gray-600 block">Contact</label>
				{isEditMode ? (
					<input
						type="text"
						placeholder="Phone number"
						value={contact}
						onChange={handleContactChange}
						className="input input-bordered input-sm w-full max-w-xs"
					/>
				) : (
					<div className="font-semibold text-gray-700">
						{contact || (
							<span className="underline underline-offset-4 italic font-medium underline-offset-1">
								Not provided
							</span>
						)}
					</div>
				)}
			</div>

			<div className="col-span-1">
				<label className="text-sm text-gray-600 block">Hometown</label>
				{isEditMode ? (
					<input
						type="text"
						placeholder="City and state"
						className="input input-bordered input-sm w-full max-w-xs"
						value={hometown}
						onChange={e => setHometown(e.target.value)}
					/>
				) : (
					<div className="font-semibold text-gray-700">
						{hometown || (
							<span className="underline underline-offset-4 italic font-medium underline-offset-1">
								Not provided
							</span>
						)}
					</div>
				)}
			</div>

			<div className="col-span-1">
				<label className="text-sm text-gray-600 block">Team Driver</label>
				{isEditMode ? (
					<SelectTeam driver={driver} driverId={driver?._id} />
				) : (
					<div className="font-semibold text-gray-700">
						{teamDriver ? (
							truncateString(teamDriver, 15)
						) : (
							<span className="underline underline-offset-4 italic font-medium underline-offset-1">
								Not provided
							</span>
						)}
					</div>
				)}
			</div>

			<div className="col-span-1">
				<label className="text-sm text-gray-600 block">Dispatcher</label>
				{isEditMode ? (
					<SelectDispatcher selectedDriver={driver} driverId={driver?._id} />
				) : (
					<div className="font-semibold text-gray-700">
						{driver?.dispatcherInfo?.name || (
							<span className="underline underline-offset-4 italic font-medium underline-offset-1">
								Not provided
							</span>
						)}
					</div>
				)}
			</div>

			<div className="col-span-1 flex justify-start gap-3">
				{isEditMode && (
					<button
						onClick={handleCancel}
						className="rounded-md bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					>
						Cancel
					</button>
				)}

				<button
					onClick={isEditMode ? handleSave : onEditToggle}
					className={`rounded-md px-5 py-1 text-sm font-semibold shadow-sm ${
						isEditMode
							? 'bg-green-500 hover:bg-green-600 text-white'
							: 'bg-indigo-100 hover:bg-indigo-100 text-indigo-600'
					}`}
				>
					{isEditMode ? 'Save' : 'Edit'}
				</button>
			</div>
		</div>
	)
}

export default EditableDriverInfo
