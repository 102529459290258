import { Link } from "react-router-dom";

import LandingPage from "./LandingPage";

export default function LandingPageIndex() {
  return (
    <LandingPage />
    // <>
    //   <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 px-6 md:space-x-10 mx-6">
    //     <div className="flex justify-start w-0 flex-1">
    //       <Link exact="true" to="/dashboard">
    //         <div className="flex center justify-center align-center gap-2 py-2 items-center">
    //           <h1 className="whitespace-nowrap text-lg font-extrabold text-gray-700 hover:text-gray-900">
    //             Rapid Board
    //           </h1>
    //         </div>
    //       </Link>
    //       <div className="flex items-center justify-end flex-1 lg:w-0">
    //         <Link
    //           exact="true"
    //           to="/login"
    //           className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
    //         >
    //           Sign in
    //         </Link>
    //         <Link
    //           exact="true"
    //           to="/sign-up"
    //           className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
    //         >
    //           Sign up
    //         </Link>
    //       </div>
    //     </div>
    //   </div>
    //   <h1 className="whitespace-nowrap text-base font-bold text-gray-700 hover:text-gray-900 text-center mt-6 py-6 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
    //     Welcome To Rapid Board.
    //   </h1>
    // </>
  );
}
