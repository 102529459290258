import Navbar from '../NavigationBar/LoggedOutNavbar'
import React, { useState } from 'react'
import { register } from '../../services/authService'
import { Link } from 'react-router-dom'

export default function Signup() {
	const [error, setError] = useState(null)
	const [state, setState] = useState({
		name: '',
		email: '',
		password: '',
		password2: '',
		companyName: '',
	})

	const modal = document.getElementById('modalForSignupError')
	const showError = () => {
		modal.style.display = 'block'
		return setTimeout(() => {
			modal.style.display = 'none'
		}, 3000)
	}

	const waitFunc = async () => {
		return await register(state)
	}

	const submitHandler = async e => {
		e.preventDefault()
		if (state.password !== state.password2) {
			setError("Passwords don't match!")
			return showError()
		}
		if (state.companyName === '') {
			setError('Enter your Company Name!')
			return showError()
		}
		if (state.name !== '' && state.email !== '' && state.password !== '') {
			if (state.password.length < 6) {
				modal.style.display = 'block'
				setError('Password must be more than 6 characters!')
				return showError()
			}
			waitFunc()
		}
	}

	const onChange = e => {
		e.preventDefault()
		const { name, value } = e.target
		setState({ ...state, [name]: value })
	}

	return (
		<>
			<Navbar />
			<div className="max-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<h2 className="mt-1 text-center text-3xl font-extrabold text-gray-900">
						Sign up for an account
					</h2>
				</div>
				<div
					className="text-center text-red text-danger"
					id="modalForSignupError"
					style={{ display: 'none' }}
				>
					{error}
				</div>
				<div id="successfullyCreatedModal" style={{ display: 'none' }}>
					Created Your Account!
				</div>
				<div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<form className="space-y-6" onSubmit={submitHandler}>
							<div>
								<label
									htmlFor="companyName"
									className="block text-sm font-medium text-gray-700"
								>
									Company Name
								</label>
								<input
									onChange={onChange}
									id="companyName"
									name="companyName"
									type="companyName"
									autoComplete="companyName"
									required={true}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div>
								<label
									htmlFor="companyName"
									className="block text-sm font-medium text-gray-700"
								>
									Name
								</label>
								<input
									onChange={onChange}
									id="name"
									name="name"
									type="name"
									autoComplete="name"
									required={true}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
							<div className="-mt-5">
								<label
									htmlFor="email"
									className="block text-sm font-medium text-gray-700"
								>
									Email
								</label>
								<input
									onChange={onChange}
									id="email"
									name="email"
									type="email"
									autoComplete="email"
									required={true}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>

							<div>
								<label
									htmlFor="password"
									className="block text-sm font-medium text-gray-700"
								>
									Password
								</label>
								<input
									onChange={onChange}
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required={true}
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
								/>
							</div>
							<div>
								<label
									htmlFor="password"
									className="block text-sm font-medium text-gray-700"
								>
									Retype Your Password
								</label>
								<div className="">
									<input
										onChange={onChange}
										id="password2"
										name="password2"
										type="password"
										autoComplete="current-password"
										required={true}
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
									/>
								</div>
							</div>

							<div>
								<button
									type="submit"
									className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									Sign Up
								</button>
							</div>
						</form>
						<div className="flex center justify-center align-center gap-2 py-2 items-center">
							<div>Already Have an Account ?</div>
							<Link to="/login" exact="true">
								<button className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
									Sign In
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
