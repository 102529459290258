import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import { getDrivers, updateLoadDriverId } from '../../../services/httpService'
import toast from 'react-hot-toast'

const SelectDriver = ({ load, drivers }) => {
	const [options, setOptions] = useState([])
	const [selected, setSelected] = useState(null)

	const driver = load?.drivers[0]

	useEffect(() => {
		const initialSelector = () => {
			const optionsList = drivers?.length
				? drivers?.map(d => ({
						value: d._id,
						label: `${d?.firstName} ${d?.lastName}`,
				  }))
				: [{ label: '', value: '' }]

			setOptions(optionsList)
			setSelected(optionsList[0])
		}

		initialSelector()
	}, [])

	const getOptionsAsync = newInput => {
		getDrivers({ input: newInput })
			.then(results => {
				const options = results.map(d => ({
					value: d._id,
					label: `${d.firstName} ${d.lastName}`,
				}))

				setOptions(options ? options : [])
			})
			.catch(error => {
				console.error(error)
			})
	}

	const handleChange = async (newValue, acMeta) => {
		try {
			const newDriverId = newValue?.value

			const oldDriverId = driver?._id
			const loadId = load?._id

			await updateLoadDriverId(loadId, newDriverId, oldDriverId)

			setSelected(newValue)
			toast.success(`Driver updated`)
		} catch (error) {
			console.log(error)
			toast.error(`Error updating driver`)
		}
	}

	const loadId = load?._id

	return (
		<div className="w-36">
			<Select
				key={loadId}
				cacheOptions
				defaultOptions
				isSearchable
				defaultValue={selected}
				value={selected}
				options={options}
				onChange={handleChange}
				onFocus={getOptionsAsync}
				components={{
					IndicatorSeparator: () => null,
				}}
				styles={{
					option: provided => ({
						...provided,
					}),
					menu: provided => ({ ...provided, zIndex: 9999 }),
					control: provided => ({
						...provided,
						minHeight: '30px',
						height: '30px',
					}),
					valueContainer: provided => ({
						...provided,
						height: '30px',
						padding: '0 6px',
					}),
					input: provided => ({ ...provided, margin: '0px' }),
					singleValue: provided => ({
						...provided,
						fontSize: '10px',
						marginBottom: '6px',
					}),
				}}
			/>
		</div>
	)
}

export default SelectDriver
