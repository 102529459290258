const states = [
	['Alabama', 'AL'],
	['Alaska', 'AK'],
	['Arizona', 'AZ'],
	['Arkansas', 'AR'],
	['California', 'CA'],
	['Colorado', 'CO'],
	['Connecticut', 'CT'],
	['Delaware', 'DE'],
	['District Of Columbia', 'DC'],
	['Florida', 'FL'],
	['Georgia', 'GA'],
	['Hawaii', 'HI'],
	['Idaho', 'ID'],
	['Illinois', 'IL'],
	['Indiana', 'IN'],
	['Iowa', 'IA'],
	['Kansas', 'KS'],
	['Kentucky', 'KY'],
	['Louisiana', 'LA'],
	['Maine', 'ME'],
	['Maryland', 'MD'],
	['Massachusetts', 'MA'],
	['Michigan', 'MI'],
	['Minnesota', 'MN'],
	['Mississippi', 'MS'],
	['Missouri', 'MO'],
	['Montana', 'MT'],
	['Nebraska', 'NE'],
	['Nevada', 'NV'],
	['New Hampshire', 'NH'],
	['New Jersey', 'NJ'],
	['New Mexico', 'NM'],
	['New York', 'NY'],
	['North Carolina', 'NC'],
	['North Dakota', 'ND'],
	['Ohio', 'OH'],
	['Oklahoma', 'OK'],
	['Oregon', 'OR'],
	['Pennsylvania', 'PA'],
	['Rhode Island', 'RI'],
	['South Carolina', 'SC'],
	['South Dakota', 'SD'],
	['Tennessee', 'TN'],
	['Texas', 'TX'],
	['Utah', 'UT'],
	['Vermont', 'VT'],
	['Virginia', 'VA'],
	['Washington', 'WA'],
	['West Virginia', 'WV'],
	['Wisconsin', 'WI'],
	['Wyoming', 'WY'],
]

function convertState(state) {
	const selectedState = states?.find((s, index) => {
		return s?.find(
			x => x?.toLowerCase()?.trim() === state?.toLowerCase()?.trim()
		)
	})
	if (!selectedState) return null

	return selectedState[1]
}

const capitalizeString = city => {
	if (!city) return city

	return city
		?.toLowerCase()
		?.split(' ')
		?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
		?.join(' ')
}

const equipments = [
	['DRY VAN', 'Dry Van'],
	['FLATBED', 'Flatbed'],
	['REEFER', 'Reefer'],
	['POWER ONLY', 'Power Only'],
	['STEP DECK', 'Step Deck'],
]

module.exports = { convertState, states, capitalizeString, equipments }
