import React, { useState, useEffect } from 'react'

import { CurrencyDollarIcon, GlobeIcon } from '@heroicons/react/outline'
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'

const capitalize = string => {
	if (!string || !string.length) return ''

	return string
		.toLowerCase()
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
}

const stats = [
	{
		id: 1,
		name: 'Total Booked',
		objName: 'totalBooked',
		icon: CurrencyDollarIcon,
		change: '122',
		changeType: 'increase',
	},
	{
		id: 2,
		name: 'Total Miles',
		objName: 'totalMiles',
		icon: GlobeIcon,
		change: '5.4%',
		changeType: 'increase',
	},
	{
		id: 3,
		name: 'Per Mile',
		objName: 'pricePerMile',
		icon: CurrencyDollarIcon,
		change: '3.2%',
		changeType: 'decrease',
	},
]

function classNames(...classes) {
	return classes?.filter(Boolean).join(' ')
}

function numberWithCommas(number, item) {
	const result = number?.toFixed(item?.objName !== 'totalMiles' ? 2 : 0)

	return result?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export default function DashboardCards({ data, period }) {
	const [userStats, setUserStats] = useState({
		currWeek: { totalBooked: 0, totalMiles: 0, pricePerMile: 0 },
		prevWeek: { totalBooked: 0, totalMiles: 0, pricePerMile: 0 },
		priceChange: { totalBooked: 0, totalMiles: 0, pricePerMile: 0 },
	})

	useEffect(() => {
		if (data) {
			setUserStats(data)
		}
	}, [data])

	return (
		<>
			<dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 p-6 pb-2">
				{stats?.length &&
					stats?.map(item => (
						<div
							key={item.id}
							className="relative bg-white pt-5 px-4 pb-6 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
						>
							<dt className="flex justify-between">
								<div>
									<div className="absolute bg-blue-600 rounded-md p-3">
										<item.icon
											className="h-5 w-5 text-white"
											aria-hidden="true"
										/>
									</div>
									<p className="ml-16 text-sm font-medium text-gray-500 truncate">
										{item.name}
									</p>
								</div>
								<div>
									<p className="ml-6 text-sm font-medium text-gray-500 truncate">
										Previous {capitalize(period)}
									</p>
									<div
										className={classNames(
											userStats?.priceChange?.[item.objName] > 0
												? 'text-green-600'
												: 'text-red-600',
											'ml-2 flex items-baseline text-2xl font-semibold'
										)}
									>
										<div className="ml-2  flex items-start tracking-wide2">
											{userStats?.priceChange?.[item.objName] > 0 ? (
												<ArrowSmUpIcon
													className="self-center flex-shrink-0 h-5 w-5 text-green-500"
													aria-hidden="true"
												/>
											) : (
												<ArrowSmDownIcon
													className="self-center flex-shrink-0 h-5 w-5 text-red-500"
													aria-hidden="true"
												/>
											)}
											<p className="text-xl">
												<span className="sr-only">
													{userStats?.priceChange?.[item.objName] > 0
														? 'Increased'
														: 'Decreased'}
													by
												</span>
												%
												{Math.abs(
													Math.floor(userStats?.priceChange?.[item.objName])
												)}
											</p>
										</div>
									</div>
								</div>
							</dt>
							<dd className="ml-16  flex items-start">
								<p className="text-xl font-semibold text-gray-600 -mt-7">
									{item && item?.objName !== 'totalMiles' && '$'}
									{numberWithCommas(
										userStats?.currWeek[item && item?.objName],
										item
									)}
								</p>
							</dd>
						</div>
					))}
			</dl>
		</>
	)
}
