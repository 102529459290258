import React, { useState, Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
	ChevronDownIcon,
	OfficeBuildingIcon,
	PlusCircleIcon,
} from '@heroicons/react/solid'

import { getCurrentUser } from '../../services/authService'
import Modal from '../../common/Modal'
import CreateBusinessModal from './CreateBusinessModal'

const classNames = (...classes) => {
	return classes?.filter(Boolean).join(' ')
}

const setCompany = companies => {
	if (!companies || companies?.length === 0) return null

	let defaultCompany = null
	try {
		const storedCompany = localStorage?.getItem('defaultCompany')
		if (storedCompany) {
			defaultCompany = JSON.parse(storedCompany)
		}
	} catch (err) {
		console.error('Error parsing defaultCompany from localStorage:', err)
	}

	if (!defaultCompany) return companies[0]

	return defaultCompany
}

export default function CompanyDropdown() {
	const [modalOpen, setModalOpen] = useState(false)
	const user = getCurrentUser()

	const companies = user?.companies

	const company = setCompany(companies)

	const filteredCompanies = companies?.filter(cpy => {
		return company?._id !== cpy?._id
	})

	const handleClick = e => {
		e.preventDefault()

		setModalOpen(true)
	}

	const selectCompany = e => {
		e.preventDefault()
		const { id } = e.target

		const selectedCompany = companies.find(company => company._id == id)

		localStorage.removeItem('defaultCompany')
		localStorage.setItem('defaultCompany', JSON.stringify(selectedCompany))

		window.location.reload(true)
	}

	return (
		<>
			<Menu as="div" className="relative inline-block text-right z-50 mr-8">
				<div className="py-1">
					<Menu.Button className="inline-flex justify-center w-full text-white font-extrabold px-4 py-2  text-sm">
						{company?.name}
						<ChevronDownIcon
							className="-mr-1 pb-1 ml-1 h-6 w-6"
							aria-hidden="true"
						/>
					</Menu.Button>
				</div>

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
						<div className="py-1">
							{user?.isAdmin && (
								<Menu.Item>
									{({ active }) => (
										<a
											onClick={handleClick}
											href="#"
											className={classNames(
												active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
												'group flex items-center px-4 py-2 text-md font-bold'
											)}
										>
											<PlusCircleIcon
												className="mr-3 h-5 w-5 text-gray-900 group-hover:text-gray-500"
												aria-hidden="true"
											/>
											Add New Business
										</a>
									)}
								</Menu.Item>
							)}

							<Menu.Item>
								{({ active }) => (
									<>
										{filteredCompanies?.length > 0 &&
											filteredCompanies.map(company => {
												return (
													<a
														href="#"
														key={company._id}
														id={company._id}
														onClick={selectCompany}
														className={classNames(
															active
																? 'bg-gray-100 text-gray-900'
																: 'text-gray-700',
															'group flex items-center px-4 py-2 text-md'
														)}
													>
														<OfficeBuildingIcon
															className="mr-3 h-5 w-5 text-gray-700 group-hover:text-gray-500"
															aria-hidden="true"
														/>

														{company?.name}
													</a>
												)
											})}
									</>
								)}
							</Menu.Item>
						</div>
					</Menu.Items>
				</Transition>
			</Menu>

			<Modal open={modalOpen} setOpen={setModalOpen}>
				<CreateBusinessModal setOpen={setModalOpen} />
			</Modal>
		</>
	)
}
