import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import toast from 'react-hot-toast'

import { getDrivers, updateTeamDriver } from '../../../services/httpService'

const SelectDriver = ({ driver, loadId, driverId }) => {
	const [options, setOptions] = useState([])
	const [selected, setSelected] = useState(null)

	const d = driver?.teamDriver || {}

	useEffect(() => {
		const initialSelector = () => {
			const optionsList = driver?.teamDriver.firstName
				? [
						{
							value: d._id,
							label: `${d?.firstName} ${d?.lastName}`,
						},
				  ]
				: [{ label: '', value: '' }]

			setOptions(optionsList)
			setSelected(optionsList[0])
		}

		initialSelector()
	}, [])

	const getOptionsAsync = newInput => {
		getDrivers({ input: newInput })
			.then(results => {
				const options = results.map(d => ({
					value: d._id,
					label: `${d.firstName} ${d.lastName}`,
				}))

				setOptions(options ? options : [])
			})
			.catch(error => {
				console.error(error)
			})
	}

	const handleChange = async (newValue, acMeta) => {
		try {
			const newDriverId =
				acMeta.action === 'select-option' ? newValue?.value : null

			const oldDriverId = driverId

			const response = await updateTeamDriver(newDriverId, oldDriverId)

			if (response && response.success) {
				setSelected(newValue)
				toast.success('Driver updated successfully')
			} else {
				toast.error('Driver update failed')
			}
		} catch (error) {
			console.error(error)
			toast.error(`Error updating driver: ${error.message || 'Unknown error'}`)
		}
	}

	return (
		<div className="w-36">
			<Select
				key={loadId}
				cacheOptions
				defaultOptions
				isSearchable
				defaultValue={selected}
				value={selected}
				options={options}
				onChange={handleChange}
				onFocus={getOptionsAsync}
				components={{
					IndicatorSeparator: () => null,
				}}
				styles={{
					menu: provided => ({ ...provided, zIndex: 9999 }),
					control: provided => ({
						...provided,
						minHeight: '30px',
						height: '30px',
					}),
					valueContainer: provided => ({
						...provided,
						height: '30px',
						padding: '0 6px',
					}),
					input: provided => ({ ...provided, margin: '0px' }),
					singleValue: provided => ({
						...provided,
						fontSize: '10px',
						marginBottom: '8px',
					}),
					option: (provided, state) => ({
						...provided,
						fontSize: '0.65rem',
						padding: '8px 12px',
						backgroundColor: state.isSelected ? 'blue' : 'white',
						color: state.isSelected ? 'white' : 'black',
						':active': {
							...provided[':active'],
							backgroundColor:
								!state.isDisabled && (state.isSelected ? 'blue' : 'lightgray'),
						},
					}),
				}}
			/>
		</div>
	)
}

export default SelectDriver
