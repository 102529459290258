import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import toast from 'react-hot-toast'

import {
	getSelectDispatchers,
	updateLoadDispatcher,
} from '../../../services/httpService'

const SelectDispatcher = ({ load }) => {
	const [options, setOptions] = useState([])
	const [selected, setSelected] = useState(null)

	const dispatcher = load?.dispatcher

	useEffect(() => {
		const initialSelector = () => {
			const optionsList = dispatcher?.name
				? [
						{
							value: dispatcher._id,
							label: `${dispatcher?.name}`,
						},
				  ]
				: [{ label: '', value: '' }]

			setOptions(optionsList)
			setSelected(optionsList[0])
		}

		initialSelector()
	}, [])

	const getOptionsAsync = newInput => {
		getSelectDispatchers({ input: newInput })
			.then(results => {
				const options = results.map(d => ({
					value: d.user._id,
					label: `${d.user.name}`,
				}))

				setOptions(options ? options : [])
			})
			.catch(error => {
				console.error(error)
			})
	}

	const handleChange = async (newValue, acMeta) => {
		try {
			toast.success(`Dispatcher updated`)

			const newDispatcherId =
				acMeta.action === 'select-option' ? newValue?.value : null

			const loadId = load?._id //props?.row?.original?._id

			await updateLoadDispatcher(loadId, newDispatcherId)

			setSelected(newValue)
		} catch (error) {
			console.log(error)
			toast.error(`Error updating dispatcher`)
		}
	}

	return (
		<div className="w-36">
			<Select
				// key={loadId}
				cacheOptions
				defaultOptions
				isSearchable
				defaultValue={selected}
				value={selected}
				options={options}
				onChange={handleChange}
				onFocus={getOptionsAsync}
				components={{
					IndicatorSeparator: () => null,
				}}
				styles={{
					option: provided => ({
						...provided,
					}),
					menu: provided => ({ ...provided, zIndex: 9999 }),
					control: provided => ({
						...provided,
						minHeight: '30px',
						height: '30px',
					}),
					valueContainer: provided => ({
						...provided,
						height: '30px',
						padding: '0 6px',
					}),
					input: provided => ({ ...provided, margin: '0px' }),
					singleValue: provided => ({
						...provided,
						fontSize: '10px',
						marginBottom: '6px',
					}),
				}}
			/>
		</div>
	)
}

export default SelectDispatcher
