import moment from 'moment'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import DatePicker from 'react-datepicker'
import toast from 'react-hot-toast'

export default function DateScroll({
	handleStartDate,
	handleEndDate,
	filters,
	incrementWeek,
	decrementWeek,
}) {
	const _handleStartDate = date => {
		if (moment(date).isAfter(filters.endDate)) {
			return toast.error('Start date cannot be greater than end date')
		}
		handleStartDate(date)
	}

	const _handleEndDate = date => {
		if (moment(date).isBefore(filters.startDate)) {
			return toast.error('End date cannot be less than start date')
		}
		handleEndDate(date)
	}

	return (
		<div className="flex border rounded-md shadow-sm bg-white">
			<button
				style={{ cursor: !filters?.startDate && 'not-allowed' }}
				onClick={filters?.startDate && decrementWeek}
				className="flex items-center justify-center p-2 border-r hover:bg-gray-200 focus:outline-none focus:bg-gray-300 transition"
				aria-label="Previous"
			>
				<ChevronLeftIcon className="h-5 w-5 text-gray-600" />
			</button>

			<div id="loadDatesWrapper">
				{filters.startDate ? (
					<DatePicker
						selected={new Date(filters?.startDate)}
						onSelect={_handleStartDate}
						dateFormat="P"
					/>
				) : (
					<div className="text-sm m-2">Start date</div>
				)}
				<div>-</div>
				{filters?.endDate ? (
					<DatePicker
						selected={new Date(filters?.endDate)}
						onSelect={_handleEndDate}
						dateFormat="P"
					/>
				) : (
					<div className="text-sm m-2">End date</div>
				)}
			</div>

			<button
				style={{ cursor: !filters?.endDate && 'not-allowed' }}
				onClick={filters?.endDate && incrementWeek}
				className="flex items-center justify-center p-2 border-l hover:bg-gray-200 focus:outline-none focus:bg-gray-300 transition"
				aria-label="Next"
			>
				<ChevronRightIcon className="h-5 w-5 text-gray-600" />
			</button>
		</div>
	)
}
