import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import toast from 'react-hot-toast'

import { updateDriverStatus, deleteDriver } from '../../services/httpService'
import { getCurrentUser } from '../../services/authService'

export default function ConfirmDriverAction({
	confirm,
	setConfirm,
	actionType,
	selectedDriver,
	setSelectedDriver,
	drivers,
	setDrivers,
}) {
	const cancelButtonRef = useRef(null)
	const action =
		actionType === 'Deactivate' && selectedDriver?.isActive === false
			? 'Activate'
			: actionType === 'Delete'
			? 'Delete'
			: actionType

	const handleClick = async () => {
		setConfirm(false)
		setSelectedDriver(null)

		if (actionType === 'Deactivate' || actionType === 'Activate') {
			const updatedDriver = await updateDriverStatus(selectedDriver)

			if (updatedDriver?._id) {
				const index = drivers.findIndex(
					driver => driver?._id === updatedDriver?._id
				)
				const copiedDrivers = [...drivers]
				const newDriver = { ...copiedDrivers[index] }
				newDriver.isActive = updatedDriver?.isActive

				copiedDrivers[index] = newDriver
				setDrivers(copiedDrivers)
			}
		} else if (actionType === 'Delete') {
			const user = getCurrentUser()

			if (!user?.isAdmin) return toast.error('Not authorized')

			const res = await deleteDriver(selectedDriver?._id)

			if (res.status !== 200) return toast.error('Error deleting driver')

			const updatedDrivers = drivers.filter(
				driver => driver._id !== selectedDriver._id
			)
			setDrivers(updatedDrivers)
			toast.success('Driver deleted successfully')
		}
	}

	const handleCancel = () => {
		setConfirm(false)
		setSelectedDriver(null)
	}

	return (
		<Transition.Root show={confirm} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				onClose={setConfirm}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
							<div className="sm:flex sm:items-start">
								<div
									className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-${
										action === 'Activate' ? 'yellow' : 'red'
									}-100 sm:mx-0 sm:h-10 sm:w-10`}
								>
									<ExclamationIcon
										className={`h-6 w-6 ${
											action === 'Activate' ? 'text-yellow-400' : 'text-red-600'
										}`}
										aria-hidden="true"
									/>
								</div>
								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
									<Dialog.Title
										as="h3"
										className="text-lg leading-6 font-medium text-gray-900"
									>
										{action}
									</Dialog.Title>
									<div className="mt-2">
										<p className="text-sm text-gray-500">
											Are you sure you want tooo {action}?
										</p>
									</div>
								</div>
							</div>
							<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
								<button
									type="button"
									className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${
										action === 'Activate' ? 'bg-yellow-400' : 'bg-red-600'
									} text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
									onClick={handleClick}
								>
									{action}
								</button>
								<button
									type="button"
									className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
									onClick={handleCancel}
									ref={cancelButtonRef}
								>
									Cancel
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
