import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

import { ChevronDownIcon, XIcon } from '@heroicons/react/outline'

import NotesForm from './Notes/DriverNotesForm'
import DriverNotesList from './Notes/DriverNotesList'
import Spinner from '../../../common/NewSpinner'
import Load from './DriverLoad/Load'
import EditableDriverInfo from './EditableDriverInfo'

import { getCurrentUser } from '../../../services/authService'
import { getLoads } from '../../../services/httpService'

import { useDate } from '../../context/dateContext'

const formatDate = date => {
	return date ? moment(date).format('MM/DD/YYYY') : ''
}

export default function SlideOver({ isOpen, onClose, selectedDriver }) {
	const [notes, setNotes] = useState([])
	const [loading, setLoading] = useState(false)
	const [driverData, setDriverData] = useState([])
	const [totalDistance, setTotalDistance] = useState(0)
	const [loadLoading, setLoadLoading] = useState(false)
	const [showAllLoads, setShowAllLoads] = useState(false)
	const [isEditMode, setIsEditMode] = useState(false)

	const { startDate, endDate, weekNumber } = useDate()

	const [filters] = useState({
		status: null,
		dispatcher: null,
		startDate: moment().startOf('week'),
		endDate: moment().endOf('week'),
	})

	const currentLoad = selectedDriver?.loads[0]
	const driversList = [selectedDriver?._id]

	if (selectedDriver?.teamDriver?._id) {
		driversList.push(selectedDriver?.teamDriver?._id)
	}

	useEffect(() => {
		const fetchLoads = async () => {
			try {
				setLoading(true)
				setLoadLoading(true)
				setShowAllLoads(false)

				setDriverData([])
				setTotalDistance(0)

				const result = await getLoads('', 20, 0, {
					...filters,
					drivers: driversList,
				})

				const driverLoads = result?.loads?.filter(
					load => load?.loadId !== currentLoad?.loadId
				)

				setDriverData({ ...result, loads: driverLoads })
				setTotalDistance(result?.totalDistance || 0)
				setLoadLoading(false)
			} catch (error) {
				console.log('error', error)
				setLoadLoading(false)
			} finally {
				setLoading(false)
				setLoadLoading(false)
			}
		}

		if (selectedDriver?._id) {
			fetchLoads()
			fetchNotes()
		}
	}, [selectedDriver])

	if (!isOpen) return null

	const fetchNotes = async () => {
		setLoading(true)
		try {
			const response = await axios.get(
				`/api/drivers/${selectedDriver?._id}/notes`
			)
			setNotes(response.data)
		} catch (error) {
			console.error('Error fetching notes:', error)
		}
		setLoading(false)
	}

	const addNote = async noteContent => {
		try {
			const user = getCurrentUser()

			await axios.post(`/api/drivers/${selectedDriver?._id}/notes`, {
				content: noteContent,
				createdBy: user?.id,
			})

			fetchNotes()
		} catch (error) {
			console.error(
				'Error creating note:',
				error.response ? error.response.data : error
			)
		}
	}

	const toggleShowAllLoads = () => {
		setShowAllLoads(!showAllLoads)
	}

	const handleEditToggle = () => {
		setIsEditMode(!isEditMode)
	}

	const handleSave = () => {
		setIsEditMode(false)
	}

	const driver = `${selectedDriver?.firstName} ${selectedDriver?.lastName}`
	const teamDriver = selectedDriver?.teamDriver?.firstName
		? ` / ${selectedDriver?.teamDriver?.firstName} ${selectedDriver?.teamDriver?.lastName}`
		: ''

	const totalSum = driverData?.totalSum

	const newStartDate = formatDate(startDate)
	const newEndDate = formatDate(endDate)
	const dateRangeString = `${newStartDate} - ${newEndDate}`

	return (
		<div className="fixed inset-0 overflow-hidden z-50">
			<div className="absolute inset-0 overflow-hidden">
				<div
					className="absolute inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
					onClick={onClose}
				></div>

				<section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
					<div className="w-screen max-w-4xl">
						<div className="h-full relative flex flex-col bg-white shadow-xl">
							<div className="bg-gray-800 flex items-start p-5">
								<button
									onClick={onClose}
									className="mr-8 text-gray-300 hover:text-gray-200"
								>
									<XIcon className="w-6 h-6 mt-1" />
								</button>

								<h4 className="text-gray-100 text-xl font-semibold">
									{driver} {teamDriver ? `  ${teamDriver}` : ''}
								</h4>
							</div>

							{/* Content section */}
							<div className="flex-1 overflow-y-auto p-4 space-y-4">
								<div className="bg-[#f3f4f6] p-4 rounded-md shadow">
									<div className="flex flex-col sm:flex-row sm:items-center sm:justify-start sm:space-y-0 sm:space-x-4">
										<EditableDriverInfo
											driver={selectedDriver}
											isEditMode={isEditMode}
											onEditToggle={handleEditToggle}
											onSave={handleSave}
										/>
									</div>
								</div>

								<div className="bg-[#f3f4f6] p-4 rounded-md shadow">
									<div className="mt-0">
										<div className="flex justify-between items-center mb-2 w-full">
											<div>
												<span className="font-bold text-md  mr-8">
													${totalSum?.toFixed(2) || 0}
												</span>
												<span className="font-bold text-md mr-8">
													{totalDistance} mi
												</span>

												<span className="font-bold text-md mr-8">
													$
													{totalSum && totalDistance
														? (totalSum / totalDistance)?.toFixed(2)
														: 0}
													/mi
												</span>
											</div>

											<div>
												<span className="mr-3 text-md font-bold">
													Week {weekNumber}
												</span>
												<span className="text-md font-bold">
													{dateRangeString}
												</span>
											</div>
										</div>

										<div>
											<span className="font-medium text-gray-600">
												Current Load
											</span>
											{currentLoad ? (
												<div className="mb-0">
													<div className="flex justify-between items-center">
														<Load load={currentLoad} isCurrentLoad={true} />
													</div>
												</div>
											) : (
												<div className="text-xl font-semibold mb-4 text-center p-2 text-sm text-gray-700 bg-gray-100 rounded">
													No Current Load
												</div>
											)}
										</div>

										{loadLoading ? (
											<div className="flex justify-center items-center h-full">
												<Spinner className="w-10 h-10 mt-4 mx-auto my-1" />
											</div>
										) : (
											<div className="mb-1">
												<span className="font-medium text-gray-600">Loads</span>

												<div>
													{driverData?.loads?.slice(0, 2).map(load => (
														<div key={load.id}>
															<div className="flex justify-between items-center">
																<Load load={load} />
															</div>
														</div>
													))}

													{showAllLoads &&
														driverData?.loads?.slice(2).map(load => (
															<div key={load.id} className="">
																<div className="flex justify-between items-center">
																	<Load load={load} />
																</div>
															</div>
														))}
												</div>

												{driverData?.loads?.length > 2 && (
													<div className="flex justify-center mt-1">
														<button
															onClick={toggleShowAllLoads}
															className="flex items-center text-blue-500 hover:text-blue-600"
														>
															<ChevronDownIcon
																className={`${
																	showAllLoads ? 'transform rotate-180' : ''
																} w-4 h-4 mr-1`}
															/>
															{showAllLoads ? 'Show less' : 'Show more'}
														</button>
													</div>
												)}

												{driverData?.loads?.length === 0 && (
													<div className="text-xl font-semibold mb-4 text-center p-2 text-sm text-gray-700 bg-gray-100 rounded">
														No Loads Assigned
													</div>
												)}
											</div>
										)}
									</div>
								</div>

								<div className="bg-[#f3f4f6] p-2 rounded-md shadow">
									<div className="mb-8">
										<NotesForm addNote={addNote} />

										{loading ? (
											<div className="flex justify-center items-center h-full">
												<Spinner className="w-10 h-10 mt-3 mx-auto my-1" />
											</div>
										) : (
											<DriverNotesList notes={notes} />
										)}
									</div>
								</div>
							</div>

							{/* Content section end */}
						</div>
					</div>
				</section>
			</div>
		</div>
	)
}
