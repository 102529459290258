import React from 'react'
import moment from 'moment'

const FilterTag = ({ label, onRemove }) => (
	<div className="bg-blue-600 text-gray-200 inline-flex items-center text-sm rounded mt-0 mb-3 mr-2">
		<span className="ml-2 mr-1 leading-relaxed truncate max-w-xs px-1">
			{label}
		</span>
		<button
			onClick={onRemove}
			className="w-6 h-6 text-gray-100 text-center inline-flex items-center justify-center rounded-full hover:bg-blue-500"
		>
			&times;
		</button>
	</div>
)

const SelectedFilters = ({ filters, removeFilter }) => {
	const filterTags = Object.entries(filters).reduce((acc, [key, value]) => {
		if (
			!value ||
			(Array.isArray(value) && value.length === 0) ||
			key === 'dispatcher' ||
			key === 'driver' ||
			key === 'drivers'
		) {
			return acc
		}

		if (key === 'status' && Array.isArray(value)) {
			return acc
		}

		let label = ''
		if (key === 'dispatcherName') {
			label = `dispatcher: ${value}`
		} else if (key === 'driverName') {
			label = `driver: ${value}`
		} else if (key === 'startDate') {
			label = `start date: ${moment(value).format('MM/DD/YYYY')}`
		} else if (key === 'endDate') {
			label = `end date: ${moment(value).format('MM/DD/YYYY')}`
		} else {
			label = `${key}: ${Array.isArray(value) ? value.join(', ') : value}`
		}

		if (label) {
			acc.push(
				<FilterTag key={key} label={label} onRemove={() => removeFilter(key)} />
			)
		}
		return acc
	}, [])

	return <div className="flex flex-wrap justify-start">{filterTags}</div>
}

export default SelectedFilters
