import React, { useState } from 'react'
import moment from 'moment-timezone'
import { differenceInMinutes, parseISO } from 'date-fns'

import { capitalizeString } from '../../../../services/stateConversion'

import LoadCollapse from './LoadCollapse'

function numberWithCommas(number, item) {
	const result = number?.toFixed(item !== 'miles' ? 2 : 0)

	return result?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function truncateString(str, num) {
	if (!str) return str

	if (str?.length <= num) {
		return str
	}
	return str?.slice(0, num) + '...'
}

function formatDuration(hours, minutes) {
	const days = Math.floor(hours / 24)
	const remainingHours = hours % 24
	if (days > 0) {
		return `${days}d ${remainingHours}h`
	} else if (hours > 0) {
		return `${hours}h ${minutes}m`
	} else {
		return `${minutes}m`
	}
}

function calculateTripDuration(pickupDate, dropoffDate, status) {
	if (status === 'cancelled' || status === 'rejected') {
		return '0h'
	}
	const pickup = parseISO(pickupDate)
	const dropoff = parseISO(dropoffDate)
	const durationInMinutes = differenceInMinutes(dropoff, pickup)
	const hours = Math.floor(durationInMinutes / 60)
	const minutes = durationInMinutes % 60
	return formatDuration(hours, minutes)
}

function LoadCard({ load, isCurrentLoad }) {
	const [isCollapsed, setIsCollapsed] = useState(false)

	const handleCollapseToggle = () => {
		setIsCollapsed(!isCollapsed)
	}

	const {
		loadId,
		broker,
		originCity,
		originState,
		pickupDate,
		deliveryCity,
		deliveryState,
		dropoffDate,
		price,
		distance,
		equipment,
		status,
		stops,
	} = load || {}

	const origin = `${capitalizeString(originCity)}, ${originState}`
	const originTimezone = stops && stops[0]?.timezone
	const pickDate =
		stops && stops[0]?.timezone
			? moment(pickupDate)?.tz(originTimezone)?.format('ddd, MMM DD, HH:mm z')
			: ''

	const destination = `${capitalizeString(deliveryCity)}, ${deliveryState}`
	const destinationTimezone = stops ? stops[stops?.length - 1]?.timezone : ''
	const dropDate =
		stops && stops[0]?.timezone
			? moment(dropoffDate)
					?.tz(destinationTimezone)
					?.format('ddd, MMM DD, HH:mm z')
			: ''

	const ppm = distance > 0 ? price / distance : 0

	const brokerName = truncateString(capitalizeString(broker?.name), 8)

	const priceTotal = numberWithCommas(price)
	const stopCount = load?.stops?.length

	const currentLoadStyle = isCurrentLoad ? 'bg-blue-100' : ''

	const tripDuration = calculateTripDuration(
		pickupDate,
		dropoffDate,
		status?.toLowerCase()
	)

	return (
		<div>
			<div
				onClick={handleCollapseToggle}
				className={`${currentLoadStyle} border p-3 my-1 mx-3 w-full max-w-screen-xl px-4 rounded-md shadow-sm bg-white`}
			>
				<div className="grid grid-cols-12 gap-2 items-center">
					<div className="col-span-2">
						<p className="cursor-pointer text-blue-700 text-xs">
							{loadId ? `${loadId}` : 'N/A'}
						</p>
						<p className="text-gray-500 text-xs">
							{brokerName ? brokerName : 'No Broker set'}
						</p>
					</div>

					<div className="col-span-1">
						<p className="cursor-pointer text-blue-700 text-xs">
							{status ? capitalizeString(status) : 'N/A'}
						</p>
						<p className="text-gray-500 text-xs">Status</p>
					</div>

					<div className="col-span-3">
						<p className="text-gray-700 text-xs">
							<span className="badge badge-accent badge-sm mr-1 text-white">
								1
							</span>
							{truncateString(origin, 25)}
						</p>
						<p className="text-gray-500 text-xs">{pickDate}</p>
					</div>

					<div className="col-span-3">
						<p className="text-gray-700 text-xs">
							<span className="badge badge-accent badge-sm mr-1 text-white">
								{stopCount}
							</span>
							{truncateString(destination, 25)}
						</p>
						<p className="text-gray-500 text-xs">{dropDate}</p>
					</div>

					<div className="col-span-1">
						<p className="text-gray-700 text-xs">
							{numberWithCommas(distance, 'miles')} mi
						</p>
						<p className="text-gray-500 text-xs">{tripDuration}</p>
					</div>

					<div className="col-span-1">
						<p className="text-gray-700 text-xs">${priceTotal}</p>
						<p className="text-gray-500 text-xs">
							${ppm ? ppm.toFixed(2) : 0}/mi
						</p>
					</div>

					<div className="col-span-1">
						<p className="text-gray-700 text-xs">
							{capitalizeString(equipment)}
						</p>
						<p className="text-gray-500 text-xs">Equipment</p>
					</div>
				</div>
			</div>

			{isCollapsed && (
				<div className="w-full max-w-screen-xl mx-3">
					<LoadCollapse load={load} />
				</div>
			)}
		</div>
	)
}

export default LoadCard
